var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var HZ,Cma,Dma,Ema,Fma,Gma,NZ,Hma,Ima,Jma,PZ,Kma,Lma,Mma,UZ,VZ,Nma,Oma,Pma,c_,Qma,Rma,Sma,f_,Tma,Uma,Vma,Wma;HZ=function(){};$CLJS.IZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.Za(HZ,a):$CLJS.Za(HZ,a)};Cma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.JZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Dma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Ema=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.KZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.LZ=new $CLJS.M(null,"database-id","database-id",1883826326);Fma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.MZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Gma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);NZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Hma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Ima=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Jma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.gL=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.OZ=new $CLJS.M(null,"dataset","dataset",1159262238);PZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Kma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.QZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.RZ=new $CLJS.M(null,"definition","definition",-1198729982);Lma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.SZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Mma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.TZ=new $CLJS.M("source","joins","source/joins",1225821486);UZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
VZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.WZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.lL=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Nma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.XZ=new $CLJS.M(null,"selected?","selected?",-742502788);
Oma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Pma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.YZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.ZZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.$Z=new $CLJS.M(null,"active","active",1895962068);$CLJS.a_=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.b_=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);c_=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Qma=new $CLJS.M(null,"details","details",1956795411);$CLJS.d_=new $CLJS.M("source","fields","source/fields",-649667981);Rma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.e_=new $CLJS.M("source","native","source/native",-1444604147);
Sma=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);f_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.g_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Tma=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Uma=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
Vma=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);Wma=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.h_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.i_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(UZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Es,$CLJS.ZZ,$CLJS.e_,$CLJS.KZ,$CLJS.MZ,$CLJS.d_,$CLJS.vL,$CLJS.pL,$CLJS.TZ,$CLJS.QZ,$CLJS.SZ],null));$CLJS.X(c_,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,Wma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.hL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iL,$CLJS.tL],null)],null));
$CLJS.X(NZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,Tma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.hL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.pi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Gma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.pi],null)],null)],null));
$CLJS.X($CLJS.gL,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.ZK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ek],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GB,$CLJS.PE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.tL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.ek],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.PE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mL,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.tL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lL,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.tL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.h_,
new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aL,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.g_,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.jL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JZ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WZ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,2,[$CLJS.fo,1,$CLJS.pk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XZ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Nma,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,c_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vma,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NZ],null)],null)],null)],null));
$CLJS.X(f_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YZ,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Kma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[Jma,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GB,$CLJS.PE],null)],null)],null)],null)],null)],null));
$CLJS.X(PZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$Z,$CLJS.Ys],null),new $CLJS.P(null,2,5,$CLJS.Q,[Lma,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YZ,$CLJS.GE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YK,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,
$CLJS.GE],null)],null)],null));
$CLJS.X(Rma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.uL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.jL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LZ,$CLJS.kL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.a_,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.ok],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.b_,
new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.ok],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nL,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.dL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ema,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,PZ],null)],null)],null)],null));
$CLJS.X(Oma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.cL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.bL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nL,$CLJS.dL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.ok],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.au,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null)],null));
$CLJS.X(Pma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.eL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.$K],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nL,$CLJS.dL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.ok],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.au,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null)],null));
$CLJS.X(Sma,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.fL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.dL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.GE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wl,new $CLJS.h(null,
1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null)],null));
$CLJS.X(Dma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.rL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.kL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ima,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.ok],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qma,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.ok],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.i_,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.bj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vA,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,$CLJS.bj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mma,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oL,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.ok],null)],null)],null));
$CLJS.X(VZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.IZ},Fma,$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,$CLJS.yi,$CLJS.U,$CLJS.tk],[Cma,Uma,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fr],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.IZ)?$CLJS.IZ.H:null]))],null));
$CLJS.X(Hma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VZ],null)],null)],null)],null));