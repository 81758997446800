var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var KH,LH,Dfa,NH,Efa,Ffa,Gfa,OH,MH;$CLJS.IH=function(a,b){return $CLJS.gc($CLJS.db(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.kg.j(c,e,$CLJS.ee.g($CLJS.J.j(c,e,$CLJS.Ff),d))},$CLJS.ec($CLJS.N),b))};$CLJS.JH=function(a,b){return $CLJS.db(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
KH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.GC(b,$CLJS.gD)?$CLJS.tH:$CLJS.GC(b,$CLJS.eE)?$CLJS.pH:$CLJS.GC(b,$CLJS.FD)?$CLJS.rH:null;return $CLJS.n(b)?$CLJS.DF(b,a):!0};
LH=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.IH(function(d){return $CLJS.GC($CLJS.aF(d),$CLJS.rD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Tk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.aF($CLJS.z(a));return $CLJS.Ue(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(KH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Dfa=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Os,new $CLJS.h(null,1,[$CLJS.Ht,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Jt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Ij);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(LH(b))].join("")}],null),$CLJS.We(LH)],null)],null)};
NH=function(a){var b=$CLJS.Q,c=Dfa(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.Ht,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.fo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.Hs,c,a],null)};Efa=function(a){return $CLJS.Td($CLJS.GF,$CLJS.lf.g(function(b){var c=$CLJS.aF(b),d=$CLJS.GC(c,$CLJS.mF);b=d?$CLJS.DF($CLJS.AG,b):d;return $CLJS.n(b)?$CLJS.Uj:c},a))};Ffa=function(a){a=$CLJS.JH(function(b){return!$CLJS.GC(b,$CLJS.rD)},$CLJS.lf.g($CLJS.aF,a));return $CLJS.GC(a,$CLJS.mF)?$CLJS.bk:a};Gfa=function(a){return $CLJS.n($CLJS.Ue(function(b){return $CLJS.GC($CLJS.aF(b),$CLJS.rD)},a))?Ffa(a):Efa(a)};
OH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.PH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);MH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,MH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.aF(a);return $CLJS.Te(function(d){return KH(d,c)},b)}],null)],null));
$CLJS.X(OH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.fo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)],null));$CLJS.XG.g($CLJS.ls,NH($CLJS.ls));$CLJS.XG.g($CLJS.At,NH($CLJS.At));$CLJS.NF($CLJS.ms,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mt,OH],null)]));$CLJS.NF($CLJS.SG,$CLJS.H([$CLJS.At,$CLJS.bE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mt,OH],null)]));
for(var QH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ls,$CLJS.At,$CLJS.ms],null)),RH=null,SH=0,TH=0;;)if(TH<SH){var Hfa=RH.X(null,TH);$CLJS.EF(Hfa,$CLJS.PH);TH+=1}else{var UH=$CLJS.y(QH);if(UH){var VH=UH;if($CLJS.Cd(VH)){var WH=$CLJS.lc(VH),Ifa=$CLJS.mc(VH),Jfa=WH,Kfa=$CLJS.D(WH);QH=Ifa;RH=Jfa;SH=Kfa}else{var Lfa=$CLJS.z(VH);$CLJS.EF(Lfa,$CLJS.PH);QH=$CLJS.B(VH);RH=null;SH=0}TH=0}else break}$CLJS.$E.m(null,$CLJS.PH,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);return Gfa(a)});
$CLJS.LF($CLJS.XF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));$CLJS.EF($CLJS.XF,$CLJS.qF);
for(var XH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UG,$CLJS.OF,$CLJS.jG],null)),YH=null,ZH=0,$H=0;;)if($H<ZH){var Mfa=YH.X(null,$H);$CLJS.LF(Mfa,$CLJS.H([$CLJS.At,$CLJS.bE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));$H+=1}else{var aI=$CLJS.y(XH);if(aI){var bI=aI;if($CLJS.Cd(bI)){var cI=$CLJS.lc(bI),Nfa=$CLJS.mc(bI),Ofa=cI,Pfa=$CLJS.D(cI);XH=Nfa;YH=Ofa;ZH=Pfa}else{var Qfa=$CLJS.z(bI);$CLJS.LF(Qfa,$CLJS.H([$CLJS.At,$CLJS.bE,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));XH=$CLJS.B(bI);YH=null;ZH=0}$H=0}else break}
for(var dI=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,$CLJS.uG,$CLJS.BG],null)),eI=null,fI=0,gI=0;;)if(gI<fI){var Rfa=eI.X(null,gI);$CLJS.LF(Rfa,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));gI+=1}else{var hI=$CLJS.y(dI);if(hI){var iI=hI;if($CLJS.Cd(iI)){var jI=$CLJS.lc(iI),Sfa=$CLJS.mc(iI),Tfa=jI,Ufa=$CLJS.D(jI);dI=Sfa;eI=Tfa;fI=Ufa}else{var Vfa=$CLJS.z(iI);$CLJS.LF(Vfa,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));dI=$CLJS.B(iI);eI=null;fI=0}gI=0}else break}$CLJS.LF($CLJS.UF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));
$CLJS.$E.m(null,$CLJS.UF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.GC($CLJS.aF(b),$CLJS.Bj)&&$CLJS.GC($CLJS.aF(a),$CLJS.Bj)?$CLJS.Bj:$CLJS.bE});