var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var mqa=function(a,b){return new $CLJS.qe(null,function(){a:for(var c=a,d=b;;){d=$CLJS.y(d);var e;if(e=d)e=$CLJS.z(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Lc(d);else break a}return d},null,null)},nqa=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ku(a,b),mqa(a,b)],null)},j5=function(a){var b=$CLJS.nl.l;var c=$CLJS.O(a);var d=$CLJS.J.g(c,$CLJS.c5);c=$CLJS.J.g(c,$CLJS.d5);if($CLJS.n($CLJS.n(d)?$CLJS.E.g(c,"currency"):d)){c=$CLJS.Y4.h;d=$CLJS.Ch.h(d);var e=$CLJS.q($CLJS.V4);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.Y4,d);c=new $CLJS.h(null,2,[$CLJS.b5,c,$CLJS.e5,c],null)}else c=new $CLJS.h(null,1,[$CLJS.e5,2],null);b=b.call($CLJS.nl,$CLJS.H([c,a]));return $CLJS.n($CLJS.f5.h(a))?$CLJS.R.l($CLJS.Nk.g(b,$CLJS.f5),$CLJS.e5,$CLJS.f5.h(a),$CLJS.H([$CLJS.b5,$CLJS.f5.h(a)])):b},k5=function(a,b){if(null!=a&&null!=a.Oe)a=a.Oe(a,b);else{var c=k5[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=k5._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("NumberFormatter.format-number-basic",
a);}return a},oqa=function(a,b){var c=$CLJS.bA(a.formatToParts(b),$CLJS.H([new $CLJS.h(null,1,[$CLJS.Di,!0],null)]));a=$CLJS.n($CLJS.Ue(function(d){return $CLJS.E.g($CLJS.rj.h(d),"exponentMinusSign")},c))?c:function(){var d=nqa(function(f){return $CLJS.Tk.g($CLJS.rj.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.kf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.rj,"exponentPlusSign",$CLJS.Ij,"+"],null)],null),$CLJS.H([d]))}();return $CLJS.Ve.g($CLJS.p,
$CLJS.lf.g(function(d){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.rj);e=$CLJS.J.g(e,$CLJS.Ij);switch(d){case "exponentSeparator":return"e";default:return e}},a))},pqa=function(a,b){switch($CLJS.d5.h(b)){case "scientific":return function(c){return oqa(a,c)};default:return function(c){return a.format(c)}}},l5=function(a,b,c,d,e){this.options=a;this.Ag=b;this.Dg=c;this.ee=d;this.ii=e;this.C=393216;this.I=0},qqa=function(a,b){return $CLJS.Ve.g($CLJS.p,function(){return function e(d){return new $CLJS.qe(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.md(k,v),A=$CLJS.O(x);x=$CLJS.J.g(A,$CLJS.rj);A=$CLJS.J.g(A,$CLJS.Ij);var C=new $CLJS.dh(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);C=C.h?C.h(x):C.call(null,x);$CLJS.n(C)&&(x=$CLJS.E.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}var t=$CLJS.z(f);t=$CLJS.O(t);
var u=$CLJS.J.g(t,$CLJS.rj);t=$CLJS.J.g(t,$CLJS.Ij);if($CLJS.n(function(){var v=new $CLJS.dh(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.he($CLJS.E.g(u,"integer")?b:t,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}($CLJS.bA(a.Ag.formatToParts(1),$CLJS.H([$CLJS.Di,!0])))}())},m5=function(a){var b=$CLJS.E.g($CLJS.d5.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.ql($CLJS.WA($CLJS.Wa,$CLJS.Lg([$CLJS.jqa,
$CLJS.bqa,$CLJS.eqa,$CLJS.gqa,$CLJS.lqa,$CLJS.c5,$CLJS.fqa,$CLJS.hqa,$CLJS.cqa,$CLJS.dqa],[$CLJS.e5.g(a,b),$CLJS.h5.h(a),$CLJS.g5.h(a),$CLJS.iqa.h(a),$CLJS.E.g($CLJS.d5.h(a),"scientific")?null:$CLJS.d5.g(a,"decimal"),$CLJS.c5.h(a),!0,$CLJS.b5.g(a,b),$CLJS.E.g($CLJS.d5.h(a),"scientific")?"scientific":null,$CLJS.i5.h(a)]))));var c=$CLJS.h5.h(a);var d=$CLJS.c5.h(a);c=$CLJS.n(d)?null==c||$CLJS.E.g(c,"symbol"):d;d=pqa(b,a);return new l5(a,b,c,d,$CLJS.N)},n5=function(a,b){return m5(j5(b)).Oe(null,a)},p5=
function(a,b){var c=$CLJS.Nk.l(b,$CLJS.NB,$CLJS.H([$CLJS.d5])),d=$CLJS.OA(a);if(0===a)return"0";if(1E3>d)return $CLJS.o5.g?$CLJS.o5.g(a,c):$CLJS.o5.call(null,a,c);b=$CLJS.z($CLJS.of(function(f){return d>=$CLJS.z(f)},rqa));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.nl.l($CLJS.H([c,new $CLJS.h(null,2,[$CLJS.b5,1,$CLJS.e5,1],null)]));return $CLJS.o5.g?$CLJS.o5.g(f,k):$CLJS.o5.call(null,f,k)}()),$CLJS.p.h(b)].join("")},sqa=function(a,b){var c=j5(b);if($CLJS.n(q5.h(c)))b=
q5.h(c);else if($CLJS.Va($CLJS.f5.h(c))&&$CLJS.Va($CLJS.b5.h(c))&&$CLJS.Tk.g($CLJS.d5.h(c),"currency")&&$CLJS.OA(a)<($CLJS.E.g($CLJS.d5.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.Nk.g(c,$CLJS.e5);c=$CLJS.i5.g(c,0);b=m5(b.call($CLJS.R,d,$CLJS.g5,2>c?2:c))}else b=m5(c);return k5(b,a)};l5.prototype.P=function(a,b){return new l5(this.options,this.Ag,this.Dg,this.ee,b)};l5.prototype.O=function(){return this.ii};
l5.prototype.Oe=function(a,b){a=this.ee.h?this.ee.h(b):this.ee.call(null,b);var c=$CLJS.kqa.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Tk.g(c,".,"):c)&&(b=$CLJS.z(c),c=$CLJS.jd(c),a=$CLJS.TA(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.Dg)&&(b=$CLJS.c5.h(this.options),c=$CLJS.W4(b),a=$CLJS.TA($CLJS.TA(a,[$CLJS.jh(b)," "].join(""),c),$CLJS.jh(b),c));return a};
var q5=new $CLJS.M(null,"number-formatter","number-formatter",929788393),r5=new $CLJS.M(null,"scale","scale",-230427353),s5=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),tqa=new $CLJS.M(null,"scientific","scientific",316285837);var t5=new $CLJS.h(null,1,[$CLJS.h5,"symbol"],null),uqa=$CLJS.ql(t5),rqa=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),u5=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.J.g(f,$CLJS.d5)},e,a,b,c,d)}();u5.m(null,$CLJS.gi,function(a,b){return p5(a,b)});u5.m(null,"percent",function(a,b){return[$CLJS.p.h(p5(100*a,b)),"%"].join("")});u5.m(null,"currency",function(a,b){b=$CLJS.nl.l($CLJS.H([b,t5]));var c=m5(b);return 1E3>$CLJS.OA(a)?c.Oe(null,a):qqa(c,p5(a,b))});u5.m(null,"scientific",function(a,b){return n5(a,$CLJS.nl.l($CLJS.H([b,new $CLJS.h(null,2,[$CLJS.e5,1,$CLJS.b5,1],null)])))});
$CLJS.o5=function o5(a,b){b=$CLJS.sB(b);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.NB);var e=$CLJS.J.g(d,s5),f=$CLJS.J.g(d,$CLJS.d5),k=$CLJS.J.g(d,r5);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.Nk.g(d,r5),o5.g?o5.g(b,f):o5.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,s5,!1);return o5.g?o5.g(l,m):o5.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?u5.g(a,j5($CLJS.Nk.g(d,$CLJS.NB))):$CLJS.E.g($CLJS.Ch.h(f),tqa)?n5(a,d):sqa(a,d)};
module.exports={compact_currency_options_js:uqa,format_number:$CLJS.o5};