var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var hX,Yka,jX,lX,mX,Zka,oX,pX,gX,qX,$ka,ala,bla,cla,tX,uX,wX,xX,yX,zX,AX,BX,iX,ela,fla,EX,gla,FX,hla,HX,ila,jla,kla,lla,mla,dX;$CLJS.eX=function(a,b){if("string"===typeof b)return dX(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.fX=function(a,b,c){var d=$CLJS.lm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Gb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
hX=function(a){var b=gX;return $CLJS.VA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};Yka=function(a){var b=iX;return $CLJS.VA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};jX=function(a){if($CLJS.od(a))return a;throw $CLJS.li("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Mi,a],null));};
$CLJS.kX=function(a,b,c){return $CLJS.zd(c)?$CLJS.hg.g(c,function(){return function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.md(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,Y=G;G=$CLJS.ee.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[Y,G],null));A+=1}else return!0}()?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.ee.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.xd(c)?$CLJS.Yk.g($CLJS.Ye(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.ee.g(b,$CLJS.z(c)):b),c):c};lX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.le(a):b;return $CLJS.n(b)?[$CLJS.le(a),"/",$CLJS.jh(a)].join(""):$CLJS.jh(a)};
mX=function(a,b){var c=$CLJS.xd(b)&&!$CLJS.Cf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.vd(a)?(b=$CLJS.z(b),a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Zka=function(a,b){return $CLJS.mf(function(c){var d=$CLJS.of;a:try{if($CLJS.Bd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.cl.j(c,0,1);if($CLJS.Bd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ye($CLJS.E,a)(f))){var k=$CLJS.cl.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
oX=function(a,b){for(;;)switch(b=$CLJS.pl.h($CLJS.of($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),nX.h?nX.h(a):nX.call(null,a);default:if($CLJS.n($CLJS.Ue($CLJS.Ye(mX,a),b))){var c=a;b=Zka(a,b);a=c}else{c=$CLJS.lf.g(nX,b);if($CLJS.E.g(c,b))return $CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};pX=function(a){var b=$CLJS.xd(a);return b?(b=!$CLJS.Cf(a))?$CLJS.PA($CLJS.je,$CLJS.Ya)($CLJS.z(a)):b:b};
gX=function(a){return $CLJS.n($CLJS.PA($CLJS.je,$CLJS.Ya)(a))?$CLJS.Ch.h($CLJS.TA(lX(a).toLowerCase(),/_/,"-")):a};qX=function(a,b){var c=pX(b);return $CLJS.n(c)?(b=gX($CLJS.z(b)),$CLJS.vd(a)?(a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};$ka=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.gi,$CLJS.Xd,$CLJS.rj,gX,$CLJS.cO,gX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.He([a,b]);b=rX.g?rX.g(b,sX):rX.call(null,b,sX);return d.call(c,b,a)})};
ala=function(a){a=$CLJS.hg.j($CLJS.N,$CLJS.lf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=gX(c);var d=$ka(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.rj.h(a),$CLJS.QF)&&$CLJS.Va($CLJS.cO.h(a))?$CLJS.R.j(a,$CLJS.cO,$CLJS.QG):a};bla=function(a){return $CLJS.hg.j($CLJS.N,$CLJS.lf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=lX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(ala(b),$CLJS.T,c)],null)}),a)};
cla=function(a){a=rX.h?rX.h(a):rX.call(null,a);return iX.h?iX.h(a):iX.call(null,a)};tX=function(a){return $CLJS.fX($CLJS.fX($CLJS.db(function(b,c){return $CLJS.fX(b,c,$CLJS.Ch)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.tD,$CLJS.zea,$CLJS.QO,dla,$CLJS.pA,$CLJS.wi],null)),$CLJS.tC,cla),$CLJS.eO,$CLJS.HV)};uX=function(a){return $CLJS.Id(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,a,null],null):a};wX=function(a){return vX.h(uX(a))};
xX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return nX($CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.lf.g(vX,b)))};yX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,wX(b)],null),$CLJS.lf.g(vX,c))};zX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,wX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
AX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,wX(a)],null)};BX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,vX.h(a)],null)};
iX=function(a){return CX(function(b){if($CLJS.zd(b))return Yka(b);if($CLJS.Va(pX(b)))return b;try{return vX.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.ZA($CLJS.Sy);if($CLJS.n($CLJS.YA("metabase.mbql.normalize",d))){var e=$CLJS.YE("Invalid clause:");e instanceof Error?$CLJS.XA("metabase.mbql.normalize",d,$CLJS.Qw.l($CLJS.H([b])),e):$CLJS.XA("metabase.mbql.normalize",d,$CLJS.Qw.l($CLJS.H([e,b])),null)}throw $CLJS.ki($CLJS.wE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.EV(c)])),new $CLJS.h(null,
1,[$CLJS.lS,b],null),c);}throw f;}},a)};ela=function(a){return $CLJS.Gf($CLJS.im(iX,DX(a)))};fla=function(a){for(;;)if($CLJS.n(pX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Ie($CLJS.Yk.g(uX,a))};EX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.QF);return $CLJS.n(b)?$CLJS.Zk.j(a,$CLJS.QF,vX):a};
gla=function(a){return $CLJS.hg.g($CLJS.N,function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,EX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.he(new $CLJS.P(null,
2,5,$CLJS.Q,[l,EX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};FX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.SQ);return $CLJS.n(b)?$CLJS.Zk.j(a,$CLJS.SQ,gla):a};hla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.vQ);b=$CLJS.Va(a)?GX.h?GX.h(b):GX.call(null,b):b;return $CLJS.n(a)?FX(b):b};HX=function(a){return $CLJS.vd(a)?$CLJS.y(a):null!=a};
ila=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.TF);var c=$CLJS.J.g(b,$CLJS.HN),d=$CLJS.J.g(b,$CLJS.dP),e=$CLJS.J.g(b,$CLJS.vQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.dP);var l=$CLJS.iM($CLJS.Nk.g(f,$CLJS.dP),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,$CLJS.dP],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Zk.j(l,$CLJS.TF,GX):l,t=$CLJS.n(c)?$CLJS.Zk.j(m,$CLJS.HN,$CLJS.Ye($CLJS.Yk,iX)):m,u=$CLJS.n(e)?$CLJS.Zk.j(t,$CLJS.vQ,FX):t;return iX(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.ki($CLJS.wE("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.EV(m)])),new $CLJS.h(null,1,[$CLJS.TF,a],null),m);throw v;}};
jla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.TF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.kG);c=$CLJS.J.g(c,$CLJS.fF);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.hg.j($CLJS.eh,$CLJS.FC,$CLJS.y($CLJS.of($CLJS.Wa,function k(e,f){try{if($CLJS.Bd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ke(l,$CLJS.wG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,m,$CLJS.Nk.g(t,$CLJS.CG)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.MV(k,e,f);throw l;}throw u;}}($CLJS.Ff,b)))),$CLJS.IX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,$CLJS.fF],null),$CLJS.Wk.g($CLJS.Gf,$CLJS.Ye($CLJS.Xk,b)))):a};
kla=function(a){try{return jla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.ki($CLJS.YE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.TF,a],null),b);}throw c;}};
lla=function(a,b){var c=$CLJS.hg.g($CLJS.ld(a),function(){return function f(e){return new $CLJS.qe(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.md(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.ee.g(b,G);C=JX.g?JX.g(C,K):JX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.ee.g(b,v);return JX.g?JX.g(A,C):JX.call(null,A,C)}();if(null!=u)return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};mla=function(a,b){a=$CLJS.Yk.g(function(c){var d=$CLJS.ee.g(b,KX);return JX.g?JX.g(c,d):JX.call(null,c,d)},a);return $CLJS.n($CLJS.Ue($CLJS.Wa,a))?a:null};
dX=function dX(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Gf(d);return $CLJS.he(f,new $CLJS.qe(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),dX.g?dX.g(a,k):dX.call(null,a,k)):null},null,null))};
$CLJS.IX=function IX(a){switch(arguments.length){case 3:return IX.j(arguments[0],arguments[1],arguments[2]);case 4:return IX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return IX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return IX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return IX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.IX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.IX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.IX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.IX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.IX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.IX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.IX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.IX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.IX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Ve.l($CLJS.IX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Ve.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.IX.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.IX.A=6;
var CX=function CX(a,b){return $CLJS.FV($CLJS.Ye(CX,a),$CLJS.Xd,a.h?a.h(b):a.call(null,b))},nX=function nX(a){for(;;){if($CLJS.zd(a))return $CLJS.Rt(a,nX);if($CLJS.Fd(a))a=$CLJS.Gf(a);else if($CLJS.Bd(a)){if($CLJS.Va($CLJS.Ue($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Sl(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.T:null;switch(A){case "not":a=$CLJS.jd(m);continue;case "and":return oX($CLJS.Hs,
$CLJS.lf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Js,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return oX($CLJS.Os,$CLJS.lf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Js,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return oX($CLJS.Os,t);case "or":return oX($CLJS.Hs,t);default:return $CLJS.Yk.g(nX,a)}}else return a}},nla=new $CLJS.M(null,"value_field","value_field",
-980977878),LX=new $CLJS.M(null,"ascending","ascending",-988350486),MX=new $CLJS.M(null,"named","named",-422393479),NX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),OX=new $CLJS.M(null,"descending","descending",-24766135),PX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),ola=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),QX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),RX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),pla=new $CLJS.M(null,"rows","rows",850049680),qla=new $CLJS.M(null,"special-fn","special-fn",1290649344),KX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),sX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),rla=new $CLJS.M(null,"label_field","label_field",-1573182765),sla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),tla=new $CLJS.M(null,"joined-field","joined-field",-2048778268),dla=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var SX,ula=$CLJS.$e($CLJS.N),vla=$CLJS.$e($CLJS.N),wla=$CLJS.$e($CLJS.N),xla=$CLJS.$e($CLJS.N),yla=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));SX=new $CLJS.hi($CLJS.Ih.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Wk.g(gX,$CLJS.z),yla,ula,vla,wla,xla);SX.m(null,$CLJS.lG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,b],null);return null!=a?$CLJS.ee.g(b,a):b});
SX.m(null,$CLJS.DB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DB,a instanceof $CLJS.M?lX(a):a],null)});SX.m(null,$CLJS.OV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.ee.g(SX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OV,rX.g?rX.g(b,sX):rX.call(null,b,sX),gX(c)],null)});
SX.m(null,$CLJS.wG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=rX.g?rX.g(a,sX):rX.call(null,a,sX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,b,function(){var d=$CLJS.n($CLJS.GB.h(c))?$CLJS.Zk.j(c,$CLJS.GB,$CLJS.Ch):c;d=$CLJS.n($CLJS.CG.h(c))?$CLJS.Zk.j(d,$CLJS.CG,$CLJS.Ch):d;return $CLJS.n($CLJS.GR.h(c))?$CLJS.Zk.j(d,$CLJS.GR,function(e){return $CLJS.n($CLJS.RE.h(e))?$CLJS.Zk.j(e,$CLJS.RE,$CLJS.Ch):e}):d}()],null)});
SX.m(null,QX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[QX,b instanceof $CLJS.M?lX(b):b,$CLJS.Ch.h(a)],null)});SX.m(null,PX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[PX,rX.g?rX.g(b,sX):rX.call(null,b,sX),$CLJS.yt,gX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[PX,rX.g?rX.g(b,sX):rX.call(null,b,sX),gX(c)],null)});
SX.m(null,$CLJS.IG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.ee.g(SX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.IG,b,c,d],null)),rX.g?rX.g(a,sX):rX.call(null,a,sX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.IG,rX.g?rX.g(b,sX):rX.call(null,b,sX),$CLJS.Id(c)?c:gX(c),gX(d)],null)});
SX.m(null,$CLJS.nJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nJ,b,gX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,$CLJS.iw],null)});SX.m(null,$CLJS.lJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lJ,b,gX(a)],null)});
SX.m(null,$CLJS.vJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vJ,rX.g?rX.g(b,sX):rX.call(null,b,sX),c,gX(a)],null)});SX.m(null,$CLJS.BJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.BJ,rX.g?rX.g(b,sX):rX.call(null,b,sX),c,gX(a)],null)});
SX.m(null,$CLJS.tJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tJ,rX.g?rX.g(b,sX):rX.call(null,b,sX),gX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tJ,rX.g?rX.g(b,sX):rX.call(null,b,sX)],null)});
SX.m(null,$CLJS.zJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zJ,rX.g?rX.g(b,sX):rX.call(null,b,sX),gX(c),gX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zJ,rX.g?rX.g(b,sX):rX.call(null,b,sX),gX(c)],null)});
SX.m(null,$CLJS.kJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kJ,rX.g?rX.g(b,sX):rX.call(null,b,sX),rX.g?rX.g(c,sX):rX.call(null,c,sX),gX(a)],null)});SX.m(null,$CLJS.Ij,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,b,a],null)});
SX.m(null,$CLJS.gi,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.hg.j(new $CLJS.P(null,1,5,$CLJS.Q,[gX(a)],null),$CLJS.lf.h(function(c){return rX.g?rX.g(c,sX):rX.call(null,c,sX)}),b)});
var TX=function TX(a){if($CLJS.n($CLJS.PA($CLJS.je,$CLJS.Ya)(a))){var c=gX(a);var d=new $CLJS.dh(null,new $CLJS.h(null,18,[$CLJS.fo,null,$CLJS.ms,null,$CLJS.MG,null,$CLJS.FG,null,$CLJS.At,null,$CLJS.xG,null,$CLJS.RG,null,$CLJS.SG,null,$CLJS.bG,null,$CLJS.hG,null,$CLJS.ls,null,$CLJS.cG,null,$CLJS.KG,null,$CLJS.DG,null,$CLJS.pk,null,$CLJS.gy,null,$CLJS.qG,null,$CLJS.JG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(pX(a))?(a=$CLJS.z(a),TX.h?TX.h(a):TX.call(null,
a)):null},zla=new $CLJS.h(null,8,[$CLJS.rj,gX,$CLJS.vQ,function(a){a=hX(a);return $CLJS.y($CLJS.SQ.h(a))?$CLJS.Zk.j(a,$CLJS.SQ,bla):a},$CLJS.TF,new $CLJS.h(null,6,[$CLJS.lG,function UX(a){if($CLJS.n($CLJS.PA($CLJS.je,$CLJS.Ya)(a)))return gX(a);if($CLJS.n(qX(MX,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[MX,UX.h?UX.h(a):UX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(pX(a))?TX($CLJS.jd(a)):null)?$CLJS.Yk.g(UX,a):rX.g?rX.g(a,
sX):rX.call(null,a,sX)},$CLJS.lQ,function(a){return $CLJS.hg.g($CLJS.N,function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[lX(u),rX.g?rX.g(t,sX):rX.call(null,t,sX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[lX(l),rX.g?rX.g(f,sX):rX.call(null,f,sX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.aS,function(a){return $CLJS.Gf(function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m);t=$CLJS.n(pX(t))?SX.h(t):$CLJS.fe(SX.h($CLJS.fe(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}l=$CLJS.z(e);return $CLJS.he($CLJS.n(pX(l))?SX.h(l):$CLJS.fe(SX.h($CLJS.fe(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.UQ,function(a){a=hX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.vQ);if($CLJS.n(b))return a=$CLJS.LV(a,new $CLJS.h(null,1,[$CLJS.vQ,$CLJS.TF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vQ],null),a=rX.g?rX.g(a,b):rX.call(null,a,b),$CLJS.LV(a,new $CLJS.h(null,1,[$CLJS.TF,$CLJS.vQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.TF],null);return rX.g?rX.g(a,b):rX.call(null,a,b)},$CLJS.dP,new $CLJS.h(null,1,[KX,tX],null),$CLJS.QN,new $CLJS.h(null,1,[KX,function(a){a=rX.g?rX.g(a,$CLJS.TF):rX.call(null,a,$CLJS.TF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.RE),d=$CLJS.J.g(b,$CLJS.fF);a=$CLJS.J.g(b,$CLJS.jF);b=$CLJS.n(c)?$CLJS.Zk.j(b,$CLJS.RE,gX):b;d=$CLJS.n($CLJS.PA($CLJS.je,$CLJS.Ya)(d))?$CLJS.Zk.j(b,$CLJS.fF,gX):b;return $CLJS.n(a)?$CLJS.Zk.j(d,$CLJS.jF,lX):d}],null)],null),$CLJS.Wy,
new $CLJS.h(null,1,[$CLJS.BO,$CLJS.Xd],null),$CLJS.HN,new $CLJS.h(null,1,[KX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.rj),d=$CLJS.J.g(b,$CLJS.GJ),e=$CLJS.J.g(b,$CLJS.dj);a=$CLJS.J.g(b,NX);b=$CLJS.n(e)?$CLJS.Zk.j(b,$CLJS.dj,lX):b;c=$CLJS.n(c)?$CLJS.Zk.j(b,$CLJS.rj,gX):b;d=$CLJS.n(d)?$CLJS.Zk.j(c,$CLJS.GJ,function(f){return rX.g?rX.g(f,sX):rX.call(null,f,sX)}):c;d=$CLJS.n(a)?$CLJS.IX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[NX,rla],null),function(f){return rX.g?rX.g(f,sX):rX.call(null,f,sX)}):d;return $CLJS.n(a)?
$CLJS.IX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[NX,nla],null),function(f){return rX.g?rX.g(f,sX):rX.call(null,f,sX)}):d}],null),$CLJS.CN,function(a){return null==a?null:gX(a)},$CLJS.dP,new $CLJS.h(null,1,[KX,tX],null),RX,gX],null),rX=function rX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return rX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
rX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Gf(b);b=$CLJS.y(c)?$CLJS.QA(zla,c):null;try{return $CLJS.od(b)?b.h?b.h(a):b.call(null,a):$CLJS.Ad(a)?a:$CLJS.zd(a)?$CLJS.hg.g($CLJS.N,function(){return function k(f){return new $CLJS.qe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=gX(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,rX.l(x,$CLJS.H([$CLJS.ee.g($CLJS.Gf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=gX(u);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[u,rX.l(m,$CLJS.H([$CLJS.ee.g($CLJS.Gf(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(pX(a))?SX.h(a):$CLJS.xd(a)?$CLJS.Yk.g(function(e){return rX.l(e,$CLJS.H([$CLJS.ee.g($CLJS.Gf(c),KX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.ki($CLJS.wE("Error normalizing form: {0}",$CLJS.H([$CLJS.EV(d)])),new $CLJS.h(null,3,[$CLJS.nA,a,$CLJS.vl,c,qla,b],null),d);}throw e;}};rX.A=1;rX.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var vX=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(pX(f))?$CLJS.z(f):null},e,a,b,c,d)}();vX.m(null,$CLJS.gi,function(a){return a});
vX.m(null,$CLJS.wG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(qX($CLJS.wG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return vX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,c,$CLJS.Ie($CLJS.nl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,b,$CLJS.Ie(a)],null)});
vX.m(null,$CLJS.lG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.ud(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,b,a],null)});vX.m(null,$CLJS.iL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(pX(a))?vX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,a,null],null)});
vX.m(null,QX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,b,new $CLJS.h(null,1,[$CLJS.GB,a],null)],null)});vX.m(null,sla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=wX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=wX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,a,$CLJS.R.j(c,$CLJS.RN,b)],null)});
vX.m(null,tla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=wX(a);return $CLJS.Ve.v($CLJS.TV,a,$CLJS.R,$CLJS.H([$CLJS.GP,b]))});
vX.m(null,PX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=wX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.GB);$CLJS.Va(d)||$CLJS.XS.g(d,b)?a=$CLJS.Ve.v($CLJS.TV,a,$CLJS.R,$CLJS.H([$CLJS.CG,b])):(c=$CLJS.ZA($CLJS.Uy),$CLJS.n($CLJS.YA("metabase.mbql.util",c))&&(b=$CLJS.wE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Ph.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.XA("metabase.mbql.util",c,$CLJS.Qw(),b):$CLJS.XA("metabase.mbql.util",c,$CLJS.Qw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),vX.h(new $CLJS.P(null,3,5,$CLJS.Q,[PX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
vX.m(null,$CLJS.OV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=wX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,b,$CLJS.R.j(e,$CLJS.GR,$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.RE,c],null),$CLJS.n(d)?$CLJS.He([c,d]):null,a])))],null)});
for(var VX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,$CLJS.Hs,$CLJS.Js],null)),WX=null,XX=0,YX=0;;)if(YX<XX){var ZX=WX.X(null,YX);vX.m(null,ZX,function(){return function(a){return xX(a)}}(VX,WX,XX,YX,ZX));YX+=1}else{var $X=$CLJS.y(VX);if($X){var aY=$X;if($CLJS.Cd(aY)){var bY=$CLJS.lc(aY),Ala=$CLJS.mc(aY),Bla=bY,Cla=$CLJS.D(bY);VX=Ala;WX=Bla;XX=Cla}else{var cY=$CLJS.z(aY);vX.m(null,cY,function(){return function(a){return xX(a)}}(VX,WX,XX,YX,cY,aY,$X));VX=$CLJS.B(aY);WX=null;XX=0}YX=0}else break}
vX.m(null,$CLJS.eG,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.hg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,wX(a),wX(b)],null),c)});vX.m(null,$CLJS.IG,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=wX(a);a=$CLJS.n(mX($CLJS.wG,a))?$CLJS.TV.l(c,$CLJS.Nk,$CLJS.H([$CLJS.CG])):c;return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IG,a],null),b)});
for(var dY=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.nG,$CLJS.HG,$CLJS.vG,$CLJS.oG,$CLJS.yk,$CLJS.OG,$CLJS.hs,$CLJS.js,$CLJS.ds,$CLJS.fs,$CLJS.$F,$CLJS.gG,$CLJS.YF,$CLJS.iG,$CLJS.ZF],null)),eY=null,fY=0,gY=0;;)if(gY<fY){var hY=eY.X(null,gY);vX.m(null,hY,function(){return function(a){return yX(a)}}(dY,eY,fY,gY,hY));gY+=1}else{var iY=$CLJS.y(dY);if(iY){var jY=iY;if($CLJS.Cd(jY)){var kY=$CLJS.lc(jY),Dla=$CLJS.mc(jY),Ela=kY,Fla=$CLJS.D(kY);dY=Dla;eY=Ela;fY=Fla}else{var lY=$CLJS.z(jY);vX.m(null,lY,
function(){return function(a){return yX(a)}}(dY,eY,fY,gY,lY,jY,iY));dY=$CLJS.B(jY);eY=null;fY=0}gY=0}else break}vX.m(null,pla,function(){return null});vX.m(null,$CLJS.HM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HM,vX.h(b),a],null)});
vX.m(null,MX,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=vX.h;var e=$CLJS.Q;b=vX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,ola)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.BE,a],null);return c.call(vX,new $CLJS.P(null,3,5,e,[$CLJS.HM,b,a],null))});
for(var mY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gy,$CLJS.xG],null)),nY=null,oY=0,pY=0;;)if(pY<oY){var qY=nY.X(null,pY);vX.m(null,qY,function(){return function(a){return zX(a)}}(mY,nY,oY,pY,qY));pY+=1}else{var rY=$CLJS.y(mY);if(rY){var sY=rY;if($CLJS.Cd(sY)){var tY=$CLJS.lc(sY),Gla=$CLJS.mc(sY),Hla=tY,Ila=$CLJS.D(tY);mY=Gla;nY=Hla;oY=Ila}else{var uY=$CLJS.z(sY);vX.m(null,uY,function(){return function(a){return zX(a)}}(mY,nY,oY,pY,uY,sY,rY));mY=$CLJS.B(sY);nY=null;oY=0}pY=0}else break}
for(var vY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.qG,$CLJS.TG,$CLJS.bG,$CLJS.MG,$CLJS.JG,$CLJS.fo,$CLJS.pk,$CLJS.KG,$CLJS.cG],null)),wY=null,xY=0,yY=0;;)if(yY<xY){var zY=wY.X(null,yY);vX.m(null,zY,function(){return function(a){return AX(a)}}(vY,wY,xY,yY,zY));yY+=1}else{var AY=$CLJS.y(vY);if(AY){var BY=AY;if($CLJS.Cd(BY)){var CY=$CLJS.lc(BY),Jla=$CLJS.mc(BY),Kla=CY,Lla=$CLJS.D(CY);vY=Jla;wY=Kla;xY=Lla}else{var DY=$CLJS.z(BY);vX.m(null,DY,function(){return function(a){return AX(a)}}(vY,wY,xY,yY,
DY,BY,AY));vY=$CLJS.B(BY);wY=null;xY=0}yY=0}else break}vX.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,wX(b),a],null)});
for(var EY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DG,$CLJS.FG],null)),FY=null,GY=0,HY=0;;)if(HY<GY){var IY=FY.X(null,HY);vX.m(null,IY,function(){return function(a){return BX(a)}}(EY,FY,GY,HY,IY));HY+=1}else{var JY=$CLJS.y(EY);if(JY){var KY=JY;if($CLJS.Cd(KY)){var LY=$CLJS.lc(KY),Mla=$CLJS.mc(KY),Nla=LY,Ola=$CLJS.D(LY);EY=Mla;FY=Nla;GY=Ola}else{var MY=$CLJS.z(KY);vX.m(null,MY,function(){return function(a){return BX(a)}}(EY,FY,GY,HY,MY,KY,JY));EY=$CLJS.B(KY);FY=null;GY=0}HY=0}else break}
vX.m(null,$CLJS.RG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,vX.h(b),vX.h(a)],null)});
vX.m(null,$CLJS.fG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.ee.g(vX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,b],null)),rX.l(a,$CLJS.H([sX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,$CLJS.Gf(function(){return function e(d){return new $CLJS.qe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[vX.h(v),vX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[vX.h(m),vX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
vX.m(null,$CLJS.PF,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.hg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PF,vX.h(a),$CLJS.E.g(0,b)?1:vX.h(b)],null),$CLJS.lf.g(vX,c))});
var DX=function DX(a){return function f(d,e){try{if($CLJS.n(function(){var x=jX($CLJS.Fd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Gf(e));throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=jX($CLJS.je);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.Bd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.cl.j(e,
0,2);if($CLJS.Bd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.uE($CLJS.je,$CLJS.We(new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.ms,null,$CLJS.At,null,$CLJS.SG,null,$CLJS.ls,null,MX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(TX(m)))return $CLJS.hg.j($CLJS.Ff,$CLJS.vE(DX),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;
}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.Bd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.cl.j(e,0,1);if($CLJS.Bd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;}else throw d;else throw x;}}($CLJS.Ff,a)},NY=function NY(a){return function f(d,e){try{var k=jX($CLJS.Fd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Gf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.Bd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ke(m,
$CLJS.tG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ke(m,$CLJS.mG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ke(m,LX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ke(m,OX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,t],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(m=Y,m===$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.ke(v,LX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,t],null));throw $CLJS.Z;}catch(ca){if(ca instanceof Error)if(m=ca,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ke(v,OX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,t],null));throw $CLJS.Z;
}catch(ja){if(ja instanceof Error)if(m=ja,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ke(v,$CLJS.tG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,wX(t)],null);throw $CLJS.Z;}catch(va){if(va instanceof Error)if(m=va,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ke(v,$CLJS.mG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,wX(t)],null);throw $CLJS.Z;}catch(Qa){if(Qa instanceof Error){t=Qa;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Qa;}else throw m;else throw va;}else throw m;else throw ja;
}else throw m;else throw ca;}else throw m;else throw Y;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{if($CLJS.Bd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.cl.j(e,0,0);if($CLJS.Bd(x)&&0===$CLJS.D(x))try{var A=$CLJS.cl.g(e,0);if($CLJS.E.g(A,a))return $CLJS.Gf($CLJS.pl.h($CLJS.lf.g(NY,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;
}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.kX(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Ff,a)},GX=$CLJS.Wk.g(iX,function(a){var b=HX($CLJS.lG.h(a))?$CLJS.Zk.j(a,$CLJS.lG,ela):a;b=HX($CLJS.kG.h(a))?$CLJS.Zk.j(b,$CLJS.kG,fla):b;b=HX($CLJS.fF.h(a))?$CLJS.Zk.j(b,$CLJS.fF,$CLJS.Ye($CLJS.Yk,uX)):b;b=HX($CLJS.aS.h(a))?$CLJS.Zk.j(b,$CLJS.aS,
NY):b;return HX($CLJS.UQ.h(a))?$CLJS.Zk.j(b,$CLJS.UQ,hla):b}),Pla=new $CLJS.h(null,3,[$CLJS.vQ,$CLJS.Xd,$CLJS.TF,new $CLJS.h(null,2,[$CLJS.UQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.vQ);if($CLJS.n(b))return a=$CLJS.LV(a,new $CLJS.h(null,1,[$CLJS.vQ,$CLJS.TF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vQ],null),a=JX.g?JX.g(a,b):JX.call(null,a,b),$CLJS.LV(a,new $CLJS.h(null,1,[$CLJS.TF,$CLJS.vQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.TF],null);return JX.g?JX.g(a,b):JX.call(null,a,b)},
$CLJS.QN,new $CLJS.h(null,1,[KX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.TF],null);return JX.g?JX.g(a,b):JX.call(null,a,b)}],null)],null),RX,$CLJS.Xd],null),JX=function JX(a){switch(arguments.length){case 1:return JX.h(arguments[0]);case 2:return JX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};JX.h=function(a){return JX.g(a,$CLJS.Ff)};
JX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.QA(Pla,b):null;return $CLJS.od(c)?c.h?c.h(a):c.call(null,a):$CLJS.Ad(a)?a:$CLJS.zd(a)?lla(a,b):$CLJS.xd(a)?mla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.ki("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.nA,a,$CLJS.vl,b],null),d);throw d;}};JX.A=2;
$CLJS.OY=function(){var a=$CLJS.Wk.l(JX,kla,ila,$CLJS.H([rX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.ki($CLJS.wE("Error normalizing query: {0}",$CLJS.H([$CLJS.EV(c)])),new $CLJS.h(null,1,[$CLJS.TF,b],null),c);}throw d;}}}();$CLJS.PY=function PY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.OY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Pt(a);b=$CLJS.He([$CLJS.kd(a),b]);f=PY.g?PY.g(f,b):PY.call(null,f,b);a=e.call(d,f,$CLJS.kd(a))}return a};