var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var dM,eM,fM,xha,yha,jM,zha,mM,nM,oM,pM,Aha,qM,rM,tM,uM,gM,vM,Bha,wM,Cha,xM,yM,zM,Dha,AM,BM,Eha,CM,Fha,DM,EM,Gha,GM,Hha,Iha,Jha,IM,JM,KM,LM,MM,NM,OM,PM,Kha,QM,RM,SM,UM,VM,Lha,WM,XM,YM,ZM,Mha,$M,aN,bN,cN,Nha,dN,eN,fN,gN,Oha,hN,Pha,iN,jN,kN,Qha,lN,mN,nN,oN,qN,rN,Rha,sN,tN,Sha,uN,vN,wN,xN,yN,Tha,zN,Uha,AN,BN,DN,Vha,EN,FN,GN,Wha,JN,KN,Xha,Yha,LN,NN,ON,PN,Zha,SN,TN,$ha,aia,bia,UN,VN,WN,XN,YN,ZN,cia,$N,dia,aO,bO,dO,fO,gO,hO,iO,eia,kO,fia,gia,lO,mO,nO,oO,pO,hia,qO,iia,jia,rO,sO,tO,uO,vO,kia,
lia,mia,wO,nia,xO,oia,zO,AO,pia,CO,DO,EO,FO,qia,IO,ria,sia,JO,KO,LO,tia,MO,NO,OO,PO,uia,RO,SO,TO,UO,VO,via,WO,XO,wia,YO,ZO,$O,aP,bP,cP,eP,xia,fP,yia,gP,hP,iP,zia,Aia,Bia,kP,lP,mP,Cia,nP,oP,pP,qP,Dia,Eia,sP,Fia,Gia,tP,uP,vP,wP,xP,Hia,yP,zP,AP,BP,Iia,CP,DP,EP,FP,HP,Jia,IP,JP,Kia,KP,LP,MP,Lia,NP,Mia,PP,Nia,QP,RP,SP,TP,UP,VP,WP,Oia,Pia,Qia,XP,Ria,YP,ZP,Sia,Tia,$P,aQ,bQ,Uia,cQ,Via,dQ,Wia,Xia,eQ,Yia,Zia,$ia,hQ,iQ,aja,jQ,kQ,mQ,bja,nQ,cja,oQ,pQ,qQ,dja,eja,rQ,sQ,tQ,uQ,fja,wQ,xQ,gja,yQ,zQ,AQ,BQ,CQ,DQ,EQ,FQ,
hja,GQ,HQ,ija,IQ,jja,JQ,KQ,LQ,kja,MQ,NQ,lja,mja,OQ,PQ,QQ,nja,oja,RQ,pja,qja,rja,TQ,sja,VQ,tja,uja,WQ,XQ,vja,wja,xja,YQ,ZQ,$Q,aR,bR,cR,dR,yja,eR,fR,zja,gR,Aja,Bja,Cja,hR,iR,jR,Dja,kR,lR,Eja,mR,Fja,nR,Gja,Hja,pR,qR,rR,sR,tR,Ija,uR,vR,Jja,wR,xR,yR,zR,AR,BR,CR,DR,Kja,ER,FR,HR,IR,JR,KR,LR,MR,NR,OR,PR,QR,Lja,RR,SR,TR,UR,VR,Mja,WR,YR,ZR,$R,Nja,bS,Oja,Pja,dS,Qja,eS,Rja,fS,Sja,Tja,gS,Uja,hS,jS,kS,Vja,oS,pS,Wja,Xja,qS,rS,sS,tS,uS,vS,wS,yS,zS,Yja;
$CLJS.cM=function(a){var b=$CLJS.Eo.g(a,null),c=$CLJS.AF(b,$CLJS.ct,function(d){return $CLJS.Dn(d,$CLJS.Ff)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.wl,b,$CLJS.Ij,k,$CLJS.it,l],null):null}function e(k){return f.j(k,$CLJS.Ff,$CLJS.Ff)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
dM=function(a,b){return $CLJS.zd(a)?$CLJS.J.g(a,b):a};eM=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Gn($CLJS.Eo.g($CLJS.wl.h(a),null));if($CLJS.n(b)){var e=dM($CLJS.Jt.h(b),c);$CLJS.n(e)?(e=$CLJS.Nq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:dM($CLJS.Ht.h(b),c)}return null};fM=function(a,b){return $CLJS.wd(a)||$CLJS.Pl(a)?$CLJS.J.g(a,b):$CLJS.xd(a)?$CLJS.J.g($CLJS.Gf(a),b):null};
xha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.wl),d=$CLJS.J.g(a,$CLJS.rj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.it,$CLJS.hea),f=$CLJS.J.j(b,$CLJS.cea,!0),k=$CLJS.J.g(b,$CLJS.rC),l=$CLJS.J.j(b,$CLJS.pC,$CLJS.nC),m=eM(a,$CLJS.Zq.h(c),k,b);if($CLJS.n(m))return m;m=eM(a,$CLJS.ut.h(c),k,b);if($CLJS.n(m))return m;m=eM(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=eM(a,function(){var t=$CLJS.lo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=eM(a,$CLJS.Zq.h(c),
l,b);if($CLJS.n(m))return m;m=eM(a,$CLJS.ut.h(c),l,b);if($CLJS.n(m))return m;d=eM(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=eM(a,function(){var t=$CLJS.lo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?eM(a,e.h?e.h($CLJS.lC):e.call(null,$CLJS.lC),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?eM(a,e.h?e.h($CLJS.lC):e.call(null,$CLJS.lC),l,b):f};
yha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.hg.g;var e=$CLJS.tl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.wl),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.rC);l=$CLJS.J.j(l,$CLJS.pC,$CLJS.nC);k=$CLJS.Zq.h(k);f=dM($CLJS.oC.h(k),f);f=$CLJS.n(f)?f:dM($CLJS.oC.h(k),l);d=d.call($CLJS.hg,e,f);return new $CLJS.P(null,2,5,a,[d,xha(b,c)],null)};
$CLJS.hM=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ij);a=$CLJS.J.g(b,$CLJS.it);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.dea,$CLJS.Vi),f=$CLJS.J.j(d,$CLJS.fea,yha);return $CLJS.n(a)?$CLJS.db(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Vi,m);l=e.h?e.h(l):e.call(null,l);return gM(k,c,t,l)},null,a):null};
jM=function(a,b,c){var d=$CLJS.QA($CLJS.q($CLJS.pE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Rh.v($CLJS.pE,$CLJS.iM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.kM=function(a){return jM($CLJS.Dk,a,function(){return $CLJS.BF.h(a)})};zha=function(){var a=lM;return jM($CLJS.ct,a,function(){var b=$CLJS.BF.h(a),c=$CLJS.cM(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
mM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,$CLJS.pe(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};nM=function(a){if($CLJS.Bd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,mM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,mM(c)],null);default:return mM(a)}}else return mM(a)};
oM=function(a,b){var c=$CLJS.Bd(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.vd(a)?(b=$CLJS.z(b),a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
pM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ye(oM,a)],null),$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,a],null)],null)],null),function(){return function e(d){return new $CLJS.qe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.md(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,nM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[m,nM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.xt(2,2,b))}())],null)};Aha=function(a){return $CLJS.Bd(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
qM=function(a){return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ti,Aha,$CLJS.Ht,["valid instance of one of these MBQL clauses: ",$CLJS.St(", ",$CLJS.lf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.pe(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.pe(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
rM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bM],null)],null)};$CLJS.sM={};tM={};uM={};$CLJS.iM=function iM(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=iM.j?iM.j(k,f,c):iM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
gM=function gM(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=fM(b,c);f=$CLJS.n(a)?a:$CLJS.xd(b)?$CLJS.Ff:$CLJS.Ad(b)?$CLJS.N:$CLJS.ld(b);return $CLJS.n($CLJS.n(c)?$CLJS.mC.h($CLJS.rd(f)):c)?a:$CLJS.n(c)?(b=fM(f,c),d=gM.v?gM.v(b,l,k,d):gM.call(null,b,l,k,d),$CLJS.Ul(c)&&$CLJS.xd(f)&&c>$CLJS.D(f)&&(b=$CLJS.gf(c-$CLJS.D(f),null),b=$CLJS.kf.g(f,b),f=null==f||$CLJS.Fd(f)?b:$CLJS.hg.g($CLJS.ld(f),b)),null==f||$CLJS.Pl(f)?$CLJS.R.j(f,c,d):$CLJS.wd(f)?$CLJS.ee.g(f,d):$CLJS.Ve.g($CLJS.V,$CLJS.R.j($CLJS.Gf(f),
c,d))):$CLJS.zd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eea],null),gM.v?gM.v(f,b,c,d):gM.call(null,f,b,c,d)):$CLJS.n($CLJS.mC.h($CLJS.rd(f)))?$CLJS.ee.g(f,d):$CLJS.Bd($CLJS.Ie(f))?f:$CLJS.qd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.mC,!0],null))};vM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Bha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
wM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Cha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);xM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);yM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);zM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Dha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);AM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);BM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Eha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);CM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Fha=new $CLJS.M(null,"from","from",1815293044);DM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);EM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Gha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.FM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);GM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Hha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Iha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.HM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Jha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);IM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
JM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);KM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);LM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);MM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);NM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
OM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);PM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Kha=new $CLJS.M(null,"lon-max","lon-max",1590224717);QM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);RM=new $CLJS.r(null,"stddev","stddev",775056588,null);SM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.TM=new $CLJS.M(null,"snippet","snippet",953581994);UM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);VM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Lha=new $CLJS.M(null,"lat-field","lat-field",-830652957);WM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);XM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
YM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);ZM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Mha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);$M=new $CLJS.M("location","country","location/country",1666636202);aN=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
bN=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);cN=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Nha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);dN=new $CLJS.M(null,"unary","unary",-989314568);eN=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
fN=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);gN=new $CLJS.r(null,"ceil","ceil",-184398425,null);Oha=new $CLJS.M(null,"lon-min","lon-min",-787291357);hN=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Pha=new $CLJS.M(null,"match","match",1220059550);iN=new $CLJS.r(null,"count-where","count-where",2025939247,null);jN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
kN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Qha=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);lN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);mN=new $CLJS.r(null,"sum","sum",1777518341,null);nN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);oN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.pN=new $CLJS.M("date","range","date/range",1647265776);qN=new $CLJS.r(null,"between","between",-1523336493,null);rN=new $CLJS.M(null,"clause-form","clause-form",1820463737);Rha=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);sN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);tN=new $CLJS.r(null,"field","field",338095027,null);Sha=new $CLJS.M(null,"segment-id","segment-id",1810133590);
uN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);vN=new $CLJS.r(null,"not-null","not-null",313812992,null);wN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);xN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);yN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Tha=new $CLJS.M(null,"template-tag","template-tag",310841038);
zN=new $CLJS.M(null,"invalid","invalid",412869516);Uha=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);AN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);BN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.CN=new $CLJS.M(null,"context","context",-830191113);DN=new $CLJS.r(null,"get-year","get-year",704520253,null);
Vha=new $CLJS.M(null,"format-rows?","format-rows?",992129486);EN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);FN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);GN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.HN=new $CLJS.M(null,"parameters","parameters",-1229919748);Wha=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.IN=new $CLJS.M(null,"card","card",-1430355152);JN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);KN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Xha=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Yha=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);LN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.MN=new $CLJS.M("date","month-year","date/month-year",1948031290);
NN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);ON=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);PN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.QN=new $CLJS.M(null,"joins","joins",1033962699);Zha=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.RN=new $CLJS.M(null,"source-field","source-field",933829534);SN=new $CLJS.r(null,"Field","Field",430385967,null);
TN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);$ha=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);aia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);bia=new $CLJS.M(null,"items","items",1031954938);UN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);VN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
WN=new $CLJS.M(null,"more","more",-2058821800);XN=new $CLJS.M(null,"first-clause","first-clause",-20953491);YN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);ZN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);cia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);$N=new $CLJS.r(null,"contains","contains",-1977535957,null);
dia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);aO=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);bO=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.cO=new $CLJS.M(null,"widget-type","widget-type",1836256899);dO=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.eO=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
fO=new $CLJS.r(null,"is-null","is-null",-356519403,null);gO=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);hO=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);iO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);eia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.jO=new $CLJS.M(null,"required","required",1807647006);kO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);
fia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);gia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);lO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);mO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);nO=new $CLJS.M("string","contains","string/contains",1602423827);oO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);pO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);
hia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);qO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);iia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);jia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);rO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);
sO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);tO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);uO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);vO=new $CLJS.r(null,"share","share",1051097594,null);kia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);
lia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);mia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);wO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);nia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);xO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);
oia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.yO=new $CLJS.M(null,"collection","collection",-683361892);zO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);AO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.BO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);
pia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);CO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);DO=new $CLJS.r(null,"metric","metric",2049329604,null);EO=new $CLJS.r(null,"concat","concat",-467652465,null);FO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.GO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);qia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);
$CLJS.HO=new $CLJS.M("date","relative","date/relative",25987732);IO=new $CLJS.M("location","city","location/city",-1746973325);ria=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);sia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);JO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);KO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);LO=new $CLJS.M("number","between","number/between",97700581);
tia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);MO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);NO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);OO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);PO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.QO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);
uia=new $CLJS.M(null,"metric-id","metric-id",-686486942);RO=new $CLJS.r(null,"*","*",345799209,null);SO=new $CLJS.r(null,"+","+",-740910886,null);TO=new $CLJS.r(null,"-","-",-471816912,null);UO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);VO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);via=new $CLJS.M(null,"question","question",-1411720117);WO=new $CLJS.r(null,"asc","asc",1997386096,null);
XO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);wia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);YO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);ZO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);$O=new $CLJS.r(null,"\x3c","\x3c",993667236,null);aP=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);bP=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
cP=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.dP=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);eP=new $CLJS.M("string","ends-with","string/ends-with",302681156);xia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);fP=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);yia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
gP=new $CLJS.r(null,"and","and",668631710,null);hP=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);iP=new $CLJS.r(null,"round","round",-645002441,null);zia=new $CLJS.M(null,"to","to",192099007);$CLJS.jP=new $CLJS.M("date","single","date/single",1554682003);Aia=new $CLJS.M(null,"action-id","action-id",-1727958578);Bia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);kP=new $CLJS.r(null,"exp","exp",1378825265,null);
lP=new $CLJS.r(null,"Filter","Filter",-424893332,null);mP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Cia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);nP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);oP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);pP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
qP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.rP=new $CLJS.M(null,"source-table","source-table",-225307692);Dia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Eia=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);sP=new $CLJS.r(null,"floor","floor",-772394748,null);Fia=new $CLJS.M(null,"middleware","middleware",1462115504);
Gia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);tP=new $CLJS.M(null,"requires-features","requires-features",-101116256);uP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);vP=new $CLJS.M(null,"clause-name","clause-name",-996419059);wP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);xP=new $CLJS.r(null,"now","now",-9994004,null);
Hia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);yP=new $CLJS.r(null,"not","not",1044554643,null);zP=new $CLJS.r(null,"avg","avg",1837937727,null);AP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);BP=new $CLJS.M(null,"max-results","max-results",-32858165);Iia=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);CP=new $CLJS.r(null,"case","case",-1510733573,null);
DP=new $CLJS.r(null,"distinct","distinct",-148347594,null);EP=new $CLJS.r(null,"get-second","get-second",-425414791,null);FP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.GP=new $CLJS.M(null,"join-alias","join-alias",1454206794);HP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Jia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
IP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);JP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Kia=new $CLJS.M(null,"original","original",-445386197);KP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);LP=new $CLJS.r(null,"abs","abs",1394505050,null);MP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Lia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
NP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Mia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.OP=new $CLJS.M(null,"date","date",-1463434462);PP=new $CLJS.M(null,"second-clause","second-clause",-461435645);Nia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);QP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
RP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);SP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);TP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);UP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);VP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);WP=new $CLJS.r(null,"or","or",1876275696,null);
Oia=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Pia=new $CLJS.M(null,"constraints","constraints",422775616);Qia=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);XP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Ria=new $CLJS.M(null,"csv-download","csv-download",2141432084);YP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
ZP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Sia=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Tia=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);$P=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);aQ=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
bQ=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Uia=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);cQ=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Via=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);dQ=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Wia=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Xia=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);eQ=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Yia=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Zia=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);$ia=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.fQ=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.gQ=new $CLJS.M(null,"database","database",1849087575);hQ=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);iQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);aja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);jQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
kQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.lQ=new $CLJS.M(null,"expressions","expressions",255689909);mQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);bja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);nQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
cja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);oQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);pQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);qQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);dja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);eja=new $CLJS.M(null,"action","action",-811238024);
rQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);sQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);tQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);uQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.vQ=new $CLJS.M(null,"native","native",-613060878);fja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);wQ=new $CLJS.M(null,"page","page",849072397);
xQ=new $CLJS.r(null,"length","length",-2065447907,null);gja=new $CLJS.M(null,"dashboard","dashboard",-631747508);yQ=new $CLJS.r(null,"get-week","get-week",752472178,null);zQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);AQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);BQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);CQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
DQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);EQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);FQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);hja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);GQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);HQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
ija=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);IQ=new $CLJS.M(null,"internal","internal",-854870097);jja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);JQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);KQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
LQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);kja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);MQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);NQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);lja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
mja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);OQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);PQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);QQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
nja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);oja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);RQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.SQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);pja=new $CLJS.M(null,"public-question","public-question",629369976);qja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
rja=new $CLJS.M(null,"binary","binary",-1802232288);TQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);sja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.UQ=new $CLJS.M(null,"source-query","source-query",198004422);VQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);tja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
uja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);WQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);XQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);vja=new $CLJS.M(null,"executed-by","executed-by",-739811161);wja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
xja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);YQ=new $CLJS.M(null,"amount","amount",364489504);ZQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);$Q=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);aR=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);bR=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
cR=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);dR=new $CLJS.r(null,"trim","trim",-1880116002,null);yja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);eR=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);fR=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);zja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
gR=new $CLJS.M("string","\x3d","string/\x3d",983744235);Aja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Bja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Cja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);hR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);iR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
jR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Dja=new $CLJS.M(null,"lat-min","lat-min",1630784161);kR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);lR=new $CLJS.r(null,"inside","inside",-681932758,null);Eja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);mR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Fja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);nR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Gja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Hja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.oR=new $CLJS.M(null,"card-id","card-id",-1770060179);pR=new $CLJS.M(null,"variadic","variadic",882626057);
qR=new $CLJS.r(null,"upper","upper",1886775433,null);rR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);sR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);tR=new $CLJS.r(null,"optional","optional",-600484260,null);Ija=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);uR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
vR=new $CLJS.M(null,"sugar","sugar",-73788488);Jja=new $CLJS.M(null,"lat-max","lat-max",841568226);wR=new $CLJS.r(null,"power","power",702679448,null);xR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);yR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);zR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
AR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);BR=new $CLJS.r(null,"median","median",-2084869638,null);CR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);DR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Kja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);ER=new $CLJS.M(null,"y","y",-1757859776);FR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.GR=new $CLJS.M(null,"binning","binning",1709835866);HR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);IR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);JR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);KR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);LR=new $CLJS.r(null,"Reference","Reference",2024574086,null);MR=new $CLJS.M(null,"b","b",1482224470);
NR=new $CLJS.M(null,"a","a",-2123407586);OR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);PR=new $CLJS.r(null,"replace","replace",853943757,null);QR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Lja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);RR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
SR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);TR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);UR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);VR=new $CLJS.r(null,"segment","segment",675610331,null);Mja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);WR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
YR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);ZR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);$R=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Nja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.aS=new $CLJS.M(null,"order-by","order-by",1527318070);bS=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Oja=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Pja=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.cS=new $CLJS.M(null,"condition","condition",1668437652);dS=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Qja=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);eS=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Rja=new $CLJS.M(null,"card-name","card-name",-2035606807);fS=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Sja=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Tja=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);gS=new $CLJS.r(null,"log","log",45015523,null);Uja=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
hS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.iS=new $CLJS.M(null,"database_type","database_type",-54700895);jS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);kS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.lS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.mS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.nS=new $CLJS.M("date","all-options","date/all-options",549325958);
Vja=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);oS=new $CLJS.M("location","state","location/state",-114378652);pS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Wja=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Xja=new $CLJS.M(null,"lon-field","lon-field",517872067);qS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);
rS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);sS=new $CLJS.M(null,"numeric","numeric",-1495594714);tS=new $CLJS.r(null,"variable","variable",1359185035,null);uS=new $CLJS.r(null,"lower","lower",-1534114948,null);vS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);wS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.xS=new $CLJS.M(null,"limit","limit",-1355822363);
yS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);zS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Yja=new $CLJS.M(null,"pulse","pulse",-244494476);var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GE],null),BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PE],null),CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CE],null),DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yE],null),ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KE],null),FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tL],null),Zja=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jL],null),$ja=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dL],null),GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.UI],null),HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null),IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PI],null),JS=new $CLJS.dh(null,new $CLJS.h(null,12,[$CLJS.ti,null,$CLJS.ou,null,$CLJS.tj,null,$CLJS.gi,null,$CLJS.Li,null,$CLJS.Cj,null,$CLJS.rk,null,$CLJS.Lj,null,$CLJS.ni,null,$CLJS.Kj,null,$CLJS.ui,null,$CLJS.Ei,null],null),null),KS=new $CLJS.dh(null,new $CLJS.h(null,7,[$CLJS.vk,null,$CLJS.jH,null,$CLJS.gi,null,$CLJS.Ai,null,$CLJS.mH,null,$CLJS.nj,null,$CLJS.Zi,null],null),null),
LS=$CLJS.pu.g(JS,KS),aka=$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"date bucketing unit"],null)],null),JS),bka=$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"time bucketing unit"],null)],null),KS),MS=$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"datetime bucketing unit"],null)],null),LS),NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yJ],null),cka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,
1,[$CLJS.Ht,"temporal extract unit"],null),$CLJS.nH,$CLJS.Ei,$CLJS.rk,$CLJS.jJ,$CLJS.EJ,$CLJS.FJ,$CLJS.Lj,$CLJS.Li,$CLJS.Ai,$CLJS.nj,$CLJS.kH],null),dka=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"datetime-diff unit"],null),$CLJS.jH,$CLJS.Zi,$CLJS.vk,$CLJS.ou,$CLJS.tj,$CLJS.Cj,$CLJS.ti,$CLJS.ni],null),OS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"temporal-extract week extraction mode"],null),$CLJS.dJ,$CLJS.mJ,$CLJS.rJ],null),PS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"relative-datetime unit"],null),$CLJS.gi,$CLJS.Zi,$CLJS.vk,$CLJS.ou,$CLJS.tj,$CLJS.Cj,$CLJS.ti,$CLJS.ni],null),WS,fka,dT,eT,fT,gT,hT,iT,jT,VU,hka,WU,ika,jka,XU,kka,lka,mka;$CLJS.X(fN,pM($CLJS.nJ,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.iw],null),$CLJS.Ts],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,PS],null)])));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null);
$CLJS.X(KP,pM($CLJS.lJ,$CLJS.H(["n",$CLJS.Ts,"unit",PS])));var eka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);
$CLJS.X(AN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,"valid :absolute-datetime clause",$CLJS.Ti,function(a){if($CLJS.Va(oM($CLJS.hJ,a)))a=zN;else{a=$CLJS.jd(a);var b=$CLJS.kM(GS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.OP:$CLJS.oJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[zN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"not an :absolute-datetime clause"],null),$CLJS.Xe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OP,pM($CLJS.hJ,
$CLJS.H(["date",GS,"unit",aka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,pM($CLJS.hJ,$CLJS.H(["datetime",HS,"unit",MS]))],null)],null));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AN],null);$CLJS.X(PN,pM($CLJS.Vy,$CLJS.H(["time",IS,"unit",bka])));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PN],null),TS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ht,"date or datetime literal"],null),RS,HS,GS],null);
$CLJS.X(AM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ht,"time literal"],null),SS,IS],null));$CLJS.X(qS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ht,"temporal literal"],null),TS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null)],null));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qS],null);
$CLJS.X(zja,qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof RS?new $CLJS.Gc(function(){return RS},$CLJS.qd($ha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,IQ,$CLJS.sj,$CLJS.Ek,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qQ,"metabase/mbql/schema.cljc",69,$CLJS.hJ,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(RS)?RS.H:null])):null));return $CLJS.n(a)?a:qQ}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof QS?new $CLJS.Gc(function(){return QS},$CLJS.qd(Vja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,
$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",29,$CLJS.nJ,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nJ,$CLJS.wk,$CLJS.V($CLJS.Vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.iw],null),$CLJS.Ts],null)),$CLJS.wi,$CLJS.V($CLJS.Vj,$CLJS.V(tR,fP))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:IP}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof SS?new $CLJS.Gc(function(){return SS},$CLJS.qd(Xia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,IQ,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hS,"metabase/mbql/schema.cljc",27,$CLJS.Vy,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vy,$CLJS.Vy,$CLJS.V($CLJS.Vj,yja),$CLJS.wi,$CLJS.V($CLJS.Vj,qia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:hS}(),SS],null)])));
$CLJS.X(fS,pM($CLJS.Ij,$CLJS.H(["value",$CLJS.pi,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iS,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tD,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QO,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Gs,CS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wi,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,MS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,AS],null)],null)],null)],null)])));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fS],null);$CLJS.X(PO,pM($CLJS.DB,$CLJS.H(["expression-name",AS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,$CLJS.ok],null)])));
WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PO],null);
fka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.RE);c=$CLJS.J.g(c,$CLJS.zE);return $CLJS.E.g(b,$CLJS.zE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.RE);c=$CLJS.J.g(c,$CLJS.QE);return $CLJS.E.g(b,$CLJS.QE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"binning strategy"],null),$CLJS.zE,$CLJS.QE,$CLJS.gi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),DS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.QE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,$CLJS.Hl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"bin width must be \x3e\x3d 0."],null),$CLJS.We($CLJS.bm)],null)],null)],null)],null)));$CLJS.XS=function XS(a){switch(arguments.length){case 1:return XS.h(arguments[0]);case 2:return XS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.XS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.GB);b=$CLJS.J.g(b,$CLJS.CG);return $CLJS.XS.g(a,b)};$CLJS.XS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.GC(a,$CLJS.gD)?JS:$CLJS.GC(a,$CLJS.eE)?KS:$CLJS.GC(a,$CLJS.FD)?LS:null:null;return $CLJS.n(a)?$CLJS.Kd(a,b):!0};$CLJS.XS.A=2;
$CLJS.X(uR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GB,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,FS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,new $CLJS.h(null,
1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,MS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GP,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GR,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,fka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.XS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.We($CLJS.RE)],null)],null));
$CLJS.X(WR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.GB);return"string"===typeof c?b:!0}],null)],null)}(pM($CLJS.wG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,FS,AS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uR],null)],null)]))));var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WR],null);
$CLJS.X(XP,qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof WS?new $CLJS.Gc(function(){return WS},$CLJS.qd(aO,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LN,"metabase/mbql/schema.cljc",60,$CLJS.DB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.DB,$CLJS.mS,$CLJS.V($CLJS.Vj,hN),$CLJS.kj,$CLJS.V($CLJS.Vj,$CLJS.V(tR,$CLJS.ok))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:LN}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof YS?new $CLJS.Gc(function(){return YS},
$CLJS.qd(sO,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.Gr,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,$CLJS.yi,$CLJS.U,$CLJS.tk],["0.39.0",$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tN,"metabase/mbql/schema.cljc",51,$CLJS.wG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:tN}(),YS],null)])));
$CLJS.ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XP],null);$CLJS.X(vM,pM($CLJS.lG,$CLJS.H(["aggregation-clause-index",$CLJS.Ts,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,$CLJS.ok],null)])));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vM],null);
$CLJS.X(bN,qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof $S?new $CLJS.Gc(function(){return $S},$CLJS.qd(wja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FR,"metabase/mbql/schema.cljc",23,$CLJS.lG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lG,Wja,$CLJS.V($CLJS.Vj,$CLJS.Ts),$CLJS.kj,$CLJS.V($CLJS.Vj,$CLJS.V(tR,$CLJS.ok))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:FR}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof WS?new $CLJS.Gc(function(){return WS},
$CLJS.qd(aO,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LN,"metabase/mbql/schema.cljc",60,$CLJS.DB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.DB,$CLJS.mS,$CLJS.V($CLJS.Vj,hN),$CLJS.kj,
$CLJS.V($CLJS.Vj,$CLJS.V(tR,$CLJS.ok))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:LN}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof YS?new $CLJS.Gc(function(){return YS},$CLJS.qd(sO,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.Gr,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,$CLJS.yi,
$CLJS.U,$CLJS.tk],["0.39.0",$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tN,"metabase/mbql/schema.cljc",51,$CLJS.wG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:tN}(),YS],null)])));
var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null),bT=new $CLJS.dh(null,new $CLJS.h(null,11,[$CLJS.Ut,null,$CLJS.PG,null,$CLJS.PF,null,$CLJS.VG,null,$CLJS.WF,null,$CLJS.zG,null,$CLJS.fG,null,$CLJS.$t,null,$CLJS.NG,null,$CLJS.yG,null,$CLJS.WG,null],null),null),cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null);
$CLJS.X(CM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,1,[$CLJS.Ti,function(a){return"string"===typeof a?$CLJS.ek:$CLJS.n(oM(bT,a))?VM:$CLJS.n(oM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.Wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,$CLJS.ek],null),new $CLJS.P(null,2,5,$CLJS.Q,[VM,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,$CLJS.ZS],null)],null));dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CM],null);
eT=new $CLJS.dh(null,new $CLJS.h(null,26,[$CLJS.SF,null,$CLJS.DJ,null,$CLJS.ms,null,$CLJS.zJ,null,$CLJS.wJ,null,$CLJS.jG,null,$CLJS.At,null,$CLJS.OF,null,$CLJS.AJ,null,$CLJS.tJ,null,$CLJS.zG,null,$CLJS.SG,null,$CLJS.xJ,null,$CLJS.BG,null,$CLJS.UF,null,$CLJS.fJ,null,$CLJS.ls,null,$CLJS.XF,null,$CLJS.fG,null,$CLJS.$I,null,$CLJS.ey,null,$CLJS.gJ,null,$CLJS.sJ,null,$CLJS.kJ,null,$CLJS.UG,null,$CLJS.uG,null],null),null);
fT=new $CLJS.dh(null,new $CLJS.h(null,9,[$CLJS.js,null,$CLJS.ds,null,$CLJS.Hs,null,$CLJS.Js,null,$CLJS.fs,null,$CLJS.OG,null,$CLJS.Os,null,$CLJS.yk,null,$CLJS.hs,null],null),null);gT=new $CLJS.dh(null,new $CLJS.h(null,17,[$CLJS.fo,null,$CLJS.MG,null,$CLJS.FG,null,$CLJS.xG,null,$CLJS.RG,null,$CLJS.TG,null,$CLJS.bG,null,$CLJS.hG,null,$CLJS.HM,null,$CLJS.cG,null,$CLJS.KG,null,$CLJS.DG,null,$CLJS.pk,null,$CLJS.gy,null,$CLJS.qG,null,$CLJS.rG,null,$CLJS.JG,null],null),null);
hT=new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.pJ,null,$CLJS.bJ,null,$CLJS.BJ,null,$CLJS.ls,null,$CLJS.vJ,null],null),null);iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mR],null);jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NN],null);$CLJS.kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OQ],null);$CLJS.lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sR],null);
$CLJS.X(zR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,"numeric expression argument",$CLJS.Ti,function(a){return"number"===typeof a?$CLJS.iu:$CLJS.n(oM(eT,a))?qP:$CLJS.n(oM(gT,a))?$CLJS.lG:$CLJS.n(oM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.wG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,$CLJS.Hl],null),new $CLJS.P(null,2,5,$CLJS.Q,[qP,iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,$CLJS.lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wG,
$CLJS.ZS],null)],null));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zR],null);
$CLJS.X(JQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,"datetime expression argument",$CLJS.Ti,function(a){return $CLJS.n(oM(gT,a))?$CLJS.lG:$CLJS.n(oM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.n(oM(hT,a))?UN:$CLJS.Wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,$CLJS.lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[UN,$CLJS.kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,TS,$CLJS.ZS],null)],null)],null));
var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);
$CLJS.X(mQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,"expression argument",$CLJS.Ti,function(a){return"number"===typeof a?$CLJS.iu:$CLJS.Ed(a)?$CLJS.Ys:$CLJS.n(oM(fT,a))?BQ:$CLJS.n(oM(eT,a))?qP:$CLJS.n(oM(hT,a))?UN:"string"===typeof a?$CLJS.ek:$CLJS.n(oM(bT,a))?VM:$CLJS.n(oM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.Wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,$CLJS.Hl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ys,$CLJS.Ys],null),new $CLJS.P(null,2,5,$CLJS.Q,[BQ,jT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[qP,iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[UN,$CLJS.kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,$CLJS.ek],null),new $CLJS.P(null,2,5,$CLJS.Q,[VM,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,$CLJS.ZS],null)],null));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);$CLJS.X(kQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ht,"numeric expression arg or interval"],null),eka,mT],null));
var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kQ],null);$CLJS.X(NM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,"int greater than zero or numeric expression",$CLJS.Ti,function(a){return"number"===typeof a?$CLJS.iu:$CLJS.Wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,iT],null)],null));var gka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null);
$CLJS.X(VN,pM($CLJS.zG,$CLJS.H(["a",oT,"b",oT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,oT],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VN],null);$CLJS.X(zM,pM($CLJS.PF,$CLJS.H(["s",dT,"start",gka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,mT],null)])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zM],null);$CLJS.X(CQ,pM($CLJS.ey,$CLJS.H(["s",dT])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);$CLJS.X(aN,pM($CLJS.NG,$CLJS.H(["s",dT])));
var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aN],null);$CLJS.X(eN,pM($CLJS.WG,$CLJS.H(["s",dT])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eN],null);$CLJS.X(xN,pM($CLJS.PG,$CLJS.H(["s",dT])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xN],null);$CLJS.X(iO,pM($CLJS.Ut,$CLJS.H(["s",dT])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iO],null);$CLJS.X(SM,pM($CLJS.$t,$CLJS.H(["s",dT])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null);
$CLJS.X(yR,pM($CLJS.VG,$CLJS.H(["s",dT,"match",$CLJS.ek,"replacement",$CLJS.ek])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yR],null);$CLJS.X(vS,pM($CLJS.WF,$CLJS.H(["a",dT,"b",dT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,dT],null)])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vS],null);$CLJS.X(YO,pM($CLJS.yG,$CLJS.H(["s",dT,"pattern",$CLJS.ek])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YO],null);
$CLJS.X(UR,pM($CLJS.ls,$CLJS.H(["x",pT,"y",pT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,pT],null)])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UR],null);$CLJS.X(SR,pM($CLJS.At,$CLJS.H(["x",mT,"y",pT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,pT],null)])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null);$CLJS.X(RR,pM($CLJS.SG,$CLJS.H(["x",mT,"y",mT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,mT],null)])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RR],null);
$CLJS.X(TR,pM($CLJS.ms,$CLJS.H(["x",mT,"y",mT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,mT],null)])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TR],null);$CLJS.X(jQ,pM($CLJS.uG,$CLJS.H(["x",mT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jQ],null);$CLJS.X(bR,pM($CLJS.SF,$CLJS.H(["x",mT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bR],null);$CLJS.X(yS,pM($CLJS.BG,$CLJS.H(["x",mT])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yS],null);$CLJS.X(oP,pM($CLJS.XF,$CLJS.H(["x",mT])));
var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oP],null);$CLJS.X(uP,pM($CLJS.UF,$CLJS.H(["x",mT,"y",mT])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uP],null);$CLJS.X(RP,pM($CLJS.jG,$CLJS.H(["x",mT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RP],null);$CLJS.X(OM,pM($CLJS.OF,$CLJS.H(["x",mT])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null);$CLJS.X(pQ,pM($CLJS.UG,$CLJS.H(["x",mT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pQ],null);
$CLJS.X(nN,pM($CLJS.kJ,$CLJS.H(["datetime-x",nT,"datetime-y",nT,"unit",dka])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nN],null);$CLJS.X(FN,pM($CLJS.zJ,$CLJS.H(["datetime",nT,"unit",cka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,OS],null)])));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FN],null);$CLJS.X(wP,pM($CLJS.gJ,$CLJS.H(["date",nT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wP],null);$CLJS.X(YP,pM($CLJS.DJ,$CLJS.H(["date",nT])));
var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YP],null);$CLJS.X(BM,pM($CLJS.$I,$CLJS.H(["date",nT])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BM],null);$CLJS.X(wS,pM($CLJS.tJ,$CLJS.H(["date",nT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,OS],null)])));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wS],null);$CLJS.X(JO,pM($CLJS.sJ,$CLJS.H(["date",nT])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JO],null);$CLJS.X(SP,pM($CLJS.xJ,$CLJS.H(["date",nT])));
var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SP],null);$CLJS.X(jN,pM($CLJS.AJ,$CLJS.H(["datetime",nT])));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jN],null);$CLJS.X(OO,pM($CLJS.wJ,$CLJS.H(["datetime",nT])));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OO],null);$CLJS.X(cN,pM($CLJS.fJ,$CLJS.H(["datetime",nT])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null);$CLJS.X(tO,pM($CLJS.bJ,$CLJS.H(["datetime",nT,"to",NS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,NS],null)])));
var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tO],null),ZT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"datetime arithmetic unit"],null),$CLJS.mH,$CLJS.jH,$CLJS.Zi,$CLJS.vk,$CLJS.ou,$CLJS.tj,$CLJS.Cj,$CLJS.ti,$CLJS.ni],null);$CLJS.X(yM,pM($CLJS.vJ,$CLJS.H(["datetime",nT,"amount",mT,"unit",ZT])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yM],null);
$CLJS.X(WM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return pM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.pJ));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null);$CLJS.X(xM,pM($CLJS.BJ,$CLJS.H(["datetime",nT,"amount",mT,"unit",ZT])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xM],null);
$CLJS.X(OQ,qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.qd(dQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SO,"metabase/mbql/schema.cljc",51,$CLJS.ls,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ls,$CLJS.OE,$CLJS.V($CLJS.Vj,hQ),ER,$CLJS.V($CLJS.Vj,hQ),WN,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,hQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:SO}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},
$CLJS.qd(Hia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[mO,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",67,$CLJS.vJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vJ,$CLJS.oJ,$CLJS.V($CLJS.Vj,rS),YQ,$CLJS.V($CLJS.Vj,
cR),$CLJS.wi,$CLJS.V($CLJS.Vj,kR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:ZP}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.qd(Bha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,
$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[mO,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pS,"metabase/mbql/schema.cljc",72,$CLJS.BJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BJ,$CLJS.oJ,$CLJS.V($CLJS.Vj,rS),YQ,$CLJS.V($CLJS.Vj,cR),$CLJS.wi,$CLJS.V($CLJS.Vj,kR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(bU)?bU.H:null])):null));
return $CLJS.n(a)?a:pS}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.qd(Fja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.bJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TN,"metabase/mbql/schema.cljc",71,$CLJS.bJ,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bJ,$CLJS.oJ,$CLJS.V($CLJS.Vj,rS),zia,$CLJS.V($CLJS.Vj,HR),Fha,$CLJS.V($CLJS.Vj,$CLJS.V(tR,HR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:TN}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&
"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.qd(hja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.pJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",
45,$CLJS.pJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:xP}(),aU],null)])));$CLJS.cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UP],null);$CLJS.X(DM,pM($CLJS.Os,$CLJS.H(["first-clause",$CLJS.cU,"second-clause",$CLJS.cU,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,$CLJS.cU],null)])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null);
$CLJS.X(pO,pM($CLJS.Hs,$CLJS.H(["first-clause",$CLJS.cU,"second-clause",$CLJS.cU,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,$CLJS.cU],null)])));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null);$CLJS.X(YM,pM($CLJS.Js,$CLJS.H(["clause",$CLJS.cU])));
var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null),gU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,3,[$CLJS.Ht,":field or :expression reference or :relative-datetime",$CLJS.Jt,$CLJS.Xe(":field or :expression reference or :relative-datetime"),$CLJS.Ti,function(a){return $CLJS.n(oM($CLJS.nJ,a))?$CLJS.nJ:$CLJS.Wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,$CLJS.ZS],null)],null);
$CLJS.X(LQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.h(null,1,[$CLJS.Ht,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Hs,$CLJS.Ys,$CLJS.Hl,$CLJS.ek,US,gU,oT,VS],null)],null));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LQ],null);
$CLJS.X(cP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,"order comparable",$CLJS.Ti,function(a){return $CLJS.n(oM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.Wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Hs,$CLJS.Hl,$CLJS.ek,US,oT,gU],null)],null)],null));var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cP],null);
$CLJS.X(KR,pM($CLJS.yk,$CLJS.H(["field",hU,"value-or-field",hU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,hU],null)])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KR],null);$CLJS.X(OR,pM($CLJS.OG,$CLJS.H(["field",hU,"value-or-field",hU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ui,hU],null)])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OR],null);$CLJS.X(JR,pM($CLJS.hs,$CLJS.H(["field",iU,"value-or-field",iU])));
var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JR],null);$CLJS.X(IR,pM($CLJS.ds,$CLJS.H(["field",iU,"value-or-field",iU])));var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IR],null);$CLJS.X(yN,pM($CLJS.js,$CLJS.H(["field",iU,"value-or-field",iU])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yN],null);$CLJS.X(EN,pM($CLJS.fs,$CLJS.H(["field",iU,"value-or-field",iU])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EN],null);$CLJS.X(tQ,pM($CLJS.ZF,$CLJS.H(["field",iU,"min",iU,"max",iU])));
var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tQ],null);$CLJS.X(VQ,pM($CLJS.eG,$CLJS.H(["lat-field",iU,"lon-field",iU,"lat-max",iU,"lon-min",iU,"lat-min",iU,"lon-max",iU])));var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);$CLJS.X(hO,pM($CLJS.YF,$CLJS.H(["field",$CLJS.ZS])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hO],null);$CLJS.X(XO,pM($CLJS.iG,$CLJS.H(["field",$CLJS.ZS])));var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XO],null);$CLJS.X(zO,pM($CLJS.$F,$CLJS.H(["field",$CLJS.ZS])));
var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zO],null);$CLJS.X(GM,pM($CLJS.gG,$CLJS.H(["field",$CLJS.ZS])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GM],null),vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null)],null);$CLJS.X(MO,pM($CLJS.nG,$CLJS.H(["field",dT,"string-or-field",dT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,vU],null)])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MO],null);
$CLJS.X(FP,pM($CLJS.HG,$CLJS.H(["field",dT,"string-or-field",dT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,vU],null)])));var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FP],null);$CLJS.X(xO,pM($CLJS.vG,$CLJS.H(["field",dT,"string-or-field",dT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,vU],null)])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xO],null);$CLJS.X(nQ,pM($CLJS.oG,$CLJS.H(["field",dT,"string-or-field",dT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,vU],null)])));
var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null);$CLJS.X(PM,pM($CLJS.IG,$CLJS.H(["field",$CLJS.ZS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.Ts,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,$CLJS.iw,$CLJS.ky,$CLJS.dG],null)],null),"unit",PS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null)],null)],null)])));var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);
$CLJS.X(EQ,pM($CLJS.VF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bL],null),AS],null)])));var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);
$CLJS.X(NN,qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.qd(xR,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gP,"metabase/mbql/schema.cljc",15,$CLJS.Os,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Os,XN,$CLJS.V($CLJS.Vj,lP),PP,$CLJS.V($CLJS.Vj,lP),gO,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,lP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:gP}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},
$CLJS.qd(TP,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",14,$CLJS.Hs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Hs,XN,$CLJS.V($CLJS.Vj,lP),PP,$CLJS.V($CLJS.Vj,lP),gO,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,lP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:WP}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.qd(FQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yP,"metabase/mbql/schema.cljc",15,$CLJS.Js,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,$CLJS.lS,$CLJS.V($CLJS.Vj,lP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:yP}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.qd(bQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,
$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",13,$CLJS.hs,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hs,$CLJS.wG,$CLJS.V($CLJS.Vj,ZN),iR,$CLJS.V($CLJS.Vj,ZN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:$O}(),lU],null),
new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.qd(eS,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZO,"metabase/mbql/schema.cljc",14,$CLJS.js,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.js,$CLJS.wG,$CLJS.V($CLJS.Vj,ZN),iR,$CLJS.V($CLJS.Vj,ZN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:ZO}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.qd($P,new $CLJS.h(null,
1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",13,$CLJS.ds,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ds,$CLJS.wG,$CLJS.V($CLJS.Vj,ZN),iR,$CLJS.V($CLJS.Vj,ZN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(mU)?mU.H:null])):null));
return $CLJS.n(a)?a:aP}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.qd(YR,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hP,"metabase/mbql/schema.cljc",14,$CLJS.fs,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fs,$CLJS.wG,$CLJS.V($CLJS.Vj,ZN),iR,$CLJS.V($CLJS.Vj,ZN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:hP}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.qd(aQ,new $CLJS.h(null,
1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",13,$CLJS.yk,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yk,$CLJS.wG,$CLJS.V($CLJS.Vj,jS),iR,$CLJS.V($CLJS.Vj,jS),MQ,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,jS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",
$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:bP}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.qd(oN,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KN,"metabase/mbql/schema.cljc",14,$CLJS.OG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OG,$CLJS.wG,$CLJS.V($CLJS.Vj,jS),iR,$CLJS.V($CLJS.Vj,jS),MQ,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,jS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:KN}(),kU],null)])));
$CLJS.X(UP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,"valid filter expression",$CLJS.Ti,function(a){return $CLJS.n(oM(hT,a))?$CLJS.oJ:$CLJS.n(oM(eT,a))?sS:$CLJS.n(oM(bT,a))?$CLJS.ek:$CLJS.n(oM(fT,a))?$CLJS.Ys:$CLJS.Wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,$CLJS.kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[sS,iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ys,jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,qM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.qd(xR,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",
15,$CLJS.Os,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Os,XN,$CLJS.V($CLJS.Vj,lP),PP,$CLJS.V($CLJS.Vj,lP),gO,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,lP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:gP}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.qd(TP,new $CLJS.h(null,1,[$CLJS.gk,
!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",14,$CLJS.Hs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Hs,XN,$CLJS.V($CLJS.Vj,lP),PP,$CLJS.V($CLJS.Vj,lP),gO,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,lP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(eU)?
eU.H:null])):null));return $CLJS.n(a)?a:WP}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.qd(FQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yP,"metabase/mbql/schema.cljc",15,$CLJS.Js,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,$CLJS.lS,$CLJS.V($CLJS.Vj,lP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:yP}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.qd(aQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,
$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",13,$CLJS.yk,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.yk,$CLJS.wG,$CLJS.V($CLJS.Vj,jS),iR,$CLJS.V($CLJS.Vj,jS),MQ,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,jS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(jU)?jU.H:null])):null));
return $CLJS.n(a)?a:bP}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.qd(oN,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KN,"metabase/mbql/schema.cljc",14,$CLJS.OG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OG,$CLJS.wG,$CLJS.V($CLJS.Vj,jS),iR,$CLJS.V($CLJS.Vj,jS),MQ,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,jS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:KN}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},
$CLJS.qd(bQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",13,$CLJS.hs,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hs,$CLJS.wG,$CLJS.V($CLJS.Vj,ZN),iR,$CLJS.V($CLJS.Vj,ZN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:$O}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.qd($P,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aP,"metabase/mbql/schema.cljc",13,$CLJS.ds,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ds,$CLJS.wG,$CLJS.V($CLJS.Vj,ZN),iR,$CLJS.V($CLJS.Vj,ZN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:aP}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.qd(eS,new $CLJS.h(null,
1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",14,$CLJS.js,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.js,$CLJS.wG,$CLJS.V($CLJS.Vj,ZN),iR,$CLJS.V($CLJS.Vj,ZN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(nU)?nU.H:null])):
null));return $CLJS.n(a)?a:ZO}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.qd(YR,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hP,"metabase/mbql/schema.cljc",14,$CLJS.fs,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fs,$CLJS.wG,$CLJS.V($CLJS.Vj,ZN),iR,$CLJS.V($CLJS.Vj,ZN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:hP}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.qd(Zia,new $CLJS.h(null,
1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qN,"metabase/mbql/schema.cljc",19,$CLJS.ZF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ZF,$CLJS.wG,$CLJS.V($CLJS.Vj,ZN),$CLJS.fo,$CLJS.V($CLJS.Vj,ZN),$CLJS.pk,$CLJS.V($CLJS.Vj,ZN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:qN}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.qd(aja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nR,"metabase/mbql/schema.cljc",23,$CLJS.nG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nG,$CLJS.wG,$CLJS.V($CLJS.Vj,hR),JN,$CLJS.V($CLJS.Vj,hR),$CLJS.kj,$CLJS.V($CLJS.Vj,$CLJS.V(tR,DQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:nR}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},
$CLJS.qd(cia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",21,$CLJS.HG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HG,$CLJS.wG,$CLJS.V($CLJS.Vj,hR),JN,$CLJS.V($CLJS.Vj,hR),$CLJS.kj,$CLJS.V($CLJS.Vj,$CLJS.V(tR,DQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:ON}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.qd(oia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$N,"metabase/mbql/schema.cljc",20,$CLJS.vG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vG,$CLJS.wG,$CLJS.V($CLJS.Vj,hR),JN,$CLJS.V($CLJS.Vj,hR),$CLJS.kj,$CLJS.V($CLJS.Vj,$CLJS.V(tR,DQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:$N}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==
typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.qd(Tja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EM,"metabase/mbql/schema.cljc",36,$CLJS.oG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.oG,$CLJS.wG,$CLJS.V($CLJS.Vj,hR),JN,$CLJS.V($CLJS.Vj,hR),$CLJS.kj,$CLJS.V($CLJS.Vj,$CLJS.V(tR,DQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:EM}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.qd(Cja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),
$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lR,"metabase/mbql/schema.cljc",26,$CLJS.eG,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.eG,Lha,$CLJS.V($CLJS.Vj,ZN),Xja,$CLJS.V($CLJS.Vj,ZN),Jja,$CLJS.V($CLJS.Vj,ZN),Oha,$CLJS.V($CLJS.Vj,ZN),Dja,$CLJS.V($CLJS.Vj,ZN),Kha,$CLJS.V($CLJS.Vj,
ZN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:lR}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.qd(pia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HP,"metabase/mbql/schema.cljc",28,$CLJS.$F,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,$CLJS.wG,$CLJS.V($CLJS.Vj,SN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:HP}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&
"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.qd(nja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uQ,"metabase/mbql/schema.cljc",29,$CLJS.gG,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,$CLJS.wG,$CLJS.V($CLJS.Vj,SN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:uQ}(),uU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.qd(Oja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fO,"metabase/mbql/schema.cljc",27,$CLJS.YF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,$CLJS.wG,$CLJS.V($CLJS.Vj,SN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:fO}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&
"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.qd(Bia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vN,"metabase/mbql/schema.cljc",28,$CLJS.iG,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,$CLJS.wG,$CLJS.V($CLJS.Vj,SN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:vN}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.qd(Cha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",33,$CLJS.IG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.IG,$CLJS.wG,$CLJS.V($CLJS.Vj,SN),$CLJS.wk,$CLJS.V($CLJS.Vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.Ts,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,$CLJS.iw,$CLJS.ky,$CLJS.dG],null)],null)),$CLJS.wi,$CLJS.V($CLJS.Vj,fP),$CLJS.kj,$CLJS.V($CLJS.Vj,$CLJS.V(tR,dja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:TQ}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.qd(Jia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VR,"metabase/mbql/schema.cljc",27,$CLJS.VF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,Sha,$CLJS.V($CLJS.Vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,tja,hN],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:VR}(),BU],null)]))],null)],null));
$CLJS.X(GQ,pM($CLJS.fG,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.h(null,1,[$CLJS.Ht,":case subclause"],null),$CLJS.cU,oT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.h(null,1,[$CLJS.bt,!0],null),oT],null)],null)],null)])));
var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);
$CLJS.X(mR,qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.qd(dQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SO,"metabase/mbql/schema.cljc",51,$CLJS.ls,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ls,$CLJS.OE,$CLJS.V($CLJS.Vj,hQ),ER,$CLJS.V($CLJS.Vj,hQ),WN,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,hQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:SO}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},
$CLJS.qd(Via,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",51,$CLJS.At,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.At,$CLJS.OE,$CLJS.V($CLJS.Vj,cR),
ER,$CLJS.V($CLJS.Vj,hQ),WN,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,hQ))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:TO}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM?new $CLJS.Gc(function(){return DT},$CLJS.qd(Uia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,vP,$CLJS.Yi,$CLJS.sj,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,
new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.tA,"metabase/mbql/schema.cljc",$CLJS.SG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.SG,$CLJS.OE,$CLJS.V($CLJS.Vj,cR),ER,$CLJS.V($CLJS.Vj,cR),WN,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,cR))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:$CLJS.tA}(),DT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.qd(Wia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RO,"metabase/mbql/schema.cljc",51,$CLJS.ms,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ms,$CLJS.OE,$CLJS.V($CLJS.Vj,cR),ER,$CLJS.V($CLJS.Vj,cR),WN,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,cR))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:RO}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},
$CLJS.qd(QQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eR,"metabase/mbql/schema.cljc",58,$CLJS.zG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zG,NR,$CLJS.V($CLJS.Vj,JM),MR,$CLJS.V($CLJS.Vj,
JM),WN,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,JM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:eR}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.qd(mja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,
$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xQ,"metabase/mbql/schema.cljc",56,$CLJS.ey,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ey,$CLJS.By,$CLJS.V($CLJS.Vj,hR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:xQ}(),sT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.qd(dia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sP,"metabase/mbql/schema.cljc",55,$CLJS.uG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,$CLJS.OE,$CLJS.V($CLJS.Vj,cR)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:sP}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.qd(Yha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),
$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gN,"metabase/mbql/schema.cljc",54,$CLJS.SF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,$CLJS.OE,$CLJS.V($CLJS.Vj,cR)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(GT)?
GT.H:null])):null));return $CLJS.n(a)?a:gN}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.qd(lia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",55,$CLJS.BG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,$CLJS.OE,$CLJS.V($CLJS.Vj,cR)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:iP}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&
"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.qd(Uja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",53,$CLJS.XF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.XF,$CLJS.OE,$CLJS.V($CLJS.Vj,cR)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:LP}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.qd(Hja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,
rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[bS,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wR,"metabase/mbql/schema.cljc",69,$CLJS.UF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.UF,$CLJS.OE,$CLJS.V($CLJS.Vj,cR),ER,$CLJS.V($CLJS.Vj,cR)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:wR}(),JT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.qd(Eia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[bS,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NO,"metabase/mbql/schema.cljc",68,$CLJS.jG,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,$CLJS.OE,$CLJS.V($CLJS.Vj,cR)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:NO}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.qd(Ija,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),
$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[bS,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kP,"metabase/mbql/schema.cljc",67,$CLJS.OF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,$CLJS.OE,$CLJS.V($CLJS.Vj,cR)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(LT)?
LT.H:null])):null));return $CLJS.n(a)?a:kP}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.qd(Rha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[bS,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gS,"metabase/mbql/schema.cljc",67,$CLJS.UG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UG,$CLJS.OE,$CLJS.V($CLJS.Vj,cR)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:gS}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&
"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.qd(UM,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",61,$CLJS.fG,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.fG,$CLJS.rx,$CLJS.V($CLJS.Vj,lO),$CLJS.kj,$CLJS.V($CLJS.Vj,$CLJS.V(tR,VP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:CP}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.qd(Uha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,
$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.kJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",65,$CLJS.kJ,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kJ,fia,$CLJS.V($CLJS.Vj,rS),gia,$CLJS.V($CLJS.Vj,rS),$CLJS.wi,$CLJS.V($CLJS.Vj,Mia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:MP}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.qd(qja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.qd(aR,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",71,$CLJS.zJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zJ,$CLJS.oJ,$CLJS.V($CLJS.Vj,rS),$CLJS.wi,$CLJS.V($CLJS.Vj,Nja),$CLJS.Ci,$CLJS.V($CLJS.Vj,$CLJS.V(tR,zS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:eQ}(),OT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.qd(xia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DN,"metabase/mbql/schema.cljc",71,$CLJS.gJ,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,$CLJS.OP,$CLJS.V($CLJS.Vj,rS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:DN}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.qd(mia,new $CLJS.h(null,1,[$CLJS.gk,!0],
null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cQ,"metabase/mbql/schema.cljc",74,$CLJS.DJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DJ,$CLJS.OP,$CLJS.V($CLJS.Vj,rS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:cQ}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.qd(Tia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),
$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zQ,"metabase/mbql/schema.cljc",72,$CLJS.$I,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$I,$CLJS.OP,$CLJS.V($CLJS.Vj,rS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:zQ}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==
typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.qd(Mja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yQ,"metabase/mbql/schema.cljc",
71,$CLJS.tJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.tJ,$CLJS.OP,$CLJS.V($CLJS.Vj,rS),$CLJS.Ci,$CLJS.V($CLJS.Vj,$CLJS.V(tR,zS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:yQ}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.qd(Iha,new $CLJS.h(null,1,[$CLJS.gk,
!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",70,$CLJS.sJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sJ,$CLJS.OP,$CLJS.V($CLJS.Vj,rS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:rQ}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.qd(Nia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),
$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",78,$CLJS.xJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xJ,$CLJS.OP,$CLJS.V($CLJS.Vj,rS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:iQ}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==
typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.qd(Lia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KM,"metabase/mbql/schema.cljc",
71,$CLJS.AJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AJ,$CLJS.oJ,$CLJS.V($CLJS.Vj,rS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:KM}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.qd(uja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,
$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kS,"metabase/mbql/schema.cljc",73,$CLJS.wJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wJ,$CLJS.oJ,$CLJS.V($CLJS.Vj,rS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(WT)?WT.H:null])):null));
return $CLJS.n(a)?a:kS}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.qd(Iia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.zJ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",73,$CLJS.fJ,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fJ,$CLJS.oJ,$CLJS.V($CLJS.Vj,rS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:EP}(),XT],null)])));
$CLJS.X(ZM,qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.qd(kja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HQ,"metabase/mbql/schema.cljc",59,$CLJS.PF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PF,$CLJS.By,$CLJS.V($CLJS.Vj,hR),$CLJS.Ov,$CLJS.V($CLJS.Vj,iia),$CLJS.ey,$CLJS.V($CLJS.Vj,$CLJS.V(tR,cR))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:HQ}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},
$CLJS.qd(Kja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dR,"metabase/mbql/schema.cljc",54,$CLJS.NG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,$CLJS.By,$CLJS.V($CLJS.Vj,hR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:dR}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.qd(Oia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,
1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",55,$CLJS.PG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PG,$CLJS.By,$CLJS.V($CLJS.Vj,hR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:NQ}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&
"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.qd(lja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",
55,$CLJS.WG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WG,$CLJS.By,$CLJS.V($CLJS.Vj,hR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:QP}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.qd(Dha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,
$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PR,"metabase/mbql/schema.cljc",57,$CLJS.VG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.VG,$CLJS.By,$CLJS.V($CLJS.Vj,hR),Pha,$CLJS.V($CLJS.Vj,$CLJS.ek),$CLJS.AA,$CLJS.V($CLJS.Vj,$CLJS.ek)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:PR}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.qd(oja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uS,"metabase/mbql/schema.cljc",55,$CLJS.$t,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$t,$CLJS.By,$CLJS.V($CLJS.Vj,hR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:uS}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==
typeof uM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.qd(Lja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",55,$CLJS.Ut,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ut,$CLJS.By,$CLJS.V($CLJS.Vj,hR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:qR}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.qd(cja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,
$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EO,"metabase/mbql/schema.cljc",56,$CLJS.WF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.WF,NR,$CLJS.V($CLJS.Vj,hR),MR,$CLJS.V($CLJS.Vj,hR),WN,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,hR))],null),$CLJS.Kc,"Schema for a valid concat clause.",$CLJS.n(zT)?
zT.H:null])):null));return $CLJS.n(a)?a:EO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.qd(jja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.lQ,"null",$CLJS.Jda,"null"],null),null),
$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BN,"metabase/mbql/schema.cljc",74,$CLJS.yG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.yG,$CLJS.By,$CLJS.V($CLJS.Vj,hR),$CLJS.zA,$CLJS.V($CLJS.Vj,$CLJS.ek)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:BN}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==
typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.qd(QQ,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.lQ,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
eR,"metabase/mbql/schema.cljc",58,$CLJS.zG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zG,NR,$CLJS.V($CLJS.Vj,JM),MR,$CLJS.V($CLJS.Vj,JM),WN,$CLJS.V($CLJS.Vj,$CLJS.V($CLJS.Ri,JM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:eR}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},
$CLJS.qd(UM,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",61,$CLJS.fG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fG,$CLJS.rx,$CLJS.V($CLJS.Vj,lO),$CLJS.kj,
$CLJS.V($CLJS.Vj,$CLJS.V(tR,VP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:CP}(),CU],null)])));
$CLJS.DU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,":field or :expression reference or expression",$CLJS.Ti,function(a){return $CLJS.n(oM(eT,a))?sS:$CLJS.n(oM(bT,a))?$CLJS.ek:$CLJS.n(oM(fT,a))?$CLJS.Ys:$CLJS.n(oM(hT,a))?$CLJS.oJ:$CLJS.n(oM($CLJS.fG,a))?$CLJS.fG:$CLJS.Wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[sS,iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ys,jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,$CLJS.kT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fG,CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,$CLJS.ZS],null)],null);$CLJS.X(ZR,pM($CLJS.gy,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,$CLJS.ZS],null)])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZR],null);$CLJS.X(NP,pM($CLJS.xG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,$CLJS.ZS],null)])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);$CLJS.X(XM,pM($CLJS.qG,$CLJS.H(["field-or-expression",$CLJS.DU])));
var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null);$CLJS.X(QR,pM($CLJS.TG,$CLJS.H(["field-or-expression",$CLJS.DU])));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QR],null);$CLJS.X(wO,pM($CLJS.bG,$CLJS.H(["field-or-expression",$CLJS.DU])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wO],null);$CLJS.X(fR,pM($CLJS.JG,$CLJS.H(["field-or-expression",$CLJS.DU])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fR],null);$CLJS.X(rO,pM($CLJS.fo,$CLJS.H(["field-or-expression",$CLJS.DU])));
var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rO],null);$CLJS.X(dO,pM($CLJS.pk,$CLJS.H(["field-or-expression",$CLJS.DU])));var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);$CLJS.X(YN,pM($CLJS.RG,$CLJS.H(["field-or-expression",$CLJS.DU,"pred",$CLJS.cU])));var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YN],null);$CLJS.X(oO,pM($CLJS.FG,$CLJS.H(["pred",$CLJS.cU])));var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oO],null);$CLJS.X(XQ,pM($CLJS.DG,$CLJS.H(["pred",$CLJS.cU])));
var OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XQ],null);$CLJS.X(uN,pM($CLJS.MG,$CLJS.H(["field-or-expression",$CLJS.DU])));var PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uN],null);$CLJS.X(wM,pM($CLJS.cG,$CLJS.H(["field-or-expression",$CLJS.DU])));var QU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wM],null);$CLJS.X(AO,pM($CLJS.KG,$CLJS.H(["field-or-expression",$CLJS.DU])));var RU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AO],null);$CLJS.X(nP,pM($CLJS.hG,$CLJS.H(["field-or-expression",$CLJS.DU,"percentile",mT])));
var SU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nP],null);$CLJS.X($Q,pM($CLJS.rG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$K],null),AS],null)])));var TU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$Q],null);
$CLJS.X(WQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,"unnamed aggregation clause or numeric expression",$CLJS.Ti,function(a){return $CLJS.n(oM(eT,a))?qP:$CLJS.Wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[qP,iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wt,qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.qd(ija,
new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",60,$CLJS.qG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,rR,$CLJS.V($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:zP}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.qd(tia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DR,"metabase/mbql/schema.cljc",64,$CLJS.TG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TG,rR,$CLJS.V($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:DR}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==
typeof uM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.qd(kia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DP,"metabase/mbql/schema.cljc",65,$CLJS.bG,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,rR,$CLJS.V($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:DP}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof PU?new $CLJS.Gc(function(){return PU},$CLJS.qd(Eja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,
$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.GG,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RM,"metabase/mbql/schema.cljc",76,$CLJS.MG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,rR,$CLJS.V($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(PU)?PU.H:null])):null));return $CLJS.n(a)?a:RM}(),PU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.qd(Yia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mN,"metabase/mbql/schema.cljc",60,$CLJS.JG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,rR,$CLJS.V($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:mN}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.qd(Mha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,
$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.nt,"metabase/mbql/schema.cljc",60,$CLJS.fo,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fo,rR,$CLJS.V($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(KU)?KU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.nt}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.qd(Hha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.rt,"metabase/mbql/schema.cljc",60,$CLJS.pk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pk,rR,$CLJS.V($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:$CLJS.rt}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&
"undefined"!==typeof TU?new $CLJS.Gc(function(){return TU},$CLJS.qd(hia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DO,"metabase/mbql/schema.cljc",18,$CLJS.rG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,uia,$CLJS.V($CLJS.Vj,new $CLJS.P(null,3,5,
$CLJS.Q,[$CLJS.Hs,yia,hN],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(TU)?TU.H:null])):null));return $CLJS.n(a)?a:DO}(),TU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.qd(Gia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,
$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vO,"metabase/mbql/schema.cljc",62,$CLJS.DG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,$CLJS.xo,$CLJS.V($CLJS.Vj,lP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:vO}(),OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.qd(Nha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iN,"metabase/mbql/schema.cljc",68,$CLJS.FG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,$CLJS.xo,$CLJS.V($CLJS.Vj,lP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:iN}(),NU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.qd(Sja,new $CLJS.h(null,1,[$CLJS.gk,!0],
null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",66,$CLJS.RG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.RG,rR,$CLJS.V($CLJS.Vj,jR),$CLJS.xo,$CLJS.V($CLJS.Vj,lP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:AP}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.qd(UM,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",61,$CLJS.fG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fG,$CLJS.rx,$CLJS.V($CLJS.Vj,lO),$CLJS.kj,$CLJS.V($CLJS.Vj,$CLJS.V(tR,VP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:CP}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&
"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof RU?new $CLJS.Gc(function(){return RU},$CLJS.qd(xja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sG,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BR,"metabase/mbql/schema.cljc",
68,$CLJS.KG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KG,rR,$CLJS.V($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(RU)?RU.H:null])):null));return $CLJS.n(a)?a:BR}(),RU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof SU?new $CLJS.Gc(function(){return SU},$CLJS.qd(Sia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,
vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sG,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZQ,"metabase/mbql/schema.cljc",72,$CLJS.hG,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hG,rR,$CLJS.V($CLJS.Vj,jR),$CLJS.hG,$CLJS.V($CLJS.Vj,cR)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(SU)?SU.H:null])):
null));return $CLJS.n(a)?a:ZQ}(),SU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof QU?new $CLJS.Gc(function(){return QU},$CLJS.qd(Xha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MM,"metabase/mbql/schema.cljc",77,$CLJS.cG,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,rR,$CLJS.V($CLJS.Vj,jR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:MM}(),QU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.qd(Jha,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,
$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",74,$CLJS.xG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,$CLJS.wG,$CLJS.V($CLJS.Vj,$CLJS.V(tR,SN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:mP}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.qd(jia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([tP,$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,vR,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.RF,"null"],null),null),
$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Bi,"metabase/mbql/schema.cljc",70,$CLJS.gy,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gy,$CLJS.wG,$CLJS.V($CLJS.Vj,$CLJS.V(tR,SN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:$CLJS.Bi}(),EU],null)]))],null)],null));
$CLJS.X(oQ,pM($CLJS.HM,$CLJS.H(["aggregation",WQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ok,new $CLJS.h(null,1,[$CLJS.Ht,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.bt,!0],null),AS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),AS],null)],null)])));
$CLJS.X(sR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ht,"aggregation clause or numeric expression",$CLJS.Ti,function(a){return $CLJS.n(oM($CLJS.HM,a))?$CLJS.HM:kO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[kO,WQ],null)],null));$CLJS.X(QM,pM($CLJS.tG,$CLJS.H(["field",aT])));var UU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null);$CLJS.X(pP,pM($CLJS.mG,$CLJS.H(["field",aT])));
VU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pP],null);
hka=qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof UU?new $CLJS.Gc(function(){return UU},$CLJS.qd(Bja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
WO,"metabase/mbql/schema.cljc",15,$CLJS.tG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,$CLJS.wG,$CLJS.V($CLJS.Vj,LR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(UU)?UU.H:null])):null));return $CLJS.n(a)?a:WO}(),UU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof VU?new $CLJS.Gc(function(){return VU},$CLJS.qd(sia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,
$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",16,$CLJS.mG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,$CLJS.wG,$CLJS.V($CLJS.Vj,LR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(VU)?VU.H:null])):null));return $CLJS.n(a)?a:RQ}(),VU],null)]));
WU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,$CLJS.TM,$CLJS.IN,$CLJS.QF,$CLJS.iu,$CLJS.KB,$CLJS.OP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BE,AS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.bt,!0],null),AS],null)],null);
ika=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,WU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.TM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FM,AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fQ,DS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),DS],null)],null)],null);
jka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,WU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.IN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oR,DS],null)],null)],null);
XU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,WU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.pi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jO,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null)],null)],null);kka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CR],null);
lka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,XU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.QF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,$CLJS.bj,$CLJS.pi],null)],null)],null)],null);
$CLJS.YU=new $CLJS.dh(null,new $CLJS.h(null,4,[$CLJS.OP,null,$CLJS.iu,null,$CLJS.Ys,null,$CLJS.KB,null],null),null);mka=$CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Es],null),$CLJS.YU);
$CLJS.X(KQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.rj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,lka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TM,ika],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IN,jka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,XU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,mka],null)],null)],null)],null)],null));
var ZU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,AS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Te(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yO,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,AS],null)],null)],null),nka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,ZU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,$CLJS.pi],null)],null)],null),$U=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sQ],null),aV=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,1,[$CLJS.Ti,function(a){return $CLJS.n($CLJS.uE($CLJS.zd,
$CLJS.vQ)(a))?$CLJS.vQ:$CLJS.AE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,ZU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vQ,$CLJS.pi],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AE,$U],null)],null),bV=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tD,BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$ia,AS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.QO,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,CS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eO,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.ok],null)],null)],null),cV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$ja,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Is,new $CLJS.h(null,1,[$CLJS.Ht,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),oka=$CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Es],null),
new $CLJS.dh(null,new $CLJS.h(null,4,[$CLJS.vF,null,$CLJS.eF,null,$CLJS.oF,null,$CLJS.sF,null],null),null)),dV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AR],null);
$CLJS.X(uO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rP,new $CLJS.h(null,1,[$CLJS.bt,!0],null),cV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),aV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cS,$CLJS.cU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),oka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fF,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.My,$CLJS.uA],null),dV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,new $CLJS.h(null,1,[$CLJS.bt,!0],null),AS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mL,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,FS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dP,new $CLJS.h(null,
1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,bV],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.uE($CLJS.PA($CLJS.rP,$CLJS.UQ),$CLJS.We($CLJS.uE($CLJS.rP,$CLJS.UQ)))],null)],null));var pka=$CLJS.Q,eV;var fV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uO],null)],null);
if($CLJS.xd(fV)&&$CLJS.E.g($CLJS.z(fV),$CLJS.Ej)){var gV=$CLJS.y(fV);$CLJS.z(gV);var hV=$CLJS.B(gV),qka=$CLJS.zd($CLJS.z(hV))?hV:$CLJS.he(null,hV),iV=$CLJS.y(qka),rka=$CLJS.z(iV),ska=$CLJS.B(iV);eV=$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.R.j(rka,$CLJS.fo,1)],null),ska)}else eV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,fV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(wN,new $CLJS.P(null,3,5,pka,[$CLJS.Os,eV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"All join aliases must be unique."],null),function(a){return $CLJS.aM($CLJS.of($CLJS.Wa,$CLJS.lf.g($CLJS.jF,a)))}],null)],null));var tka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wN],null);
$CLJS.X(AR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wl,new $CLJS.h(null,1,[$CLJS.Ht,"Distinct, non-empty sequence of Field clauses"],null),rM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.fo,1],null),$CLJS.ZS],null))],null));
$CLJS.X(sQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),aV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rP,new $CLJS.h(null,1,[$CLJS.bt,!0],null),cV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.fo,1],null),$CLJS.lT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,new $CLJS.h(null,1,
[$CLJS.bt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.fo,1],null),$CLJS.ZS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,AS,$CLJS.DU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fF,new $CLJS.h(null,1,[$CLJS.bt,!0],null),dV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.cU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xS,new $CLJS.h(null,
1,[$CLJS.bt,!0],null),ES],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aS,new $CLJS.h(null,1,[$CLJS.bt,!0],null),rM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.fo,1],null),hka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[wQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[wQ,DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[bia,DS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,new $CLJS.h(null,1,[$CLJS.bt,!0],null),tka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dP,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,bV],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.km(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UQ,$CLJS.rP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,
new $CLJS.h(null,1,[$CLJS.Ht,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.kG);b=$CLJS.J.g(b,$CLJS.fF);return $CLJS.ud($CLJS.wu.g($CLJS.ih(a),$CLJS.ih(b)))}],null)],null));
$CLJS.jV=$CLJS.Lg([$CLJS.QG,$CLJS.OP,qO,bO,eP,oS,LO,$CLJS.nS,$CLJS.iu,KO,$M,gR,dS,$CLJS.pN,$R,nO,$CLJS.jP,IO,$CLJS.dj,$CLJS.HO,IM,$CLJS.MN,$CLJS.GO,JP,$CLJS.Ys,$CLJS.KB,CO],[new $CLJS.h(null,1,[VO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.QG,null,$CLJS.OP,null,$CLJS.iu,null,$CLJS.Ys,null,$CLJS.KB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.OP,VO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.QG,null,$CLJS.OP,null,$CLJS.nS,null,$CLJS.jP,null,$CLJS.dj,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.rj,$CLJS.ek,$CLJS.JE,pR,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[qO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,sS,$CLJS.JE,pR,VO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.QG,null,bO,null,$CLJS.iu,null,$CLJS.dj,null,IM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.ek,$CLJS.JE,dN,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[eP,null],null),null)],null),new $CLJS.h(null,1,[VO,new $CLJS.dh(null,new $CLJS.h(null,1,[oS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,
sS,$CLJS.JE,rja,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[LO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.OP,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,sS,VO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.QG,null,bO,null,$CLJS.iu,null,$CLJS.dj,null,IM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,sS,$CLJS.JE,dN,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[KO,null],null),null)],null),new $CLJS.h(null,1,[VO,new $CLJS.dh(null,
new $CLJS.h(null,1,[$M,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.ek,$CLJS.JE,pR,VO,new $CLJS.dh(null,new $CLJS.h(null,8,[$CLJS.QG,null,oS,null,$M,null,gR,null,IO,null,$CLJS.dj,null,IM,null,$CLJS.KB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.ek,$CLJS.JE,dN,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[dS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.OP,VO,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.nS,null,$CLJS.pN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.rj,$CLJS.ek,$CLJS.JE,dN,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[$R,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.ek,$CLJS.JE,dN,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[nO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.OP,VO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.QG,null,$CLJS.OP,null,$CLJS.nS,null,$CLJS.jP,null,$CLJS.dj,null],null),null)],null),new $CLJS.h(null,1,[VO,new $CLJS.dh(null,new $CLJS.h(null,1,[IO,null],null),null)],null),new $CLJS.h(null,1,[VO,
new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.dj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.OP,VO,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.nS,null,$CLJS.HO,null],null),null)],null),new $CLJS.h(null,1,[VO,new $CLJS.dh(null,new $CLJS.h(null,1,[IM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.OP,VO,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.nS,null,$CLJS.MN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.OP,VO,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.nS,
null,$CLJS.GO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,sS,$CLJS.JE,pR,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[JP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.Ys,VO,new $CLJS.dh(null,new $CLJS.h(null,3,[$CLJS.QG,null,$CLJS.dj,null,$CLJS.Ys,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.ek,VO,new $CLJS.dh(null,new $CLJS.h(null,8,[$CLJS.QG,null,oS,null,$M,null,gR,null,IO,null,$CLJS.dj,null,IM,null,$CLJS.KB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,
sS,$CLJS.JE,dN,VO,new $CLJS.dh(null,new $CLJS.h(null,1,[CO,null],null),null)],null)]);$CLJS.X(CR,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"valid parameter type"],null)],null),$CLJS.$g($CLJS.jV)));$CLJS.X(lN,$CLJS.hg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"valid template tag widget type"],null),$CLJS.uA],null),$CLJS.$g($CLJS.jV)));
$CLJS.X(PQ,pM(Tha,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,AS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,AS],null)],null)],null)])));var kV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PQ],null);$CLJS.X(LM,pM($CLJS.QF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.ZS,kV],null)])));var lV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);$CLJS.X(GN,pM(FO,$CLJS.H(["target",kV])));
var mV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GN],null),uka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.ZS,qM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof lV?new $CLJS.Gc(function(){return lV},$CLJS.qd(Qia,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AQ,"metabase/mbql/schema.cljc",21,$CLJS.QF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,$CLJS.GJ,$CLJS.V($CLJS.Vj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,SN,UO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(lV)?lV.H:null])):null));return $CLJS.n(a)?a:AQ}(),lV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=vP.h($CLJS.rd("undefined"!==typeof $CLJS.sM&&
"undefined"!==typeof tM&&"undefined"!==typeof uM&&"undefined"!==typeof mV?new $CLJS.Gc(function(){return mV},$CLJS.qd(fja,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,vP,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,rN,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.qd(aR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tS,"metabase/mbql/schema.cljc",20,FO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[FO,$CLJS.GJ,
$CLJS.V($CLJS.Vj,UO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(mV)?mV.H:null])):null));return $CLJS.n(a)?a:tS}(),mV],null)]))],null),lM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);
$CLJS.X(sN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Os,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.uE($CLJS.PA($CLJS.vQ,$CLJS.TF),$CLJS.We($CLJS.uE($CLJS.vQ,$CLJS.TF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.vQ);var d=$CLJS.J.g(c,$CLJS.TF);c=$CLJS.J.g(c,$CLJS.rj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.Ht,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.kL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.TF,$CLJS.vQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),nka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$U],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HN,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,kka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.bt,!0],null),AS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GJ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),uka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.pi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.bt,!0],null),AS],null),new $CLJS.P(null,3,5,$CLJS.Q,[Zha,new $CLJS.h(null,1,[$CLJS.bt,!0],null),
AS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.pi],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oL,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[Qja,new $CLJS.h(null,1,[$CLJS.bt,!0],null),NS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pia,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[BP,new $CLJS.h(null,1,[$CLJS.bt,!0],null),ES],null),new $CLJS.P(null,3,5,$CLJS.Q,[kN,new $CLJS.h(null,1,[$CLJS.bt,!0],null),ES],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,BP);b=$CLJS.J.g(b,kN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fia,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[Aja,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vha,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eha,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[aia,new $CLJS.h(null,1,[$CLJS.bt,
!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[sja,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Gha,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Gja,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wy,new $CLJS.h(null,
1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CN,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.Es,eja,bja,$CLJS.yO,Ria,gja,Dia,Qha,Wha,eia,ria,pja,Yja,via,nia],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[vja,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,DS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Aia,
new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,DS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oR,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,Zja],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,AS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Cia,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,DS],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Pja,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,$CLJS.pi,$CLJS.pi],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wia,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,DS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BO,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,$CLJS.pi,
$CLJS.pi],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YK,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.pi],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.We($CLJS.dP)],null)],null));var nV=$CLJS.kM(lM);
(function(){var a=zha();return function(b){if($CLJS.n(nV.h?nV.h(b):nV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.hM(b);throw $CLJS.li($CLJS.wE("Invalid query: {0}",$CLJS.H([$CLJS.Ph.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Sy,c,Kia,b],null));}})();