var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var aJ,cJ,eJ,qJ,uJ,CJ;$CLJS.$I=new $CLJS.M(null,"get-month","get-month",-369374731);aJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.bJ=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);cJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.dJ=new $CLJS.M(null,"iso","iso",-1366207543);eJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.fJ=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.gJ=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.hJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.iJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.jJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.kJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.lJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.mJ=new $CLJS.M(null,"us","us",746429226);$CLJS.nJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.oJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.pJ=new $CLJS.M(null,"now","now",-1650525531);qJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.rJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.sJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.tJ=new $CLJS.M(null,"get-week","get-week",-888059349);
uJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.vJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.wJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.xJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.yJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.zJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.AJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.BJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);CJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.DJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.EJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.FJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.GJ=new $CLJS.M(null,"target","target",253001721);$CLJS.LF($CLJS.lJ,$CLJS.H([$CLJS.At,$CLJS.rD,$CLJS.Ts,$CLJS.rH]));$CLJS.$E.m(null,$CLJS.iJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.aF(a);return $CLJS.wd(a)?(a=$CLJS.wu.g(a,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.gD,null,$CLJS.FD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.EF($CLJS.iJ,$CLJS.qF);
for(var HJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vJ,$CLJS.BJ],null)),IJ=null,JJ=0,KJ=0;;)if(KJ<JJ){var LJ=IJ.X(null,KJ);$CLJS.LF(LJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rH],null)]));$CLJS.EF(LJ,$CLJS.iJ);KJ+=1}else{var MJ=$CLJS.y(HJ);if(MJ){var NJ=MJ;if($CLJS.Cd(NJ)){var OJ=$CLJS.lc(NJ),Hga=$CLJS.mc(NJ),Iga=OJ,Jga=$CLJS.D(OJ);HJ=Hga;IJ=Iga;JJ=Jga}else{var PJ=$CLJS.z(NJ);$CLJS.LF(PJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.iF],null),$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rH],null)]));$CLJS.EF(PJ,$CLJS.iJ);HJ=$CLJS.B(NJ);IJ=null;JJ=0}KJ=0}else break}
for(var QJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gJ,$CLJS.$I,$CLJS.sJ,$CLJS.AJ,$CLJS.wJ,$CLJS.fJ,$CLJS.DJ],null)),RJ=null,SJ=0,TJ=0;;)if(TJ<SJ){var Kga=RJ.X(null,TJ);$CLJS.LF(Kga,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));TJ+=1}else{var UJ=$CLJS.y(QJ);if(UJ){var VJ=UJ;if($CLJS.Cd(VJ)){var WJ=$CLJS.lc(VJ),Lga=$CLJS.mc(VJ),Mga=WJ,Nga=$CLJS.D(WJ);QJ=Lga;RJ=Mga;SJ=Nga}else{var Oga=$CLJS.z(VJ);$CLJS.LF(Oga,$CLJS.H([$CLJS.At,
$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));QJ=$CLJS.B(VJ);RJ=null;SJ=0}TJ=0}else break}$CLJS.LF($CLJS.kJ,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oH],null)]));
for(var XJ=$CLJS.y(new $CLJS.dh(null,new $CLJS.h(null,8,[$CLJS.DJ,null,$CLJS.wJ,null,$CLJS.AJ,null,$CLJS.xJ,null,$CLJS.fJ,null,$CLJS.$I,null,$CLJS.gJ,null,$CLJS.sJ,null],null),null)),YJ=null,ZJ=0,$J=0;;)if($J<ZJ){var Pga=YJ.X(null,$J);$CLJS.LF(Pga,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$J+=1}else{var aK=$CLJS.y(XJ);if(aK){var bK=aK;if($CLJS.Cd(bK)){var cK=$CLJS.lc(bK),Qga=$CLJS.mc(bK),Rga=cK,Sga=$CLJS.D(cK);
XJ=Qga;YJ=Rga;ZJ=Sga}else{var Tga=$CLJS.z(bK);$CLJS.LF(Tga,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));XJ=$CLJS.B(bK);YJ=null;ZJ=0}$J=0}else break}$CLJS.X(CJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,$CLJS.dJ,$CLJS.mJ,$CLJS.rJ],null));
$CLJS.NF($CLJS.tJ,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.yJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,$CLJS.GE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,2,[$CLJS.Ht,"valid timezone ID",$CLJS.Jt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return["invalid timezone ID: ",$CLJS.Ph.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.iH($CLJS.Md,$CLJS.ZI.tz.names())),$CLJS.QI],null)],null));
$CLJS.NF($CLJS.bJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yJ],null)],null)],null)],
null)]));$CLJS.EF($CLJS.bJ,$CLJS.iJ);$CLJS.LF($CLJS.pJ,$CLJS.H([$CLJS.At,$CLJS.jD]));$CLJS.X(eJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Ue(function(b){return $CLJS.GC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gD,$CLJS.FD],null))}],null)],null));
$CLJS.X(aJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GB,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eJ],null)],null)],null)],null));
$CLJS.XG.g($CLJS.hJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.Ht,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.hJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.Ht,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.gi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.Ht,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.iw],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.gi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lH],null)],null)],null)],null)],null)],null));
$CLJS.$E.m(null,$CLJS.hJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.iw)?$CLJS.E.g(a,$CLJS.gi)?$CLJS.FD:$CLJS.n($CLJS.DF($CLJS.qH,a))?$CLJS.gD:$CLJS.FD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.qh($CLJS.LI,b))?$CLJS.gD:$CLJS.n($CLJS.qh($CLJS.MI,b))?$CLJS.gD:null:null;if($CLJS.n(a))return a;b=$CLJS.aF(b);b=$CLJS.wd(b)?$CLJS.hg.j($CLJS.eh,$CLJS.nf(function(c){return $CLJS.GC(c,$CLJS.bk)}),b):b;return $CLJS.wd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.X(uJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.iw],null),$CLJS.Ts],null));
$CLJS.NF($CLJS.nJ,$CLJS.H([$CLJS.At,$CLJS.FD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rH],null)],null)],null)],null)]));
$CLJS.LF($CLJS.Vy,$CLJS.H([$CLJS.At,$CLJS.eE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pH],null)]));$CLJS.X(cJ,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Es,$CLJS.nH,$CLJS.Ei,$CLJS.rk,$CLJS.jJ,$CLJS.EJ,$CLJS.FJ,$CLJS.Lj,$CLJS.Li,$CLJS.Ai,$CLJS.nj,$CLJS.kH],null));$CLJS.X(qJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,$CLJS.dJ,$CLJS.mJ,$CLJS.rJ],null));
$CLJS.NF($CLJS.zJ,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qJ],null)],null)],
null)],null)]));