var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var EW,Rka,Ska,MW,Tka,Uka,Wka,IW,Xka,Vka;EW=function(a){return a+1};$CLJS.FW=function(a){if("string"===typeof a){var b=$CLJS.qh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.pm(a));};
Rka=function(a){var b=new $CLJS.Fa,c=$CLJS.Nu;$CLJS.Nu=new $CLJS.sc(b);try{var d=$CLJS.Nu,e=$CLJS.Va($CLJS.Vv(d)),f=$CLJS.Nu;$CLJS.Nu=e?$CLJS.Mv(d):d;try{d=$CLJS.Xv;$CLJS.Xv=!0;try{$CLJS.Zv(a)}finally{$CLJS.Xv=d}$CLJS.E.g(0,$CLJS.Uu($CLJS.Nu,$CLJS.Wu))||$CLJS.bc($CLJS.Nu,"\n");$CLJS.Tu()}finally{$CLJS.Nu=f}$CLJS.xh($CLJS.p.h(b))}finally{$CLJS.Nu=c}};$CLJS.GW=function(a,b){return $CLJS.Ve.j($CLJS.ha,a,b)};
$CLJS.HW=function(a){var b=$CLJS.Jv;$CLJS.Jv=120;try{var c=new $CLJS.Fa,d=$CLJS.Oa,e=$CLJS.Ra;$CLJS.Oa=!0;$CLJS.Ra=function(f){return c.append(f)};try{Rka(a)}finally{$CLJS.Ra=e,$CLJS.Oa=d}return $CLJS.p.h(c)}finally{$CLJS.Jv=b}};
Ska=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.QV,$CLJS.Xd),c=$CLJS.J.j(a,$CLJS.NV,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.$e($CLJS.N),e=$CLJS.$e($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(A):C.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Rh.v(e,$CLJS.Zk,u,$CLJS.gE(EW,0)),u);if($CLJS.E.g(x,1))return $CLJS.Rh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var A=t,C=$CLJS.p.h(x);return c.g?c.g(A,C):c.call(null,A,C)}()}}function k(m){var t=l,u=t.g;null==IW&&(IW=$CLJS.$e(0));var v=$CLJS.Ih.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Rh.g(IW,EW))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.JW=function(a){return $CLJS.Bd(a)&&1<$CLJS.D(a)&&$CLJS.z(a)instanceof $CLJS.M&&$CLJS.zd($CLJS.jd(a))&&$CLJS.Kd($CLJS.jd(a),$CLJS.NE)};$CLJS.KW=function(a){return $CLJS.JW(a)?$CLJS.FE.h($CLJS.UE(a)):null};$CLJS.LW=function(a,b){return $CLJS.VE($CLJS.JW(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,2,[$CLJS.NE,$CLJS.p.h($CLJS.XE()),$CLJS.Ki,$CLJS.aF(a)],null),a],null),$CLJS.R,$CLJS.H([$CLJS.FE,b]))};
MW=function(a,b,c){var d=$CLJS.Kd(a,b)?$CLJS.Zk.j(a,b,function(e){return $CLJS.Bd(e)&&$CLJS.E.g($CLJS.z(e),$CLJS.Os)?$CLJS.Gf($CLJS.ef(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Kd(a,b)?$CLJS.LV(d,$CLJS.He([b,c])):d};Tka=function(a){var b=$CLJS.km(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rP,$CLJS.UQ],null));b=NW.h?NW.h(b):NW.call(null,b);return $CLJS.BW($CLJS.R.l(MW($CLJS.Nk.l(a,$CLJS.rP,$CLJS.H([$CLJS.UQ])),$CLJS.cS,$CLJS.FL),$CLJS.Jj,$CLJS.xL,$CLJS.H([$CLJS.CL,b])))};
Uka=function(a){return $CLJS.Yk.g(Tka,a)};Wka=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Zk.j($CLJS.Sl(a)?new $CLJS.h(null,1,[$CLJS.OW,a],null):a,$CLJS.OW,function(b){return $CLJS.Yk.g(function(c){return $CLJS.R.j($CLJS.RA(c,$CLJS.rB),$CLJS.Jj,$CLJS.ZK)},b)}),$CLJS.Jj,Vka):null};
$CLJS.PW=function(a){if($CLJS.E.g($CLJS.Jj.h(a),$CLJS.tW))return a;var b=$CLJS.rj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.tW,$CLJS.CL,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Jj,$CLJS.nW],null),$CLJS.LV($CLJS.vQ.h(a),new $CLJS.h(null,1,[$CLJS.TF,$CLJS.vQ],null))]))],null)],null),$CLJS.Nk.l(a,$CLJS.rj,$CLJS.H([$CLJS.vQ]))]));case "query":return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.Jj,$CLJS.tW,$CLJS.CL,NW($CLJS.TF.h(a))],null),$CLJS.Nk.l(a,$CLJS.rj,$CLJS.H([$CLJS.TF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.QW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.CL);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.li($CLJS.wE("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[Xka,$CLJS.D(a)],null));return c};
$CLJS.RW=function(a,b){a=$CLJS.PW(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.CL);return $CLJS.J.g($CLJS.Gf(c),$CLJS.QW(a,b))};$CLJS.SW=function(a){return"string"===typeof a&&(a=$CLJS.rh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.FW(a)):null};$CLJS.UW=function(){return $CLJS.Wk.g(TW,Ska($CLJS.H([$CLJS.QV,$CLJS.nB,$CLJS.NV,function(a,b){return TW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};IW=null;
$CLJS.VW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.WW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.XW=new $CLJS.M(null,"join","join",-758861890);$CLJS.YW=new $CLJS.M(null,"stage","stage",1843544772);Xka=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.OW=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.ZW=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.$W=new $CLJS.M(null,"table","table",-564943036);Vka=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.aX=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var NW,TW;
NW=function NW(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.UQ),d=$CLJS.J.g(a,$CLJS.dP);c=$CLJS.n(c)?NW.h?NW.h(c):NW.call(null,c):$CLJS.Ff;d=Wka(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.iM(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.zV],null),d):c;d=$CLJS.n($CLJS.vQ.h(a))?$CLJS.nW:$CLJS.gW;a=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.Jj,d],null),$CLJS.Nk.l(a,$CLJS.UQ,$CLJS.H([$CLJS.dP]))]));a=$CLJS.y($CLJS.QN.h(a))?$CLJS.Zk.j(a,$CLJS.QN,Uka):a;a=MW(a,$CLJS.aG,$CLJS.uW);return $CLJS.ee.g(c,a)};
$CLJS.bX=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.PW(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.CL);d=$CLJS.QW(c,d);e=$CLJS.Ve.N($CLJS.Zk,$CLJS.Gf(k),d,e,f);return $CLJS.R.j(c,$CLJS.CL,e)}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
TW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.zW.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.cX=RegExp(" id$","i");