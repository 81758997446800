var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var j2,Vna,p2,q2,s2,Wna,Xna,Yna,Zna,$na,boa,coa,doa,aoa,v2,w2;j2=function(a,b){var c=$CLJS.Jd,d=i2;for(a=$CLJS.y(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.z(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};$CLJS.k2=function(a,b,c){return null==c||$CLJS.Kd(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.l2=function(a,b){return $CLJS.hg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.NE,$CLJS.p.h($CLJS.XE())],null)],null),$CLJS.lf.h($CLJS.DW),b)};
$CLJS.m2=function(a,b){a=$CLJS.QW(a,b);return 0<a?a-1:null};$CLJS.n2=function(a,b){if($CLJS.y(b)){if($CLJS.E.g($CLJS.D(b),1))return $CLJS.z(b);a=[" ",(0,$CLJS.na)(a)," "].join("");return $CLJS.E.g($CLJS.D(b),2)?[$CLJS.p.h($CLJS.z(b)),a,$CLJS.p.h($CLJS.jd(b))].join(""):[$CLJS.St(", ",$CLJS.Pt(b)),",",a,$CLJS.p.h($CLJS.kd(b))].join("")}return null};
$CLJS.o2=function(a,b,c,d){a=$CLJS.PW(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.RW(a,b);e=!($CLJS.y($CLJS.lG.h(e))||$CLJS.y($CLJS.kG.h(e)));c=$CLJS.bX.l(a,b,$CLJS.Zk,$CLJS.H([c,function(f){return $CLJS.ee.g($CLJS.Gf(f),$CLJS.DW.h(d))}]));return e?$CLJS.Zk.N((0,$CLJS.bX)(c,b,function(f){return $CLJS.fX($CLJS.Nk.l(f,$CLJS.aS,$CLJS.H([$CLJS.fF])),$CLJS.QN,function(k){return $CLJS.Yk.g(function(l){return $CLJS.Nk.g(l,$CLJS.fF)},k)})}),$CLJS.CL,$CLJS.Wk.g(function(f){return $CLJS.hg.g($CLJS.Ff,f)},$CLJS.cl),0,
$CLJS.QW(a,b)+1):c};Vna=function(a,b){b=$CLJS.h0(a,b);return $CLJS.n(b)?$CLJS.V1(a,b):null};p2=function(a){return $CLJS.hg.j($CLJS.eh,$CLJS.Xk.h(function(b){return $CLJS.pe(b)&&$CLJS.Tk.g(b,$CLJS.Jj)}),$CLJS.$g(a))};q2=function(a){return $CLJS.hg.j($CLJS.N,$CLJS.mm(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.lG.h(a))};
$CLJS.r2=function(a,b,c){var d=$CLJS.nl.l;b=$CLJS.Va($CLJS.m2(a,b))?function(){var f=$CLJS.$V.h($CLJS.z($CLJS.CL.h(a)));return $CLJS.n(f)?(f=Vna(a,f),$CLJS.n(f)?$CLJS.JH(function(k){return $CLJS.E.g($CLJS.dj.h(k),c)},f):null):null}():null;try{var e=$CLJS.H1(a,c)}catch(f){e=null}return d.call($CLJS.nl,$CLJS.H([b,e]))};
s2=function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null),d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.GP);d=$CLJS.J.g(d,$CLJS.RN);$CLJS.I(a,2,null);a=$CLJS.n(d)?$CLJS.E.g(d,$CLJS.mL.h(b)):d;return $CLJS.n(a)?a:$CLJS.E.g($CLJS.PA($CLJS.j_,$CLJS.h_)(b),c)};
Wna=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null),d=$CLJS.Ie($CLJS.of(function(e){var f=$CLJS.E.g($CLJS.WZ.h(e),c);return f?s2(a,e):f},b));return $CLJS.n(d)?d:$CLJS.of(function(e){var f=$CLJS.E.g($CLJS.T.h(e),c);return f?s2(a,e):f},b)};
Xna=function(a,b,c){$CLJS.I(a,0,null);var d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.Ie($CLJS.of(function(k){var l=$CLJS.E.g($CLJS.dj.h(k),e);if(l){if(l=$CLJS.Va($CLJS.GP.h(d))){l=$CLJS.aL.h(k);var m=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.ZZ,null,$CLJS.d_,null],null),null);l=m.h?m.h(l):m.call(null,l)}return $CLJS.n(l)?l:s2(a,k)}return l},b));if($CLJS.n(f))return f;b=$CLJS.n(c)?$CLJS.Ie($CLJS.of(function(k){return $CLJS.E.g($CLJS.dj.h(k),e)},b)):null;return $CLJS.n(b)?b:$CLJS.Ff};
Yna=function(a){var b=t2.g($CLJS.aL.h(a),$CLJS.QZ);return $CLJS.n(b)?b:$CLJS.FE.h(a)};Zna=function(a,b){b=$CLJS.Ie($CLJS.Xk.g($CLJS.mL,b));if($CLJS.n(b))if($CLJS.Va($CLJS.B(b)))a=$CLJS.z(b);else{if($CLJS.n(t2.g($CLJS.z(a),$CLJS.wG))){var c=$CLJS.Ie($CLJS.Xk.g(Yna,b));c=$CLJS.n(c)?$CLJS.B(c)?null:$CLJS.z(c):null}else c=null;if($CLJS.n(c))a=c;else throw $CLJS.li("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.W,a,$CLJS.OW,b],null));}else a=null;return a};
$na=function(a,b){b=$CLJS.Ie($CLJS.Xk.g(function(c){var d=$CLJS.PA($CLJS.j_,$CLJS.h_)(c);$CLJS.n(d)?(c=$CLJS.aL.h(c),d=new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.ZZ,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Va(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.z(b):Zna(a,b):null};
boa=function(a,b){var c=$CLJS.UE(a);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.GP);if($CLJS.n(d)){b=$CLJS.Ie($CLJS.of(function(e){return $CLJS.E.g($CLJS.PA($CLJS.j_,$CLJS.h_)(e),d)},b));if($CLJS.n(b)){if($CLJS.Va($CLJS.B(b)))return $CLJS.z(b);throw $CLJS.li("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.W,a,aoa,b],null));}return null}return $na(a,b)};coa=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
doa=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.u2=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.eoa=new $CLJS.M(null,"matching-refs","matching-refs",324693611);aoa=new $CLJS.M(null,"matches","matches",635497998);v2=new $CLJS.M(null,"left","left",-399115937);w2=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var t2=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.sC(f);k=$CLJS.sC(k);return $CLJS.Tk.g(f,k)?w2:f},e,a,b,c,d)}();t2.m(null,w2,function(){return!1});
t2.m(null,$CLJS.CB,function(a,b){var c=p2(a),d=p2(b);return $CLJS.E.g(c,d)&&$CLJS.Te(function(e){return t2.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});t2.m(null,$CLJS.AB,function(a,b){var c=$CLJS.E.g($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.y(a),$CLJS.z(c),$CLJS.B(c),c=$CLJS.y(b),$CLJS.z(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.y(c);b=$CLJS.z(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.y(a);d=$CLJS.z(a);a=$CLJS.B(a);c=t2.g(c,d);if($CLJS.n(c)){if(c=$CLJS.ud(b))return c;c=b}else return c}else return c});var i2=null;
t2.m(null,$CLJS.gW,function(a,b){var c=i2;i2=new $CLJS.h(null,2,[v2,q2(a),$CLJS.Kx,q2(b)],null);try{var d=$CLJS.ei(t2,$CLJS.CB);return d.g?d.g(a,b):d.call(null,a,b)}finally{i2=c}});
t2.m(null,$CLJS.lG,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.H([$CLJS.D(b)])))?(c=$CLJS.E.g(c,f))?(d=t2.g(d,k),$CLJS.n(d)?$CLJS.n(i2)?$CLJS.E.g(j2(new $CLJS.P(null,2,5,$CLJS.Q,[v2,e],null),doa),j2(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kx,l],null),coa)):$CLJS.E.g(e,l):d):c:a});
t2.m(null,$CLJS.gi,function(a,b){if($CLJS.zd(a)){var c=$CLJS.ei(t2,$CLJS.CB);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.xd(a)?(c=$CLJS.ei(t2,$CLJS.AB),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.E.g(a,b)});
$CLJS.x2=function(){function a(f,k,l,m,t){var u=$CLJS.JW(l)?l:$CLJS.CW(l);$CLJS.I(u,0,null);$CLJS.I(u,1,null);var v=$CLJS.I(u,2,null),x=e.j?e.j(u,m,t):e.call(null,u,m,t);return $CLJS.n(x)?x:$CLJS.n($CLJS.n(f)?"number"===typeof v:f)&&(m=$CLJS.Ie($CLJS.Xk.g($CLJS.dj,m)),$CLJS.n(m)&&(k=$CLJS.JW(l)?$CLJS.r2(f,k,v):l,$CLJS.n(k)))?(f=$CLJS.R.j,l=$CLJS.WZ.h(k),k=$CLJS.n(l)?l:$CLJS.T.h(k),u=$CLJS.WE(f.call($CLJS.R,u,2,k),$CLJS.Ye($CLJS.nl,new $CLJS.h(null,1,[$CLJS.GB,$CLJS.Fj],null))),e.j?e.j(u,m,t):e.call(null,
u,m,t)):null}function b(f,k,l,m){return e.N?e.N(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=$CLJS.I(f,0,null);$CLJS.I(f,1,null);var t=$CLJS.I(f,2,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.u2);switch(m instanceof $CLJS.M?m.T:null){case "aggregation":return $CLJS.JH(function(u){return $CLJS.E.g($CLJS.aL.h(u),$CLJS.vL)&&$CLJS.E.g($CLJS.o1.h(u),t)},k);case "expression":case "field":switch(k="string"===typeof t?Wna(f,k):Xna(f,k,l),$CLJS.D(k)){case 0:return null;case 1:return $CLJS.z(k);
default:return boa(f,k)}default:throw $CLJS.li("Unknown type of ref",new $CLJS.h(null,1,[$CLJS.W,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.N=a;return e}();
$CLJS.foa=function(){function a(d,e,f,k){return $CLJS.x2.v(d,e,$CLJS.GZ.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();