var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Tz=function(){},Uz=function(a){return $CLJS.J.g($CLJS.Iy,a).value},uda=function(a){var b=Uz(a);return $CLJS.n(function(){var c=Uz($CLJS.Zy);return $CLJS.Qk.g?$CLJS.Qk.g(c,b):$CLJS.Qk.call(null,c,b)}())?$CLJS.Ez:$CLJS.n(function(){var c=Uz($CLJS.Yy);return $CLJS.Qk.g?$CLJS.Qk.g(c,b):$CLJS.Qk.call(null,c,b)}())?$CLJS.Oz:$CLJS.n(function(){var c=Uz($CLJS.Wy);return $CLJS.Qk.g?$CLJS.Qk.g(c,b):$CLJS.Qk.call(null,c,b)}())?$CLJS.Hz:$CLJS.n(function(){var c=Uz($CLJS.Qy);return $CLJS.Qk.g?
$CLJS.Qk.g(c,b):$CLJS.Qk.call(null,c,b)}())?$CLJS.Nz:$CLJS.n(function(){var c=Uz($CLJS.Xy);return $CLJS.Qk.g?$CLJS.Qk.g(c,b):$CLJS.Qk.call(null,c,b)}())?$CLJS.Pz:$CLJS.n(function(){var c=Uz($CLJS.Ty);return $CLJS.Qk.g?$CLJS.Qk.g(c,b):$CLJS.Qk.call(null,c,b)}())?$CLJS.Kz:$CLJS.n(function(){var c=Uz($CLJS.Ny);return $CLJS.Qk.g?$CLJS.Qk.g(c,b):$CLJS.Qk.call(null,c,b)}())?$CLJS.Lz:$CLJS.Mz},Vz=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},Wz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ee.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Sz,c))].join(""),$CLJS.H(["color:black"]))],null)},Xz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ee.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Sz,$CLJS.Qz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.Sz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},vda=function(a){function b(c,d){return d>=c}a=Uz(a);if(b(Uz($CLJS.Zy),a))return"error";if(b(Uz($CLJS.Yy),a))return"warn";if(b(Uz($CLJS.Wy),a))return"info";b(Uz($CLJS.Qy),a);return"log"},Yz=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.Oy),e=$CLJS.J.g(c,$CLJS.Zt);b=$CLJS.J.g(c,$CLJS.$y);e=vda(e);e=$CLJS.Da(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Ve.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Zz=function Zz(a,b){for(;;){if($CLJS.E.g($CLJS.Dz,b))return Wz(a,", ",$CLJS.Mz);if($CLJS.E.g($CLJS.Fz,b))return Vz(a);if(b instanceof $CLJS.M)return Wz(a,b,$CLJS.Hz);if(b instanceof $CLJS.r)return Wz(a,b,$CLJS.Nz);if("string"===typeof b)return Wz(a,$CLJS.Ph.l($CLJS.H([b])),$CLJS.Gz);if($CLJS.Cf(b)){var d=Vz(function(){var f=a,k=$CLJS.Fb(b);return Zz.g?Zz.g(f,k):Zz.call(null,f,k)}()),e=$CLJS.Gb(b);
return Zz.g?Zz.g(d,e):Zz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Kg)return d=a,d=Wz(d,"{",$CLJS.Rz),d=$CLJS.db(Zz,d,$CLJS.jf($CLJS.Dz,b)),Wz(d,"}",$CLJS.Rz);if($CLJS.zd(b))return d=a,d=Wz(d,["#",$CLJS.p.h(function(){var f=$CLJS.$a(b),k=f.name;return $CLJS.ud(k)?$CLJS.Ph.l($CLJS.H([f])):k}())," "].join(""),$CLJS.Iz),d=Wz(d,"{",$CLJS.Rz),d=$CLJS.db(Zz,d,$CLJS.jf($CLJS.Dz,b)),Wz(d,"}",$CLJS.Rz);if($CLJS.wd(b))return d=a,d=Wz(d,"#{",$CLJS.Rz),d=$CLJS.db(Zz,d,$CLJS.jf($CLJS.Fz,b)),Wz(d,
"}",$CLJS.Rz);if($CLJS.Bd(b))return d=a,d=Wz(d,"[",$CLJS.Rz),d=$CLJS.db(Zz,d,$CLJS.jf($CLJS.Fz,b)),Wz(d,"]",$CLJS.Rz);if(b instanceof $CLJS.Uf)d=Wz(a,"#queue ",$CLJS.Iz),e=$CLJS.hg.g($CLJS.Ff,b),a=d,b=e;else{if($CLJS.Fd(b))return d=a,d=Wz(d,"(",$CLJS.Iz),d=$CLJS.db(Zz,d,$CLJS.jf($CLJS.Fz,b)),Wz(d,")",$CLJS.Iz);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.Za(Tz,b)):$CLJS.Za(Tz,b))d=Wz(a,"#atom ",$CLJS.Iz),e=$CLJS.q(b),a=d,b=e;else if($CLJS.ii(b))d=Wz(a,"#uuid ",$CLJS.Iz),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Xa(b))d=Wz(a,"#js ",$CLJS.Iz),e=$CLJS.db(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.Ch.h(m),$CLJS.Da(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ua(b))d=Wz(a,"#js ",$CLJS.Iz),e=$CLJS.hg.g($CLJS.Ff,b),a=d,b=e;else return Wz(a,$CLJS.Ph.l($CLJS.H([b])),$CLJS.Jz)}}};$CLJS.wda=Yz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Zt);var b=$CLJS.J.g(a,$CLJS.Oy),c=$CLJS.J.g(a,$CLJS.Vi);$CLJS.J.g(a,$CLJS.$y);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.xda=Yz(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Zt);a=$CLJS.J.g(b,$CLJS.Oy);var d=$CLJS.J.g(b,$CLJS.Vi);$CLJS.J.g(b,$CLJS.$y);b=uda(c);d=Zz(Vz(Xz(Xz(Xz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Ff],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.he(a,d)});
$CLJS.yda=Yz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Zt);var b=$CLJS.J.g(a,$CLJS.Oy),c=$CLJS.J.g(a,$CLJS.Vi);$CLJS.J.g(a,$CLJS.$y);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Ph.l($CLJS.H([c]))],null)});