var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.LG=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.dK=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.eK=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var fK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Hs],null)),gK=null,hK=0,iK=0;;)if(iK<hK){var Uga=gK.X(null,iK);$CLJS.NF(Uga,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.fo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)],null)],null)]));iK+=1}else{var jK=$CLJS.y(fK);if(jK){var kK=jK;if($CLJS.Cd(kK)){var lK=$CLJS.lc(kK),Vga=$CLJS.mc(kK),
Wga=lK,Xga=$CLJS.D(lK);fK=Vga;gK=Wga;hK=Xga}else{var Yga=$CLJS.z(kK);$CLJS.NF(Yga,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.fo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)],null)],null)]));fK=$CLJS.B(kK);gK=null;hK=0}iK=0}else break}$CLJS.LF($CLJS.Js,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)]));
for(var mK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.OG],null)),nK=null,oK=0,pK=0;;)if(pK<oK){var Zga=nK.X(null,pK);$CLJS.NF(Zga,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.fo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null)],null)]));pK+=1}else{var qK=$CLJS.y(mK);if(qK){var rK=qK;if($CLJS.Cd(rK)){var sK=$CLJS.lc(rK),$ga=$CLJS.mc(rK),
aha=sK,bha=$CLJS.D(sK);mK=$ga;nK=aha;oK=bha}else{var cha=$CLJS.z(rK);$CLJS.NF(cha,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.fo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null)],null)]));mK=$CLJS.B(rK);nK=null;oK=0}pK=0}else break}
for(var tK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hs,$CLJS.js,$CLJS.ds,$CLJS.fs],null)),uK=null,vK=0,wK=0;;)if(wK<vK){var dha=uK.X(null,wK);$CLJS.LF(dha,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)]));wK+=1}else{var xK=$CLJS.y(tK);if(xK){var yK=xK;if($CLJS.Cd(yK)){var zK=$CLJS.lc(yK),eha=$CLJS.mc(yK),fha=zK,gha=$CLJS.D(zK);tK=eha;uK=fha;vK=gha}else{var hha=$CLJS.z(yK);$CLJS.LF(hha,$CLJS.H([$CLJS.At,$CLJS.Qj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)]));tK=$CLJS.B(yK);uK=null;vK=0}wK=0}else break}$CLJS.LF($CLJS.ZF,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)]));
$CLJS.LF($CLJS.eG,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)]));
for(var AK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,$CLJS.iG],null)),BK=null,CK=0,DK=0;;)if(DK<CK){var iha=BK.X(null,DK);$CLJS.LF(iha,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)]));DK+=1}else{var EK=$CLJS.y(AK);if(EK){var FK=EK;if($CLJS.Cd(FK)){var GK=$CLJS.lc(FK),jha=$CLJS.mc(FK),kha=GK,lha=$CLJS.D(GK);AK=jha;BK=kha;CK=lha}else{var mha=$CLJS.z(FK);$CLJS.LF(mha,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)]));AK=$CLJS.B(FK);
BK=null;CK=0}DK=0}else break}
for(var HK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,$CLJS.gG],null)),IK=null,JK=0,KK=0;;)if(KK<JK){var nha=IK.X(null,KK);$CLJS.LF(nha,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)]));KK+=1}else{var LK=$CLJS.y(HK);if(LK){var MK=LK;if($CLJS.Cd(MK)){var NK=$CLJS.lc(MK),oha=$CLJS.mc(MK),pha=NK,qha=$CLJS.D(NK);HK=oha;IK=pha;JK=qha}else{var rha=$CLJS.z(MK);$CLJS.LF(rha,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)]));HK=$CLJS.B(MK);
IK=null;JK=0}KK=0}else break}
for(var OK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EG,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null)],null),PK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nG,$CLJS.HG,$CLJS.vG,$CLJS.oG],null)),QK=null,RK=0,SK=0;;)if(SK<RK){var TK=QK.X(null,SK);$CLJS.XG.v(TK,$CLJS.At,$CLJS.Qj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,TK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.LE,OK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.uF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null));SK+=1}else{var UK=$CLJS.y(PK);if(UK){var VK=UK;if($CLJS.Cd(VK)){var WK=$CLJS.lc(VK),sha=$CLJS.mc(VK),tha=WK,uha=$CLJS.D(WK);PK=sha;QK=tha;RK=uha}else{var XK=$CLJS.z(VK);$CLJS.XG.v(XK,$CLJS.At,$CLJS.Qj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,XK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.LE,OK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.uF],null)],null));PK=$CLJS.B(VK);QK=null;RK=0}SK=0}else break}
$CLJS.XG.v($CLJS.IG,$CLJS.At,$CLJS.Qj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.IG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.LE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mk,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,$CLJS.iw,$CLJS.ky,$CLJS.dG],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.xF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rH],null)],null));$CLJS.XG.v($CLJS.VF,$CLJS.At,$CLJS.Qj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.VF],null),$CLJS.LE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.yE,$CLJS.GE],null)],null));
$CLJS.X($CLJS.LG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.dK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.Es,$CLJS.yk,$CLJS.OG,$CLJS.eG,$CLJS.ZF,$CLJS.hs,$CLJS.ds,$CLJS.js,$CLJS.fs,$CLJS.YF,$CLJS.iG,$CLJS.$F,$CLJS.gG,$CLJS.vG,$CLJS.oG,$CLJS.nG,$CLJS.HG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eK,$CLJS.bj],null)],null));