var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var E5,Aqa,Bqa,Cqa,G5,I5,J5,Dqa,Eqa,M5,K5,L5,N5,O5,Fqa,Gqa,Hqa,P5,Iqa,Jqa,Q5,Kqa,Lqa;E5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=E5($CLJS.J.g(a,b),c),$CLJS.ud(c)?$CLJS.Nk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Nk.g(a,b)}return a};
Aqa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.z(u);u=$CLJS.B(u);var v=$CLJS.lm(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Gb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Ve.j(l,$CLJS.Gb(v),m)):f}(a,b,c,d)};Bqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Aqa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.F5=function(a){return $CLJS.NE.h($CLJS.UE(a))};
Cqa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.lQ,$CLJS.z(b))?$CLJS.LW(d,$CLJS.KW(c)):d;return Bqa(a,b,function(f){return $CLJS.Gf(function(){return function m(l){return new $CLJS.qe(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Cd(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.te(v);a:for(var A=0;;)if(A<v){var C=$CLJS.md(u,A);C=$CLJS.E.g($CLJS.F5(C),$CLJS.F5(c))?e:C;x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.we($CLJS.ye(x),m($CLJS.mc(t))):$CLJS.we($CLJS.ye(x),null)}x=$CLJS.z(t);return $CLJS.he($CLJS.E.g($CLJS.F5(x),
$CLJS.F5(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
G5=function(a,b,c,d){var e=$CLJS.QA(a,b);if($CLJS.n(e)){var f=$CLJS.F5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z(b),$CLJS.kd(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.hg.j($CLJS.Ff,$CLJS.Xk.h($CLJS.Wk.g($CLJS.fh([f]),$CLJS.F5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fF],null))&&$CLJS.Te(function(l){return $CLJS.TY(l,$CLJS.DB)},e)?null:e;if($CLJS.y(e))return $CLJS.iM(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QN,$CLJS.FL],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.li($CLJS.YE("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Sy,$CLJS.WW,$CLJS.FL,$CLJS.QA(a,b),$CLJS.XW,$CLJS.QA(a,$CLJS.td(b)),$CLJS.QL,d,$CLJS.YW,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QN,$CLJS.fF],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.IX.v(a,$CLJS.td(b),$CLJS.Nk,k):E5(a,b)}return a};$CLJS.H5=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.CL);b=0>b?$CLJS.D(a)+b:b;return b+1<$CLJS.D(a)?b+1:null};
I5=function(a,b){var c=$CLJS.e4.g(a,b),d=$CLJS.oh(0,$CLJS.D(c));return $CLJS.kf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aS],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uW],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.fF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.lQ],null)],null),function(){return function k(f){return new $CLJS.qe(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.Cd(l)){var m=
$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v),A=$CLJS.F(c,x);$CLJS.fF.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,x,$CLJS.fF],null));v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}u=$CLJS.z(l);m=$CLJS.F(c,u);if($CLJS.fF.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.he(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,u,$CLJS.fF],null),k($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.qe(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,x,$CLJS.FL],null));v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}u=$CLJS.z(l);return $CLJS.he(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,u,$CLJS.FL],null),k($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
J5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.CG),k=$CLJS.J.g(e,$CLJS.GR),l=$CLJS.I(c,2,null);return $CLJS.z($CLJS.JH(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.z(m),d)&&$CLJS.E.g($CLJS.CG.h($CLJS.jd(m)),f)&&$CLJS.E.g($CLJS.GR.h($CLJS.jd(m)),k)&&$CLJS.E.g($CLJS.kd(m),l)},$CLJS.jm($CLJS.at,$CLJS.aS.h($CLJS.RW(a,b)))))};
Dqa=function(a,b,c,d){c=J5(a,b,c);return $CLJS.n(c)?$CLJS.bX.l(a,b,$CLJS.IX,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.aS,c,2,1],null),$CLJS.Wk.g(function(e){return $CLJS.D1($CLJS.Gl,e)},$CLJS.nl),d])):a};Eqa=function(a,b,c){c=J5(a,b,c);return $CLJS.n(c)?$CLJS.bX.l(a,b,G5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.aS],null),$CLJS.QA($CLJS.RW(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aS,c],null)),b])):a};
M5=function(a,b,c,d,e,f){var k=$CLJS.bX.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.F5(e);return $CLJS.Tk.g(a,k)?$CLJS.z($CLJS.y($CLJS.of($CLJS.Wa,function(m,t){try{if($CLJS.Bd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.ke(u,$CLJS.lQ))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.KW(e);K=K5.W?K5.W(k,b,c,$CLJS.DB,$CLJS.N,K):K5.call(null,k,b,c,$CLJS.DB,$CLJS.N,K);return L5.v?L5.v(K,b,c,l):L5.call(null,K,b,c,l)}()],null);throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{u=
$CLJS.F(t,0);if($CLJS.ke(u,$CLJS.lG))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=K5.W?K5.W(k,b,c,$CLJS.lG,$CLJS.N,l):K5.call(null,k,b,c,$CLJS.lG,$CLJS.N,l);return L5.v?L5.v(S,b,c,l):L5.call(null,S,b,c,l)}()],null);throw $CLJS.Z;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Z)try{if($CLJS.Bd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.ke(x,$CLJS.kG))return new $CLJS.P(null,
1,5,$CLJS.Q,[L5.v?L5.v(k,b,c,l):L5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{x=$CLJS.F(t,0);if($CLJS.ke(x,$CLJS.fF))return new $CLJS.P(null,1,5,$CLJS.Q,[L5.v?L5.v(k,b,c,l):L5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){var A=Y;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw Y;}else throw v;else throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Z)try{if($CLJS.Bd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.ke(C,$CLJS.QN))try{var G=$CLJS.F(t,2);if($CLJS.ke(G,$CLJS.fF))return new $CLJS.P(null,1,5,$CLJS.Q,[L5.v?L5.v(k,b,c,l):L5.call(null,k,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){A=Y;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){A=Y;if(A===$CLJS.Z)throw $CLJS.Z;throw A;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){A=Y;if(A===$CLJS.Z)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw A;}throw Y;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.Ff,d)))):k};
K5=function(a,b,c,d,e,f){var k=$CLJS.RW(a,b),l=$CLJS.mf(function(m){var t=$CLJS.QA(k,m);return $CLJS.n(t)?$CLJS.im(function(u){return $CLJS.z($CLJS.y($CLJS.of($CLJS.Wa,function C(x,A){try{if($CLJS.Bd(A)&&3===$CLJS.D(A))try{var G=$CLJS.F(A,0);if($CLJS.E.g(G,d))try{var K=$CLJS.F(A,1);if($CLJS.ud(e)||$CLJS.C1($CLJS.ih(e),$CLJS.ih(K)))try{var S=$CLJS.F(A,2);if($CLJS.E.g(S,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Z;}catch(ca){if(ca instanceof Error){var Y=
ca;if(Y===$CLJS.Z)throw $CLJS.Z;throw Y;}throw ca;}else throw $CLJS.Z;}catch(ca){if(ca instanceof Error){Y=ca;if(Y===$CLJS.Z)throw $CLJS.Z;throw Y;}throw ca;}else throw $CLJS.Z;}catch(ca){if(ca instanceof Error){Y=ca;if(Y===$CLJS.Z)throw $CLJS.Z;throw Y;}throw ca;}else throw $CLJS.Z;}catch(ca){if(ca instanceof Error){Y=ca;if(Y===$CLJS.Z)return $CLJS.MV(C,x,A);throw Y;}throw ca;}}($CLJS.Ff,u))))},t):null},$CLJS.H([I5(a,b)]));return $CLJS.db(function(m,t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);
return M5(m,b,c,u,t,function(v,x,A){return G5(v,x,A,b)})},a,l)};L5=function(a,b,c,d){b=$CLJS.H5(c,b);if($CLJS.n(b)){var e=$CLJS.RW(c,b);c=$CLJS.Ue(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.aL),l=$CLJS.J.g(f,$CLJS.o1);return $CLJS.E.g($CLJS.KZ,k)&&$CLJS.E.g(d,l)?$CLJS.WZ.h(f):null},$CLJS.W0.j(c,b,e));return $CLJS.n(c)?K5(a,b,a,$CLJS.wG,$CLJS.N,c):a}return a};
N5=function(a,b,c,d,e){var f=$CLJS.DW.h(c),k=$CLJS.RW(a,b);c=$CLJS.JH(function(t){var u=$CLJS.QA(k,t);if($CLJS.n(u)){var v=$CLJS.F5(f);return $CLJS.n($CLJS.Ue($CLJS.Wk.j($CLJS.fh([v]),$CLJS.NE,$CLJS.jd),u))?t:null}return null},I5(a,b));var l=(d=$CLJS.E.g($CLJS.VG,d))?$CLJS.DW.h(e):null;e=d?function(t,u,v){return Cqa(t,u,v,l)}:function(t,u,v){return G5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kG],null),c);a=d&&m&&$CLJS.E.g($CLJS.z(f),$CLJS.z(l))&&$CLJS.E.g($CLJS.kd(f),$CLJS.kd(l))?
Dqa(a,b,f,$CLJS.km($CLJS.jd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GR,$CLJS.CG],null))):m?Eqa(a,b,f):a;return $CLJS.n(c)?M5(a,b,a,c,f,e):a};O5=function(a,b){return $CLJS.TY(a,$CLJS.wG)&&$CLJS.E.g($CLJS.P1(a),b)};Fqa=function(a,b,c){return function k(e,f){try{if(O5(f,b))return $CLJS.K3(f,c);throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Z)return $CLJS.kX(k,e,f);throw l;}throw m;}}($CLJS.Ff,a)};
Gqa=function(a,b,c){var d=$CLJS.QN.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.QA(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.jF],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Tk.g(e,c):b)){var f=$CLJS.UW();$CLJS.G3(f,$CLJS.lf.g($CLJS.jF,d));c=f(c);return Fqa($CLJS.iM(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,b,$CLJS.jF],null),c),e,c)}return a};
Hqa=function(a,b,c){if($CLJS.Id(c))return c;var d=function(){var e=$CLJS.fh([c]);return"string"===typeof c?$CLJS.Wk.g(e,$CLJS.jF):e}();return $CLJS.Ue(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.jm($CLJS.at,$CLJS.QN.h($CLJS.RW(a,b))))};
P5=function(a,b,c,d){b=$CLJS.vu.g($CLJS.ih($CLJS.W0.j(b,c,$CLJS.RW(b,c))),$CLJS.ih($CLJS.W0.j(a,c,$CLJS.RW(a,c))));return $CLJS.db(function(e,f){return $CLJS.Ve.N(K5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Iqa=function(a,b,c){a=P5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,new $CLJS.h(null,1,[$CLJS.GP,$CLJS.j_.h(d)],null),$CLJS.dj.h(d)],null)});c=$CLJS.H5(a,c);return $CLJS.n(c)?P5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,$CLJS.N,$CLJS.WZ.h(d)],null)}):a};
Jqa=function(a,b,c){return $CLJS.Id(c)?$CLJS.QA($CLJS.RW(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,c,$CLJS.jF],null)):$CLJS.zd(c)?$CLJS.jF.h(c):c};Q5=function(a,b,c,d){var e=Jqa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.bX)(a,b,function(k){var l=$CLJS.QN.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.TE(k,$CLJS.QN,l)});return(0,$CLJS.bX)(f,b,function(k){return $CLJS.fX(k,$CLJS.QN,function(l){return $CLJS.Yk.g(function(m){return $CLJS.S3(f,b,m)},l)})})}(),Iqa(c,a,b)):a};
Kqa=function(a,b){return null!=$CLJS.z($CLJS.y($CLJS.of($CLJS.Wa,function f(d,e){try{if(O5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Z)return $CLJS.MV(f,d,e);throw k;}throw l;}}($CLJS.Ff,a))))};Lqa=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.S5=function(){function a(d,e,f){return $CLJS.zd(f)&&$CLJS.E.g($CLJS.Jj.h(f),$CLJS.xL)?$CLJS.R5.j?$CLJS.R5.j(d,e,f):$CLJS.R5.call(null,d,e,f):N5(d,e,f,Lqa,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.U5=function(){function a(d,e,f,k){return $CLJS.zd(f)&&$CLJS.E.g($CLJS.Jj.h(f),$CLJS.xL)?$CLJS.T5.v?$CLJS.T5.v(d,e,f,k):$CLJS.T5.call(null,d,e,f,k):N5(d,e,f,$CLJS.VG,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Mqa=function(){function a(d,e,f,k){f=Hqa(d,e,f);return $CLJS.n(f)?$CLJS.bX.l(d,e,Gqa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.R5=function(){function a(d,e,f){try{return Q5(d,e,f,function(u,v){return $CLJS.Ie($CLJS.j1(function(x){return!($CLJS.E.g($CLJS.jF.h(x),v)||Kqa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.nm(k)),m=$CLJS.J.g(l,$CLJS.QL),t=$CLJS.J.g(l,$CLJS.Sy);l=$CLJS.J.g(l,$CLJS.XW);if($CLJS.E.g(t,$CLJS.WW))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.T5=function(){function a(d,e,f,k){return null==k?$CLJS.R5.j(d,e,f):Q5(d,e,f,function(l,m){return $CLJS.Yk.g(function(t){return $CLJS.E.g($CLJS.jF.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();