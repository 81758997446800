var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var G1;$CLJS.C1=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Te(function(c){return $CLJS.Kd(b,c)},a)};$CLJS.D1=function(a,b){return $CLJS.WA($CLJS.We(a),b)};$CLJS.E1=function(a){var b;a:for(b=a;;)if($CLJS.zd(b))b=$CLJS.dj.h(b);else{b=$CLJS.Id(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.wE("Not something with an ID: {0}",$CLJS.H([$CLJS.Ph.l($CLJS.H([a]))])));};
$CLJS.F1=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=$CLJS.F1[$CLJS.xa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=$CLJS.F1._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.ab("MetadataProvider.database",a);}return a};G1=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=G1[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=G1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.field",a);}return a};
$CLJS.H1=function(a,b){return G1($CLJS.e0(a),b)};$CLJS.B1.m(null,$CLJS.tW,function(a){return $CLJS.A1(a,new $CLJS.h(null,2,[$CLJS.rj,$CLJS.Ch,$CLJS.CL,$CLJS.Ye($CLJS.Yk,$CLJS.B1)],null))});$CLJS.R0.m(null,$CLJS.tW,function(){throw $CLJS.li("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.T0.m(null,$CLJS.tW,function(a,b,c,d){return $CLJS.U0.v(a,b,$CLJS.RW(c,b),d)});$CLJS.L0.m(null,$CLJS.tW,function(a,b,c,d){return $CLJS.l0.v(a,b,$CLJS.RW(c,b),d)});
$CLJS.I1=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.Jj.h($CLJS.RW(f,0))},e,a,b,c,d)}();$CLJS.I1.m(null,$CLJS.gi,function(){return!0});
$CLJS.J1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.Jj,$CLJS.tW,$CLJS.xE,$CLJS.e0(e),$CLJS.gQ,d,$CLJS.CL,f],null)}function b(d,e){var f=$CLJS.dj.h($CLJS.F1($CLJS.e0(d)));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.K1=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.sC(k)},e,a,b,c,d)}();$CLJS.K1.m(null,$CLJS.CB,function(a,b){b=$CLJS.kZ.h(b);return $CLJS.J1.g(a,$CLJS.CL.h(b))});
$CLJS.K1.m(null,$CLJS.tW,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.gZ);var d=$CLJS.e0(a),e=$CLJS.Nk.g($CLJS.R.j(c,$CLJS.xE,d),$CLJS.gZ);a=$CLJS.CL.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.CL,$CLJS.hg.j($CLJS.Ff,$CLJS.lf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.Bd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.ke(v,$CLJS.wG))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.We($CLJS.PA($CLJS.GB,$CLJS.Ki))(x)))try{var A=$CLJS.F(t,2);if($CLJS.n($CLJS.uE($CLJS.Hl,
$CLJS.$l)(A))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.km($CLJS.H1(d,C),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GB,$CLJS.Ki],null));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,$CLJS.nl.l($CLJS.H([K,G])),C],null)}throw $CLJS.Z;}catch(ja){if(ja instanceof Error){var S=ja;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw ja;}else throw $CLJS.Z;}catch(ja){if(ja instanceof Error){S=ja;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw ja;}else throw $CLJS.Z;}catch(ja){if(ja instanceof Error)if(S=ja,S===$CLJS.Z)try{if(v=
$CLJS.F(t,0),$CLJS.ke(v,$CLJS.DB))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.We($CLJS.PA($CLJS.GB,$CLJS.Ki))(x))){G=$CLJS.F(t,1);var Y=$CLJS.F(t,2);K=function(){try{return $CLJS.D1(function(va){return $CLJS.E.g($CLJS.Fj,va)},$CLJS.km($CLJS.jd($CLJS.Kna.j(e,k,Y)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GB,$CLJS.Ki],null)))}catch(va){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DB,$CLJS.nl.l($CLJS.H([K,G])),Y],null)}throw $CLJS.Z;}catch(va){if(va instanceof Error){var ca=va;if(ca===$CLJS.Z)throw $CLJS.Z;
throw ca;}throw va;}else throw $CLJS.Z;}catch(va){if(va instanceof Error){ca=va;if(ca===$CLJS.Z)throw $CLJS.Z;throw ca;}throw va;}else throw S;else throw ja;}else throw $CLJS.Z;}catch(ja){if(ja instanceof Error){S=ja;if(S===$CLJS.Z)return $CLJS.kX(u,m,t);throw S;}throw ja;}}($CLJS.Ff,f)}),$CLJS.jm($CLJS.at,a))):e});$CLJS.K1.m(null,$CLJS.fL,function(a,b){return $CLJS.J1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.gW,$CLJS.rP,$CLJS.E1(b)],null)],null))});
$CLJS.K1.m(null,$CLJS.uL,function(a,b){return $CLJS.J1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.gW,$CLJS.$V,$CLJS.E1(b)],null)],null))});