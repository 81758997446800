var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var V$=function(a){return new $CLJS.qe(null,function(){return $CLJS.he(a.o?a.o():a.call(null),V$(a))},null,null)},W$=function(a,b){return new $CLJS.qe(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.z(c),e=a.h?a.h(d):a.call(null,d),f=$CLJS.he(d,$CLJS.Ku(function(k){return $CLJS.E.g(e,a.h?a.h(k):a.call(null,k))},$CLJS.B(c)));return $CLJS.he(f,W$(a,new $CLJS.qe(null,function(){return $CLJS.ef($CLJS.D(f),c)},null,null)))}return null},null,null)},Lwa=function(a,b){switch(a){case "minutes":return $CLJS.KV("Minute",
"Minutes",b);case "hours":return $CLJS.KV("Hour","Hours",b);case "days":return $CLJS.KV("Day","Days",b);case "weeks":return $CLJS.KV("Week","Weeks",b);case "months":return $CLJS.KV("Month","Months",b);case "quarters":return $CLJS.KV("Quarter","Quarters",b);case "years":return $CLJS.KV("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},Mwa=function(a,b,c){b=parseInt(b);c=Lwa(c,b);a=new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.E.g(b,1)],null);if($CLJS.E.g(new $CLJS.P(null,
2,5,$CLJS.Q,["past",!0],null),a))return $CLJS.wE("Previous {0}",$CLJS.H([c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["past",!1],null),a))return $CLJS.wE("Previous {0} {1}",$CLJS.H([b,c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!0],null),a))return $CLJS.wE("Next {0}",$CLJS.H([c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!1],null),a))return $CLJS.wE("Next {0} {1}",$CLJS.H([b,c]));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));},Owa=function(a){return $CLJS.TA(a,
Nwa,function(b){return["\\",$CLJS.p.h(b)].join("")})},X$=function(a,b,c,d,e){this.tag=a;this.source=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264},Qwa=function(a){var b=$CLJS.gu(a,Pwa),c=$CLJS.lf.g($CLJS.z,$CLJS.eX(Y$,a)),d=function(){var e=$CLJS.D(b),f=$CLJS.D(c);return e>f?e:f}();a=$CLJS.kf.g(b,$CLJS.df(d-$CLJS.D(b),V$($CLJS.Xe(""))));d=$CLJS.kf.g(c,$CLJS.df(d-$CLJS.D(c),V$($CLJS.Xe(""))));a=$CLJS.hf.g(a,d);return $CLJS.lf.g(function(e){var f=$CLJS.qh(Y$,e);return $CLJS.n(f)?($CLJS.I(f,
0,null),f=$CLJS.I(f,1,null),new X$(f,e,null,null,null)):e},a)},Rwa=function(a){return $CLJS.mf(function(b){return"string"===typeof $CLJS.z(b)?new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.IV(b)],null):b},$CLJS.H([W$(function(b){return"string"===typeof b||$CLJS.Va($CLJS.Ij.h(b))},a)]))},Swa=function(a,b,c,d){return $CLJS.lf.g(function(e){if(e instanceof X$){var f=$CLJS.R.j;var k=$CLJS.Mj.h(e),l=$CLJS.J.g(a,k);k=$CLJS.Ij.h(l);l=$CLJS.rj.h(l);if($CLJS.n(k))try{var m=Z$.j(l,k,b);var t=$CLJS.n(c)?Owa(m):m}catch(u){if(u instanceof
Error)t=Z$.j($CLJS.gi,k,b);else throw u;}else t=null;e=f.call($CLJS.R,e,$CLJS.Ij,t)}return e},d)},Vwa=function(a){a=$CLJS.IV($CLJS.lf.g(function(b){return b instanceof X$?b:$CLJS.TA(b,Twa,"")},a));return $CLJS.TA(a,Uwa,$CLJS.jd)},Wwa=function(a){return $CLJS.RA($CLJS.z($CLJS.PY(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.HN],null),new $CLJS.P(null,1,5,$CLJS.Q,[a],null))),$CLJS.Ch)};var Z$=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.Ch.h(f)},e,a,b,c,d)}();Z$.m(null,$CLJS.jP,function(a,b,c){return(0,$CLJS.ZI)(b).locale(c).format("MMMM D, YYYY")});
Z$.m(null,$CLJS.MN,function(a,b,c){a=(0,$CLJS.ZI)(b,"YYYY-MM").locale(c);return $CLJS.n(a.isValid())?a.format("MMMM, YYYY"):""});Z$.m(null,$CLJS.GO,function(a,b,c){a=(0,$CLJS.ZI)(b,"[Q]Q-YYYY").locale(c);return $CLJS.n(a.isValid())?a.format("[Q]Q, YYYY"):""});Z$.m(null,$CLJS.pN,function(a,b,c){b=$CLJS.gu(b,/~/);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?[$CLJS.p.h(Z$.j($CLJS.jP,a,c))," - ",$CLJS.p.h(Z$.j($CLJS.jP,b,c))].join(""):""});
Z$.m(null,$CLJS.HO,function(a,b){function c(d,e){return $CLJS.B($CLJS.rh(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.YE("Today");if(c(/^thisweek$/,b))return $CLJS.YE("This Week");if(c(/^thismonth$/,b))return $CLJS.YE("This Month");if(c(/^thisquarter$/,b))return $CLJS.YE("This Quarter");if(c(/^thisyear$/,b))return $CLJS.YE("This Year");if(c(/^past1days$/,b))return $CLJS.YE("Yesterday");if(c(/^next1days$/,b))return $CLJS.YE("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.Ve.g(Mwa,
a);throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));});Z$.m(null,$CLJS.nS,function(a,b,c){function d(e,f){return $CLJS.jd($CLJS.rh(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.n(a))return Z$.j($CLJS.HO,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.n(a))return Z$.j($CLJS.jP,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.n(a)?Z$.j($CLJS.pN,a,c):Z$.j($CLJS.HO,b,c)});
Z$.m(null,$CLJS.gi,function(a,b){$CLJS.xd(b)?(a=$CLJS.D(b),b=$CLJS.n($CLJS.E.g?$CLJS.E.g(1,a):$CLJS.E.call(null,1,a))?$CLJS.p.h($CLJS.z(b)):$CLJS.n($CLJS.E.g?$CLJS.E.g(2,a):$CLJS.E.call(null,2,a))?$CLJS.wE("{0} and {1}",$CLJS.H([$CLJS.z(b),$CLJS.jd(b)])):$CLJS.wE("{0}, {1}, and {2}",$CLJS.H([$CLJS.St(", ",$CLJS.Lu(2,b)),$CLJS.F(b,$CLJS.D(b)-2),$CLJS.kd(b)]))):b=$CLJS.p.h(b);return b});var Nwa=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,Y$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,Pwa=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.g=X$.prototype;$CLJS.g.toString=function(){var a=$CLJS.Ij.h(this);return $CLJS.n(a)?a:this.source};$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,this.tag],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pA,this.source],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Qt(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,$CLJS.pA],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-884441273^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.tag,b.tag)&&$CLJS.E.g(this.source,b.source)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Kd(new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.pA,null,$CLJS.Mj,null],null),null),b)?$CLJS.Nk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new X$(this.tag,this.source,this.S,$CLJS.Ie($CLJS.Nk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.Kd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g($CLJS.Mj,b):$CLJS.ke.call(null,$CLJS.Mj,b))?new X$(c,this.source,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g($CLJS.pA,b):$CLJS.ke.call(null,$CLJS.pA,b))?new X$(this.tag,c,this.S,this.G,null):new X$(this.tag,this.source,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.ag($CLJS.Mj,this.tag),new $CLJS.ag($CLJS.pA,this.source)],null),this.G))};
$CLJS.g.P=function(a,b){return new X$(this.tag,this.source,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};var Twa=/\[\[.+\]\]/,Uwa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.g(arguments[0],arguments[1]);case 4:return $$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.Aa("metabase.shared.parameters.parameters.substitute_tags",$$);$$.g=function(a,b){return $$.v(a,b,"en",!0)};$$.v=function(a,b,c,d){return $CLJS.n(a)?(b=$CLJS.cA(b),b=$CLJS.Rt(b,Wwa),Vwa(Rwa(Swa(b,c,d,Qwa(a))))):null};$$.A=4;module.exports={tag_names:function(a){a=$CLJS.ih($CLJS.lf.g($CLJS.jd,$CLJS.eX(Y$,$CLJS.n(a)?a:"")));return $CLJS.ql(a)},substitute_tags:$$};