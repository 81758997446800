var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Nsa,Osa,Qsa,Rsa,Ssa,Tsa;Nsa=function(a,b){return $CLJS.VA(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};Osa=function(a,b){return $CLJS.gc($CLJS.db(function(c,d){return $CLJS.kg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ec($CLJS.N),b))};Qsa=function(a){return $CLJS.hg.j($CLJS.eh,$CLJS.Wk.g($CLJS.vE(function(b){return $CLJS.eX(b,a)}),$CLJS.lf.h($CLJS.jd)),Psa)};
Rsa=function(a){return new $CLJS.h(null,3,[$CLJS.rj,$CLJS.KB,$CLJS.T,a,$CLJS.dj,$CLJS.p.h($CLJS.XE())],null)};
Ssa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.nl.l($CLJS.H([a,function(){var c=$CLJS.qh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.FW(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.rj,$CLJS.IN,$CLJS.oR,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.na)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.rj,$CLJS.TM,$CLJS.FM,c],null):null}(),$CLJS.n($CLJS.BE.h(a))?null:new $CLJS.h(null,
1,[$CLJS.BE,$CLJS.N1.g($CLJS.M1,b)],null)]))};$CLJS.I8=function(a){a=$CLJS.F1($CLJS.e0(a));return $CLJS.n($CLJS.QA(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vA,Tsa],null)))?$CLJS.ee.g($CLJS.eh,$CLJS.yO):$CLJS.eh};$CLJS.J8=function(a,b){var c=$CLJS.I8(a);return(0,$CLJS.bX)(a,0,function(d){var e=$CLJS.vu.g(Usa,c);d=$CLJS.Ve.j($CLJS.Nk,d,e);d=$CLJS.nl.l($CLJS.H([d,$CLJS.km(b,c)]));$CLJS.vu.g(c,$CLJS.ih($CLJS.$g(b)));$CLJS.RW(a,0);return d})};$CLJS.K8=function(a){return $CLJS.Ie($CLJS.km($CLJS.RW(a,0),$CLJS.I8(a)))};
$CLJS.L8=function(a){return $CLJS.vQ.h($CLJS.RW(a,0))};Tsa=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.Vsa=new $CLJS.M(null,"write","write",-1857649168);$CLJS.Wsa=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var Psa,Usa;Psa=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.M8=function(){function a(d,e){d=$CLJS.Ie(Qsa(d));var f=$CLJS.Ie($CLJS.ih($CLJS.$g(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.vu.g(d,f);d=$CLJS.vu.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.z(d);k=$CLJS.z(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.BE.h(f),$CLJS.N1.g($CLJS.M1,d))?$CLJS.N1.g($CLJS.M1,k):$CLJS.BE.h(f);f=$CLJS.R.l($CLJS.Nk.l(f,$CLJS.FM,$CLJS.H([$CLJS.oR,$CLJS.fQ])),$CLJS.BE,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Nk.g(e,d),k,f)}else e=$CLJS.nl.l($CLJS.H([Nsa($CLJS.We(d),
e),Osa($CLJS.T,$CLJS.lf.g(Rsa,k))]));e=$CLJS.Rt(e,Ssa)}else e=$CLJS.N;return e}function b(d){return $CLJS.M8.g?$CLJS.M8.g(d,null):$CLJS.M8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();Usa=new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.yO,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yO,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.GE],null)],null));
$CLJS.N8=function(){function a(d,e,f,k){var l=$CLJS.M8.h(e);return $CLJS.J8($CLJS.J1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.BW(new $CLJS.h(null,4,[$CLJS.Jj,$CLJS.nW,$CLJS.zV,f,$CLJS.SQ,l,$CLJS.vQ,e],null))],null)),k)}function b(d,e){return $CLJS.N8.v?$CLJS.N8.v(d,e,null,null):$CLJS.N8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.O8=function(){function a(d,e,f){$CLJS.RW(d,0);return $CLJS.J8($CLJS.J1.g(e,$CLJS.CL.h(d)),f)}function b(d,e){return $CLJS.O8.j?$CLJS.O8.j(d,e,null):$CLJS.O8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.I1.m(null,$CLJS.nW,function(a){return $CLJS.C1($CLJS.I8(a),$CLJS.ih($CLJS.$g($CLJS.K8(a))))&&!$CLJS.UA($CLJS.L8(a))});