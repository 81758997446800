var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var oV,pV,qV,sV,tV,uV,vV,wV,xV,yV;oV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);pV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);qV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.rV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);sV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);tV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);uV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
vV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);wV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);xV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);yV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(oV,$CLJS.hg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid template tag :widget-type"],null),$CLJS.uA],null),$CLJS.$g($CLJS.jV)));$CLJS.X(sV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Es,$CLJS.TM,$CLJS.IN,$CLJS.QF,$CLJS.iu,$CLJS.KB,$CLJS.OP],null));
$CLJS.X(vV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.GE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BE,$CLJS.GE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.GE,$CLJS.Ws],null)],null)],null));
$CLJS.X(wV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Jl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jO,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null)],null)],null));
$CLJS.X(tV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.QF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.bt,!0],
null),$CLJS.ok],null)],null)],null));
$CLJS.X(uV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.TM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FM,$CLJS.GE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.sL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.kL],null)],null)],null));
$CLJS.X(yV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.IN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oR,$CLJS.jL],null)],null)],null));$CLJS.X(xV,$CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Es],null),$CLJS.YU));
$CLJS.X(pV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xV],null)],null)],null)],null));
$CLJS.X(qV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.rj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null)],null)],null)],null));
$CLJS.X($CLJS.rV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,$CLJS.GE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Te(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));