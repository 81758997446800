var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var UV,WV,XV,Eka,aW,bW,cW,Gka,dW,eW,fW,hW,iW,jW,kW,lW,mW,oW,pW,qW,rW,sW,vW,wW,Fka;UV=function(a){return $CLJS.hg.j($CLJS.N,$CLJS.Xk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.pe(c)}),a)};
$CLJS.VV=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Ve.g($CLJS.MC,function(){return function e(d){return new $CLJS.qe(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.md(l,v);$CLJS.xe(t,$CLJS.WE(x,function(){return function(A){return $CLJS.Nk.l(UV(A),$CLJS.GB,$CLJS.H([$CLJS.Ki]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.we($CLJS.ye(t),e($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),
null)}var u=$CLJS.z(k);return $CLJS.he($CLJS.WE(u,function(){return function(v){return $CLJS.Nk.l(UV(v),$CLJS.GB,$CLJS.H([$CLJS.Ki]))}}(u,k,f,b)),e($CLJS.Lc(k)))}return null}},null,null)}(a)}())};
WV=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ff,a],null)],null);for(var c=$CLJS.Ff;;){var d=$CLJS.sd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.td(a);a=function(t,u,v,x,A,C,G,K){return function(S){return $CLJS.hg.j(v,$CLJS.lf.h(function(Y,ca,ja,va,Qa){return function(gb){var Ta=$CLJS.I(gb,0,null);gb=$CLJS.I(gb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ee.g(Qa,Ta),gb],null)}}(t,u,v,x,A,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.ee.g(c,l)):a=$CLJS.zd(k)?a(k):$CLJS.xd(k)?a($CLJS.jm($CLJS.at,k)):m}else return c}};XV=function(a,b,c){return $CLJS.Bd(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Kd(b,$CLJS.J.g(c,2))};$CLJS.YV=function(a){var b=$CLJS.hg.j($CLJS.eh,$CLJS.lf.h($CLJS.Wk.g($CLJS.FE,$CLJS.jd)),$CLJS.lQ.h(a));return WV($CLJS.Nk.l(a,$CLJS.QN,$CLJS.H([$CLJS.zV])),function(c){return XV($CLJS.DB,b,c)})};
$CLJS.ZV=function(a){var b=$CLJS.hg.j($CLJS.eh,$CLJS.lf.h($CLJS.Wk.g($CLJS.NE,$CLJS.jd)),$CLJS.lG.h(a));return WV($CLJS.Nk.l(a,$CLJS.QN,$CLJS.H([$CLJS.zV])),function(c){return XV($CLJS.lG,b,c)})};Eka=function(a){function b(d){return $CLJS.mf(c,$CLJS.H([$CLJS.QN.h(d)]))}function c(d){return $CLJS.he($CLJS.jF.h(d),$CLJS.mf(b,$CLJS.H([$CLJS.CL.h(d)])))}return $CLJS.n($CLJS.$V.h(a))?$CLJS.Xe(!0):$CLJS.ih(b(a))};
aW=function(a){if($CLJS.xd(a))for(var b=$CLJS.Xe(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,K=$CLJS.PA(m,Eka(C));f=$CLJS.z($CLJS.y($CLJS.of($CLJS.Wa,function(S,Y,ca,ja,va,Qa){return function yb(Ta,Db){try{if($CLJS.Bd(Db)&&3===$CLJS.D(Db))try{var kb=$CLJS.F(Db,0);if($CLJS.ke(kb,$CLJS.wG))try{var mb=$CLJS.F(Db,1);if($CLJS.n($CLJS.GP.h(mb)))try{var Pa=$CLJS.F(Db,1);if(null!=Pa?Pa.C&256||$CLJS.Bc===Pa.mf||(Pa.C?0:$CLJS.Za($CLJS.wb,
Pa)):$CLJS.Za($CLJS.wb,Pa))try{var Xc=$CLJS.J.j(Pa,$CLJS.GP,$CLJS.PV);if($CLJS.n($CLJS.We(ja)(Xc))){var Uk=$CLJS.J.g(Pa,$CLJS.GP);$CLJS.F(Db,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Qa),": no join named ",$CLJS.Ph.l($CLJS.H([Uk]))].join("")],null)}throw $CLJS.Z;}catch(ad){if(ad instanceof Error){var Vd=ad;if(Vd===$CLJS.Z)throw $CLJS.Z;throw Vd;}throw ad;}else throw $CLJS.Z;}catch(ad){if(ad instanceof Error){Vd=ad;if(Vd===$CLJS.Z)throw $CLJS.Z;throw Vd;
}throw ad;}else throw $CLJS.Z;}catch(ad){if(ad instanceof Error){Vd=ad;if(Vd===$CLJS.Z)throw $CLJS.Z;throw Vd;}throw ad;}else throw $CLJS.Z;}catch(ad){if(ad instanceof Error){Vd=ad;if(Vd===$CLJS.Z)throw $CLJS.Z;throw Vd;}throw ad;}else throw $CLJS.Z;}catch(ad){if(ad instanceof Error){Vd=ad;if(Vd===$CLJS.Z)return $CLJS.MV(yb,Ta,Db);throw Vd;}throw ad;}}}(f,k,l,K,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.Ff,$CLJS.Nk.l(C,$CLJS.QN,$CLJS.H([Fka]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};bW=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);cW=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Gka=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);dW=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
eW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);fW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.gW=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);hW=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
iW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);jW=new $CLJS.r(null,"refs","refs",80480079,null);kW=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);lW=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);mW=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.nW=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);oW=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);pW=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);qW=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);rW=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
sW=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.tW=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.$V=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.uW=new $CLJS.M(null,"filters","filters",974726919);vW=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);wW=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.xW=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Fka=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(kW,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.nW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vQ,$CLJS.Wa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mt,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.Jl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yO,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.GE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SQ,new $CLJS.h(null,1,[$CLJS.bt,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rV],null)],null)],null));$CLJS.X(pW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AG],null));
$CLJS.X(bW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.fo,1],null),pW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Breakouts must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.VV},cW,$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.DV,dW,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[jW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.VV)?$CLJS.VV.H:null]))],null)],null));
$CLJS.X(qW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.fo,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,":fields must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.VV},cW,$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.uk,$CLJS.zi,$CLJS.Yi,$CLJS.sj,$CLJS.Ek,$CLJS.yi,$CLJS.U,$CLJS.tk],[$CLJS.DV,dW,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,
1,5,$CLJS.Q,[jW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.VV)?$CLJS.VV.H:null]))],null)],null));$CLJS.X(rW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.VF],null),$CLJS.ok,$CLJS.ek],null)],null));
$CLJS.X(oW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.fo,1],null),rW],null));
var yW=$CLJS.PA(function(a){a=$CLJS.z($CLJS.YV(a));if($CLJS.n(a)){var b=$CLJS.QA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Ph.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.ZV(a));if($CLJS.n(a)){var b=$CLJS.QA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(sW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,2,[$CLJS.Ht,"Valid references for a single query stage",$CLJS.Jt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return yW(a)}],null),$CLJS.We(yW)],null));
$CLJS.X($CLJS.xW,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.gW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QN,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lQ,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.kG,new $CLJS.h(null,1,[$CLJS.bt,!0],null),bW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fF,new $CLJS.h(null,1,[$CLJS.bt,!0],null),qW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uW,new $CLJS.h(null,1,[$CLJS.bt,!0],null),oW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aS,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.IL],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rP,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$V,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jL],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Kd(a,$CLJS.UQ)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,
new $CLJS.h(null,1,[$CLJS.Ht,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.We($CLJS.uE($CLJS.rP,$CLJS.$V))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sW],null)],null));$CLJS.X(eW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dL],null)],null)],null)],null));
$CLJS.X(fW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$V,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jL],null)],null)],null)],null));$CLJS.X(iW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fW],null)],null));
$CLJS.X(lW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.We($CLJS.PA($CLJS.rP,$CLJS.$V))],null)],null));$CLJS.X(mW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.nW,$CLJS.gW],null));
$CLJS.X(Gka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,mW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.Jj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xW],null)],null)],null)],null));
$CLJS.X(wW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,mW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.Jj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iW],null)],null)],null)],null));$CLJS.X(hW,lW);
$CLJS.X(vW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,2,[$CLJS.Ht,"Valid references for all query stages",$CLJS.Jt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return aW.h?aW.h(a):aW.call(null,a)}],null),$CLJS.We(aW)],null));
$CLJS.X($CLJS.yL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hW],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vW],null)],null));
$CLJS.X($CLJS.YL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.tW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.kL,$CLJS.qL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yL],null)],null)],null),$CLJS.xka],null));