var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var pfa,qfa,rfa,sfa,tfa,ufa,vfa,wfa,xfa;pfa=function(a,b){a.sort(b||$CLJS.Ea)};qfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ea;pfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.hH=function(a){return $CLJS.E.g(a,$CLJS.Md)?$CLJS.Md:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.iH=function(a,b){if($CLJS.y(b)){var c=$CLJS.ze.h?$CLJS.ze.h(b):$CLJS.ze.call(null,b);qfa(c,$CLJS.hH(a));return $CLJS.qd($CLJS.y(c),$CLJS.rd(b))}return $CLJS.Kc};$CLJS.jH=new $CLJS.M(null,"second","second",-444702010);$CLJS.kH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.lH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.mH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.nH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);rfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
sfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.oH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.qH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
tfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);ufa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);vfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
wfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.sH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.tH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
xfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var uH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Li,$CLJS.Lj,$CLJS.Kj,$CLJS.ui,$CLJS.rk,$CLJS.Ei,$CLJS.ni,$CLJS.nH],null),vH=$CLJS.ih(uH),yfa,zfa,Afa;$CLJS.X(vfa,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid date extraction unit"],null)],null),vH));var wH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ou,$CLJS.tj,$CLJS.Cj,$CLJS.ti,$CLJS.ni],null),xH=$CLJS.ih(wH);
$CLJS.X(ufa,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid date truncation unit"],null)],null),xH));$CLJS.yH=$CLJS.hg.j($CLJS.Ff,$CLJS.pl.o(),$CLJS.kf.g(wH,uH));yfa=$CLJS.ih($CLJS.yH);$CLJS.X($CLJS.qH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid date bucketing unit"],null)],null),yfa));var zH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kH,$CLJS.nj,$CLJS.Ai],null),AH=$CLJS.ih(zH);
$CLJS.X(tfa,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid time extraction unit"],null)],null),AH));var BH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mH,$CLJS.jH,$CLJS.Zi,$CLJS.vk],null),CH=$CLJS.ih(BH);$CLJS.X(xfa,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid time truncation unit"],null)],null),CH));$CLJS.DH=$CLJS.hg.j($CLJS.Ff,$CLJS.pl.o(),$CLJS.kf.g(BH,zH));zfa=$CLJS.ih($CLJS.DH);
$CLJS.X(sfa,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid time bucketing unit"],null)],null),zfa));$CLJS.EH=$CLJS.hg.j($CLJS.Ff,$CLJS.pl.o(),$CLJS.kf.l(BH,wH,$CLJS.H([zH,uH])));Afa=$CLJS.ih($CLJS.EH);$CLJS.X($CLJS.lH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid datetime bucketing unit"],null)],null),$CLJS.EH));var Bfa=$CLJS.ee.g(Afa,$CLJS.gi);
$CLJS.X($CLJS.tE,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid temporal bucketing unit"],null)],null),Bfa));$CLJS.FH=$CLJS.pu.g(xH,CH);$CLJS.X($CLJS.oH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid datetime truncation unit"],null)],null),$CLJS.FH));$CLJS.GH=$CLJS.pu.g(vH,AH);$CLJS.X(rfa,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid datetime extraction unit"],null)],null),$CLJS.GH));
var HH=$CLJS.ee.g(xH,$CLJS.ni);$CLJS.X($CLJS.tH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid date interval unit"],null)],null),HH));$CLJS.X($CLJS.pH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid time interval unit"],null)],null),CH));var Cfa=$CLJS.pu.g(HH,CH);$CLJS.X($CLJS.rH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.Ht,"Valid datetime interval unit"],null)],null),Cfa));
$CLJS.X(wfa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.sH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,$CLJS.tE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null)],null));