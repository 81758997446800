var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var QY,Qla,SY,Rla,Sla,Tla,UY,Vla,Wla,Xla,Yla,XY,YY,ZY,$Y,bZ,$la,cZ,dZ,ama,bma,cma,WY,Ula,eZ,fZ,Zla,hZ;QY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.ef(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Qla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj.j?$CLJS.rj.j(k,l,m):$CLJS.rj.call(null,k,l,m),$CLJS.tl.j?$CLJS.tl.j(k,l,m):$CLJS.tl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj.g?$CLJS.rj.g(k,l):$CLJS.rj.call(null,k,l),$CLJS.tl.g?$CLJS.tl.g(k,l):$CLJS.tl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj.h?$CLJS.rj.h(k):$CLJS.rj.call(null,k),$CLJS.tl.h?$CLJS.tl.h(k):$CLJS.tl.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj.o?$CLJS.rj.o():$CLJS.rj.call(null),$CLJS.tl.o?$CLJS.tl.o():$CLJS.tl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ve.N($CLJS.rj,m,t,u,v),$CLJS.Ve.N($CLJS.tl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.z(m);m=$CLJS.B(m);var u=$CLJS.z(m);m=$CLJS.B(m);var v=$CLJS.z(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.RY=function(a){return function(b){var c=$CLJS.cf(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
SY=function(a){return $CLJS.cM($CLJS.xW)(a,$CLJS.Ff,$CLJS.Ff)};Rla=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.lG,b),$CLJS.xt(2,2,c))};Sla=function(a,b){var c=$CLJS.lm(a,$CLJS.SQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Gb(c);b=$CLJS.Rt.g?$CLJS.Rt.g(c,b):$CLJS.Rt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.SQ,b)}return a};Tla=function(a){return $CLJS.kf.g($CLJS.YV(a),$CLJS.ZV(a))};
$CLJS.TY=function(a,b){return $CLJS.JW(a)&&$CLJS.E.g($CLJS.z(a),b)};UY=function(a,b,c){var d=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.et,null,$CLJS.zl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Lu(2,c):$CLJS.Lu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?QY(2,c):QY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.IX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.Bd(k)?$CLJS.hg.j($CLJS.Ff,$CLJS.Xk.h($CLJS.Gl),l):$CLJS.zd(k)?$CLJS.WA($CLJS.Wa,l):l}):$CLJS.Nk.g(a,f)};
Vla=function(a){return $CLJS.db(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return UY(b,Ula,d)},a,Tla(a))};
Wla=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.CL.h(a),b);a:{var d=c;for(var e=$CLJS.Ff;;){var f=$CLJS.z($CLJS.lf.g(Qla(),$CLJS.of($CLJS.Wk.j(VY,$CLJS.z,$CLJS.tl),$CLJS.it.h(SY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=UY(d,f,k),m=$CLJS.ZA($CLJS.Uy);if($CLJS.n($CLJS.YA("metabase.lib.convert",m))){var t=$CLJS.OB.l($CLJS.H([$CLJS.Pz,$CLJS.Ph.l($CLJS.H([k]))])),u=$CLJS.OB,v=u.l,x=$CLJS.Ph,A=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.hM(SY(d));$CLJS.XA("metabase.lib.convert",
m,$CLJS.GW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Pz,A.call(x,$CLJS.H([C]))])),$CLJS.OB.l($CLJS.H([$CLJS.Ez,$CLJS.HW($CLJS.z($CLJS.su(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.ee.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=Vla(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.CL.h(a))-1))return a;b+=1}else a=$CLJS.Zk.N(a,$CLJS.CL,$CLJS.R,b,d)}};
Xla=function(a){var b=$CLJS.UW();return $CLJS.Yk.g(function(c){return $CLJS.E.g($CLJS.jF.h(c),"__join")?$CLJS.Zk.j(c,$CLJS.jF,b):c},a)};Yla=function(a){return"string"===typeof $CLJS.rP.h(a)?$CLJS.Nk.g($CLJS.R.j(a,$CLJS.$V,$CLJS.SW($CLJS.rP.h(a))),$CLJS.rP):a};XY=function(a,b){var c=$CLJS.J.j(a,b,WY);if($CLJS.E.g(c,WY))throw $CLJS.li(["Unable to find ",$CLJS.Ph.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[Zla,a,$CLJS.kx,b],null));return c};
YY=function(){return $CLJS.Xk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.pe(b))?(b=$CLJS.le(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};ZY=function(a){return $CLJS.hg.j($CLJS.N,YY(),a)};$Y=function(a){return $CLJS.Ie($CLJS.hg.j($CLJS.N,$CLJS.Wk.g(YY(),$CLJS.Xk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Ki)})),a))};
bZ=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.hg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.lf.h($CLJS.aZ),c);a=$CLJS.E.g(a,$CLJS.fG)&&$CLJS.B(c)?$CLJS.ee.g($CLJS.td(d),new $CLJS.h(null,1,[$CLJS.gi,$CLJS.sd(d)],null)):d;b=$CLJS.Ie($Y(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HM,a,b],null):a};$la=function(a){return $CLJS.hg.j($CLJS.Ff,$CLJS.Wk.g($CLJS.lf.h(function(b){return $CLJS.RA(b,$CLJS.TB)}),$CLJS.lf.h($CLJS.aZ)),$CLJS.OW.h(a))};
cZ=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.CL);a=$CLJS.z($CLJS.db(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.aZ.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.UQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.dP,$la(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.zV.h(c)],null)},null,a));return $CLJS.n($CLJS.vQ.h(a))?$CLJS.LV(a,new $CLJS.h(null,1,[$CLJS.vQ,$CLJS.TF],null)):a};
dZ=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.fX(a,b,$CLJS.Wk.g($CLJS.aZ,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.fX(d,b,function(e){return $CLJS.hg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Os],null),$CLJS.lf.h($CLJS.aZ),e)}):d;return $CLJS.LV(a,$CLJS.He([b,c]))};ama=function(a){var b=$CLJS.$V.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Nk.g(a,$CLJS.$V),$CLJS.rP,["card__",$CLJS.p.h(b)].join("")):a};
bma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);cma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);WY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Ula=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);eZ=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
fZ=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.gZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);Zla=new $CLJS.M(null,"m","m",1632677161);hZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var VY,dma,ema,fma,gma,hma,ima,jma,kma,lma,mma;$CLJS.iZ=$CLJS.N;$CLJS.jZ=$CLJS.N;VY=new $CLJS.dh(null,new $CLJS.h(null,7,[$CLJS.uW,null,$CLJS.fF,null,$CLJS.QN,null,$CLJS.lQ,null,$CLJS.kG,null,$CLJS.aS,null,$CLJS.lG,null],null),null);dma=$CLJS.$e($CLJS.N);ema=$CLJS.$e($CLJS.N);fma=$CLJS.$e($CLJS.N);gma=$CLJS.$e($CLJS.N);hma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
$CLJS.kZ=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.sC,hma,dma,ema,fma,gma);$CLJS.kZ.m(null,$CLJS.gi,function(a){if($CLJS.Bd(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.sd(a);var c=$CLJS.zd(b);a=c?$CLJS.td(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.BW($CLJS.hg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.lf.h($CLJS.kZ),d))}else b=a;return b});$CLJS.kZ.m(null,$CLJS.tW,function(a){return a});
$CLJS.kZ.m(null,$CLJS.gW,function(a){var b=$CLJS.kZ.h($CLJS.lG.h(a)),c=$CLJS.Ie($CLJS.Yk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.LW($CLJS.kZ.h(t),u)},$CLJS.lQ.h(a))),d=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.NE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.jZ,k=$CLJS.iZ;$CLJS.jZ=d;$CLJS.iZ=e;try{var l=Rla(Yla(a),b,$CLJS.H([$CLJS.lQ,c])),m=$CLJS.db(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Zk.j(t,u,$CLJS.kZ)},l,$CLJS.Ok.l(VY,$CLJS.lG,$CLJS.H([$CLJS.lQ])));return $CLJS.n($CLJS.QN.h(m))?$CLJS.Zk.j(m,$CLJS.QN,Xla):m}finally{$CLJS.iZ=k,$CLJS.jZ=f}});$CLJS.kZ.m(null,$CLJS.nW,function(a){return Sla(a,function(b){return $CLJS.fX(b,$CLJS.QF,$CLJS.kZ)})});
$CLJS.kZ.m(null,$CLJS.xL,function(a){a=$CLJS.Zk.j($CLJS.Zk.j(a,$CLJS.FL,$CLJS.kZ),$CLJS.CL,$CLJS.kZ);var b=$CLJS.n($CLJS.fF.h(a))?$CLJS.Zk.j(a,$CLJS.fF,function(c){return $CLJS.Sl(c)?$CLJS.Yk.g($CLJS.kZ,c):$CLJS.Ch.h(c)}):a;return $CLJS.Va($CLJS.jF.h(a))?$CLJS.R.j(b,$CLJS.jF,"__join"):b});$CLJS.kZ.m(null,$CLJS.AB,function(a){return $CLJS.Yk.g($CLJS.kZ,a)});
$CLJS.kZ.m(null,$CLJS.CB,function(a){return $CLJS.n($CLJS.rj.h(a))?Wla($CLJS.R.j($CLJS.Zk.j($CLJS.PW(a),$CLJS.CL,function(b){return $CLJS.Yk.g($CLJS.kZ,b)}),$CLJS.gZ,!0)):$CLJS.Rt(a,$CLJS.kZ)});$CLJS.kZ.m(null,$CLJS.wG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.zd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.BW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,a,b],null))});
$CLJS.kZ.m(null,$CLJS.Ij,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.LV(a,new $CLJS.h(null,3,[$CLJS.tD,$CLJS.GB,$CLJS.QO,$CLJS.ak,$CLJS.iS,$CLJS.IE],null));var c=$CLJS.R.j;var d=$CLJS.Ki.h(a);$CLJS.n(d)||(d=$CLJS.GB.h(a),d=$CLJS.n(d)?d:$CLJS.aF(b));a=c.call($CLJS.R,a,$CLJS.Ki,d);return $CLJS.BW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,a,b],null))});
$CLJS.kZ.m(null,$CLJS.fG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.gi.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,$CLJS.Nk.g(c,$CLJS.gi),$CLJS.Yk.g($CLJS.kZ,b)],null);b=$CLJS.BW(b);return null!=a?$CLJS.ee.g(b,$CLJS.kZ.h(a)):b});$CLJS.kZ.m(null,$CLJS.DB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.BW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.kZ.m(null,$CLJS.lG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.BW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,XY($CLJS.jZ,c)],null))});$CLJS.kZ.m(null,$CLJS.HM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.kZ.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.nl.l($CLJS.H([c,a]))],null),d)});ima=$CLJS.$e($CLJS.N);jma=$CLJS.$e($CLJS.N);
kma=$CLJS.$e($CLJS.N);lma=$CLJS.$e($CLJS.N);mma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.aZ=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.sC,mma,ima,jma,kma,lma);
$CLJS.aZ.m(null,$CLJS.gi,function(a){if($CLJS.Bd(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.zd(a)){c=$CLJS.hg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.kf.g;d=$CLJS.lf.g($CLJS.aZ,d);a=$Y(a);a=c.call($CLJS.hg,b,e.call($CLJS.kf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.lf.g($CLJS.aZ,$CLJS.he(a,d)))}else a=$CLJS.zd(a)?$CLJS.Rt(ZY(a),$CLJS.aZ):
a;return a});for(var lZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[hZ,fZ],null)),mZ=null,nZ=0,oZ=0;;)if(oZ<nZ){var nma=mZ.X(null,oZ);$CLJS.EF(nma,eZ);oZ+=1}else{var pZ=$CLJS.y(lZ);if(pZ){var qZ=pZ;if($CLJS.Cd(qZ)){var rZ=$CLJS.lc(qZ),oma=$CLJS.mc(qZ),pma=rZ,qma=$CLJS.D(rZ);lZ=oma;mZ=pma;nZ=qma}else{var rma=$CLJS.z(qZ);$CLJS.EF(rma,eZ);lZ=$CLJS.B(qZ);mZ=null;nZ=0}oZ=0}else break}
for(var sZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.gy,$CLJS.qG,$CLJS.FG,$CLJS.bG,$CLJS.pk,$CLJS.KG,$CLJS.fo,$CLJS.hG,$CLJS.DG,$CLJS.MG,$CLJS.JG,$CLJS.RG],null)),tZ=null,uZ=0,vZ=0;;)if(vZ<uZ){var sma=tZ.X(null,vZ);$CLJS.EF(sma,hZ);vZ+=1}else{var wZ=$CLJS.y(sZ);if(wZ){var xZ=wZ;if($CLJS.Cd(xZ)){var yZ=$CLJS.lc(xZ),tma=$CLJS.mc(xZ),uma=yZ,vma=$CLJS.D(yZ);sZ=tma;tZ=uma;uZ=vma}else{var wma=$CLJS.z(xZ);$CLJS.EF(wma,hZ);sZ=$CLJS.B(xZ);tZ=null;uZ=0}vZ=0}else break}
for(var zZ=$CLJS.y($CLJS.Bf([$CLJS.ls,$CLJS.At,$CLJS.ms,$CLJS.SG,$CLJS.fG,$CLJS.zG,$CLJS.XF,$CLJS.UG,$CLJS.OF,$CLJS.jG,$CLJS.SF,$CLJS.uG,$CLJS.BG,$CLJS.UF,$CLJS.lJ,$CLJS.nJ,$CLJS.Vy,$CLJS.hJ,$CLJS.pJ,$CLJS.bJ,$CLJS.tJ,$CLJS.gJ,$CLJS.$I,$CLJS.sJ,$CLJS.AJ,$CLJS.wJ,$CLJS.fJ,$CLJS.DJ,$CLJS.vJ,$CLJS.BJ,$CLJS.WF,$CLJS.PF,$CLJS.VG,$CLJS.mI,$CLJS.yG,$CLJS.ey,$CLJS.NG,$CLJS.PG,$CLJS.WG,$CLJS.Ut,$CLJS.$t],!0)),AZ=null,BZ=0,CZ=0;;)if(CZ<BZ){var xma=AZ.X(null,CZ);$CLJS.EF(xma,fZ);CZ+=1}else{var DZ=$CLJS.y(zZ);
if(DZ){var EZ=DZ;if($CLJS.Cd(EZ)){var FZ=$CLJS.lc(EZ),yma=$CLJS.mc(EZ),zma=FZ,Ama=$CLJS.D(FZ);zZ=yma;AZ=zma;BZ=Ama}else{var Bma=$CLJS.z(EZ);$CLJS.EF(Bma,fZ);zZ=$CLJS.B(EZ);AZ=null;BZ=0}CZ=0}else break}$CLJS.aZ.m(null,eZ,function(a){return bZ(a)});$CLJS.aZ.m(null,$CLJS.CB,function(a){return $CLJS.hg.j($CLJS.N,$CLJS.Wk.g(YY(),$CLJS.lf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.aZ.h(b)],null)})),a)});
$CLJS.aZ.m(null,$CLJS.lG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.zd(b))try{var d=$Y(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,XY($CLJS.iZ,c)],null);return $CLJS.n(d)?$CLJS.ee.g(e,d):e}catch(f){throw b=f,c=$CLJS.EV(b),c=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.ki(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.aZ.m(null,$CLJS.AB,function(a){return $CLJS.Yk.g($CLJS.aZ,a)});$CLJS.aZ.m(null,$CLJS.wG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.zd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,$CLJS.aZ.h(a),$Y(b)],null)});
$CLJS.aZ.m(null,$CLJS.Ij,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$Y($CLJS.LV(b,new $CLJS.h(null,3,[$CLJS.GB,$CLJS.tD,$CLJS.ak,$CLJS.QO,$CLJS.IE,$CLJS.iS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,a,b],null)});
$CLJS.aZ.m(null,$CLJS.xL,function(a){var b=ZY(a);a=0==$CLJS.jF.h(a).lastIndexOf("__join",0)?$CLJS.Nk.g(b,$CLJS.jF):b;return $CLJS.nl.l($CLJS.H([$CLJS.Rt($CLJS.Nk.l(a,$CLJS.CL,$CLJS.H([$CLJS.FL])),$CLJS.aZ),dZ($CLJS.km(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.FL],null)),$CLJS.FL,$CLJS.cS),cZ(a)]))});
$CLJS.aZ.m(null,$CLJS.gW,function(a){var b=$CLJS.lG.h(a),c=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.jZ,e=$CLJS.iZ;$CLJS.jZ=c;$CLJS.iZ=b;try{return $CLJS.db(function(f,k){return $CLJS.fX(f,
k,$CLJS.aZ)},dZ($CLJS.fX($CLJS.fX(ama(ZY(a)),$CLJS.lG,function(f){return $CLJS.Yk.g(bZ,f)}),$CLJS.lQ,function(f){return $CLJS.hg.g($CLJS.N,function(){return function m(l){return new $CLJS.qe(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Cd(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.te(v);a:for(var A=0;;)if(A<v){var C=$CLJS.md(u,A),G=$CLJS.aZ.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KW(C),$CLJS.E.g($CLJS.Ij,$CLJS.z(G))?$CLJS.jd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.we($CLJS.ye(x),
m($CLJS.mc(t))):$CLJS.we($CLJS.ye(x),null)}x=$CLJS.z(t);u=$CLJS.aZ.h(x);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KW(x),$CLJS.E.g($CLJS.Ij,$CLJS.z(u))?$CLJS.jd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.uW,$CLJS.aG),$CLJS.Ok.l(VY,$CLJS.lG,$CLJS.H([$CLJS.uW,$CLJS.lQ])))}finally{$CLJS.iZ=e,$CLJS.jZ=d}});$CLJS.aZ.m(null,$CLJS.nW,function(a){return $CLJS.Rt(ZY(a),$CLJS.aZ)});
$CLJS.aZ.m(null,$CLJS.tW,function(a){try{var b=ZY(a),c=$CLJS.HN.h(b),d=cZ(b),e=$CLJS.E.g($CLJS.Jj.h($CLJS.kd($CLJS.CL.h(a))),$CLJS.nW)?$CLJS.vQ:$CLJS.TF;return $CLJS.nl.l($CLJS.H([$CLJS.Rt($CLJS.Nk.l(b,$CLJS.CL,$CLJS.H([$CLJS.HN,$CLJS.gZ])),$CLJS.aZ),function(){var k=$CLJS.He([$CLJS.rj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.HN,c):k}()]))}catch(k){var f=k;throw $CLJS.ki(function(){var l=$CLJS.EV(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.TF,a],null),f);}});
$CLJS.GZ=function(){function a(d,e,f){f=$CLJS.PY(null,$CLJS.bA(f,$CLJS.H([$CLJS.Di,!0])));var k=$CLJS.RW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.lG);k=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.jZ,t=$CLJS.iZ;$CLJS.jZ=k;$CLJS.iZ=l;try{try{return $CLJS.kZ.h(f)}catch(v){var u=v;throw $CLJS.ki(function(){var x=$CLJS.EV(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.TF,d,$CLJS.QL,e,cma,f,bma,$CLJS.jZ],null),u);}}finally{$CLJS.iZ=t,$CLJS.jZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();