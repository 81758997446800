var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Yma,Z_,Zma,a0,b0,c0,d0,x0,bna,ana,cna,$ma,D0,p0,F0,dna,r0;Yma=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};Z_=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Kd($CLJS.Wda,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Zma=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.IB,!0],null)),c=$CLJS.J.g(b,$CLJS.Vda),d=$CLJS.J.g(b,$CLJS.IB);return $CLJS.y(a)?(b=$CLJS.IV(function(){return function k(f){return new $CLJS.qe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);x=Z_(x,$CLJS.Va(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}u=$CLJS.z(l);return $CLJS.he(Z_(u,
$CLJS.Va(d)),k($CLJS.Lc(l)))}return null}},null,null)}(Yma($CLJS.nB(a)))}()),$CLJS.n(c)?$CLJS.IV($CLJS.df(c,b)):b):null};$CLJS.$_=function(a){return $CLJS.rP.h($CLJS.z($CLJS.CL.h(a)))};a0=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=a0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=a0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.table",a);}return a};
b0=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=b0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=b0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.card",a);}return a};c0=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=c0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=c0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.metric",a);}return a};
d0=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=d0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=d0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.fields",a);}return a};$CLJS.e0=function(a){return $CLJS.IZ(a)?a:$CLJS.xE.h(a)};$CLJS.f0=function(a,b){return a0($CLJS.e0(a),b)};$CLJS.g0=function(a,b){return d0($CLJS.e0(a),b)};$CLJS.h0=function(a,b){return b0($CLJS.e0(a),b)};$CLJS.i0=function(a,b){return c0($CLJS.e0(a),b)};
$CLJS.j0=function(a,b,c){if($CLJS.Xa(b)||$CLJS.zd(b)){$CLJS.n(b.__mbcache)||(b.__mbcache=$CLJS.$e($CLJS.N));var d=b.__mbcache;if($CLJS.n(d)){var e=$CLJS.J.g($CLJS.q(d),a);if($CLJS.n(e))return e;b=c.h?c.h(b):c.call(null,b);$CLJS.Rh.v(d,$CLJS.R,a,b);return b}}return c.h?c.h(b):c.call(null,b)};
x0=function(a,b,c){var d=$CLJS.k0.j(a,b,c);return $CLJS.nl.l($CLJS.H([$CLJS.km(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,$CLJS.BE,$CLJS.ak],null)),function(){var e=$CLJS.l0.v(a,b,c,$CLJS.m0);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.n0,e],null):null}(),function(){var e=$CLJS.PA($CLJS.Ki,$CLJS.GB)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ki,e],null):null}(),function(){var e=$CLJS.nL.h(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.$W,$CLJS.o0.j(a,b,$CLJS.Id(e)?$CLJS.f0(a,e):"string"===typeof e?
$CLJS.h0(a,$CLJS.SW(e)):null)],null):null}(),function(){var e=$CLJS.aL.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[p0,$CLJS.E.g(e,$CLJS.KZ),$CLJS.q0,$CLJS.E.g(e,$CLJS.TZ),r0,$CLJS.E.g(e,$CLJS.QZ),$CLJS.s0,$CLJS.E.g(e,$CLJS.SZ),$ma,$CLJS.E.g(e,$CLJS.vL),ana,$CLJS.E.g(e,$CLJS.pL)],null):null}(),function(){var e=$CLJS.XZ.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.t0,e],null)}(),$CLJS.km(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.u0,$CLJS.v0,$CLJS.w0],null))]))};
$CLJS.y0=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.w0=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.z0=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.s0=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.u0=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.A0=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);
$CLJS.t0=new $CLJS.M(null,"selected","selected",574897764);bna=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);ana=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);cna=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.B0=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);
$CLJS.C0=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);$ma=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);D0=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.E0=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);p0=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);
F0=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);$CLJS.G0=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.q0=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.H0=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.v0=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);dna=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.n0=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.I0=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.m0=new $CLJS.M(null,"long","long",-171452093);$CLJS.J0=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.K0=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);r0=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.L0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.sC(l)},e,a,b,c,d)}();
$CLJS.M0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.sC(l)},e,a,b,c,d)}();
$CLJS.l0=function(){function a(f,k,l,m){var t=$CLJS.BE.h($CLJS.UE(l));if($CLJS.n(t))return t;try{return $CLJS.L0.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.ki($CLJS.wE("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.Ph.l($CLJS.H([l])),$CLJS.EV(k)])),new $CLJS.h(null,2,[$CLJS.TF,f,$CLJS.OE,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.gi):e.call(null,f,k,l,$CLJS.gi)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.N0=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.UE(f));if($CLJS.n(k))return k;try{return $CLJS.M0.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.ki($CLJS.wE("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.Ph.l($CLJS.H([f])),$CLJS.EV(k)])),new $CLJS.h(null,3,[$CLJS.OE,f,$CLJS.TF,d,$CLJS.QL,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.L0.m(null,$CLJS.gi,function(a,b,c){a=$CLJS.ZA($CLJS.Uy);$CLJS.n($CLJS.YA("metabase.lib.metadata.calculation",a))&&$CLJS.XA("metabase.lib.metadata.calculation",a,$CLJS.GW("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.Ph.l($CLJS.H([c])),cna,$CLJS.sC(c)])),null);return $CLJS.Bd(c)&&$CLJS.z(c)instanceof $CLJS.M?$CLJS.jh($CLJS.z(c)):$CLJS.Ph.l($CLJS.H([c]))});
$CLJS.M0.m(null,$CLJS.gi,function(a,b,c){return Zma($CLJS.TA($CLJS.l0.j(a,b,c),/[\(\)]/,""))});$CLJS.O0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.ena=function(){function a(d,e,f){return $CLJS.O0.j(d,e,$CLJS.Ch.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.P0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.sC(l)},e,a,b,c,d)}();
$CLJS.Q0=function(){function a(d,e,f){var k=$CLJS.UE(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.CG),m=$CLJS.Ki.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Kd($CLJS.GH,l):l)?$CLJS.Bj:null;if($CLJS.n(l))return l;k=$CLJS.GB.h(k);if($CLJS.n(k))return k;d=$CLJS.P0.j(d,e,f);return $CLJS.GC(d,$CLJS.Fj)?d:$CLJS.Fj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.P0.m(null,$CLJS.gi,function(a,b,c){return $CLJS.aF(c)});$CLJS.P0.m(null,$CLJS.qF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.Q0.j(a,b,c)});$CLJS.P0.m(null,$CLJS.iJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.aF(c):$CLJS.Q0.j(a,b,d)});
$CLJS.R0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.sC(l)},e,a,b,c,d)}();
$CLJS.R0.m(null,$CLJS.gi,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Jj,$CLJS.ZK,$CLJS.GB,$CLJS.Q0.j(a,b,c),$CLJS.T,$CLJS.N0.j(a,b,c),$CLJS.BE,$CLJS.l0.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.ki($CLJS.wE("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.Ph.l($CLJS.H([$CLJS.sC(c)])),$CLJS.EV(d)])),new $CLJS.h(null,3,[$CLJS.TF,a,$CLJS.QL,b,$CLJS.OE,c],null),d);}throw e;}});
$CLJS.k0=function(){function a(e,f,k){return $CLJS.R0.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.S0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.sC(l)},e,a,b,c,d)}();
$CLJS.X(D0,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BE,$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.n0,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$W,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,D0],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[p0,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.q0,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[r0,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.s0,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[F0,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[dna,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[bna,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Ys],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aH,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.ek],null),
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.au,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.J0,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.ek],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.H0,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.t0,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gi,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.Ys],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.z0,
new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.tG,$CLJS.mG],null)],null)],null));
$CLJS.o0=function(){function a(d,e,f){return $CLJS.j0($CLJS.Ch.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.S0.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.ki($CLJS.wE("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.sC(k),$CLJS.EV(l)])),new $CLJS.h(null,3,[$CLJS.TF,d,$CLJS.QL,e,$CLJS.OE,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.S0.m(null,$CLJS.gi,function(a,b,c){return x0(a,b,c)});$CLJS.S0.m(null,$CLJS.fL,function(a,b,c){return $CLJS.nl.l($CLJS.H([x0(a,b,c),new $CLJS.h(null,1,[F0,$CLJS.E.g($CLJS.$_(a),$CLJS.dj.h(c))],null)]))});
$CLJS.T0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.sC(l)},e,a,b,c,d)}();$CLJS.T0.m(null,$CLJS.JB,function(){return $CLJS.Ff});
$CLJS.U0=function(){function a(f,k,l,m){m=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.G0,$CLJS.UW()],null),m]));return $CLJS.T0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.RW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.V0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.sC(l)},e,a,b,c,d)}();$CLJS.V0.m(null,$CLJS.JB,function(){return $CLJS.Ff});
$CLJS.V0.m(null,$CLJS.gi,function(a,b,c,d){return $CLJS.T0.v(a,b,c,d)});
$CLJS.W0=function(){function a(f,k,l,m){m=$CLJS.nl.l($CLJS.H([$CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.G0,$CLJS.UW()],null),new $CLJS.h(null,4,[$CLJS.E0,!0,$CLJS.K0,!0,$CLJS.A0,!0,$CLJS.I0,!0],null)])),m]));return $CLJS.V0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.RW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.l0};