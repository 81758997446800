var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.lib.cache.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var jwa=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},kwa=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},lwa=function(a){return $CLJS.gc($CLJS.db(function(b,c){return $CLJS.kg.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ec($CLJS.N),a))},mwa=function(a,b,c,d){var e=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(f,k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),d);return function l(k){return new $CLJS.qe(null,function(){for(;;){var m=
$CLJS.y(k);if(m){if($CLJS.Cd(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.te(u);a:for(var x=0;;)if(x<u){var A=$CLJS.md(t,x);A=$CLJS.x2.v(a,b,A,d);A=$CLJS.J.j(e,A,-1);v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.we($CLJS.ye(v),l($CLJS.mc(m))):$CLJS.we($CLJS.ye(v),null)}v=$CLJS.z(m);v=$CLJS.x2.v(a,b,v,d);return $CLJS.he($CLJS.J.j(e,v,-1),l($CLJS.Lc(m)))}return null}},null,null)}(c)},A9=function(a,b){return new $CLJS.Mh(function(){try{return $CLJS.w9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=
$CLJS.ZA($CLJS.Sy);$CLJS.n($CLJS.YA("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.XA("metabase.lib.js.metadata",d,$CLJS.GW("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.EV(c)])),c):$CLJS.XA("metabase.lib.js.metadata",d,$CLJS.GW(c,$CLJS.H(["Error parsing %s objects: %s",a,$CLJS.EV(c)])),null));return null}throw e;}})},nwa=function(a,b){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);return function(){for(var x=
0;;)if(x<l){var A=$CLJS.md(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.nL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.nL.h(v),b):x}()))return $CLJS.he(v,
e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.fF.h(a);return null==d?null:$CLJS.q(d)}())},owa=function(a,b){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.md(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?
$CLJS.E.g($CLJS.nL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.nL.h(v),b):x}()))return $CLJS.he(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.r9.h(a);return null==d?null:$CLJS.q(d)}())},pwa=function(a,b){return function e(d){return new $CLJS.qe(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.md(k,x);$CLJS.I(A,0,null);var C=$CLJS.I(A,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.nL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}var t=$CLJS.z(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=
u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.nL.h(v),b):x}()))return $CLJS.he(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.s9.h(a);return null==d?null:$CLJS.q(d)}())},B9=function(a,b,c,d){this.fg=a;this.Ii=b;this.tc=c;this.ji=d;this.C=393216;this.I=0},qwa=function(a){return $CLJS.Xa(a)?jwa(a,function(b){return void 0!==b}):a},C9=function(a){return $CLJS.Rt($CLJS.cA(kwa(a,function(b){return qwa(b)})),
function(b){return $CLJS.Zk.j($CLJS.RA(b,$CLJS.Ch),$CLJS.rj,$CLJS.Ch)})},D9=function(a,b){if($CLJS.IZ(b))a=b;else{var c=new $CLJS.h(null,6,[$CLJS.v9,A9($CLJS.gQ,b),$CLJS.t9,A9($CLJS.$W,b),$CLJS.fF,A9($CLJS.wG,b),$CLJS.q9,A9($CLJS.IN,b),$CLJS.r9,A9($CLJS.rG,b),$CLJS.s9,A9($CLJS.VF,b)],null),d=$CLJS.ZA($CLJS.Ry);$CLJS.n($CLJS.YA("metabase.lib.js.metadata",d))&&$CLJS.XA("metabase.lib.js.metadata",d,$CLJS.Qw.l($CLJS.H(["Created metadata provider for metadata"])),null);a=new B9(a,b,c,$CLJS.N)}return a},
G9=function(a){return $CLJS.zd(a)?E9.h?E9.h(a):E9.call(null,a):$CLJS.xd(a)?F9.h?F9.h(a):F9.call(null,a):a instanceof $CLJS.M?$CLJS.mB(a):a},H9=function(a,b){return $CLJS.fX($CLJS.fX((0,$CLJS.OY)($CLJS.z9($CLJS.cA(a))),$CLJS.vQ,function(c){return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.SQ,$CLJS.N],null),c]))}),$CLJS.TF,function(c){var d=function(){var e=$CLJS.fF.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.qe(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Cd(m)){var t=$CLJS.lc(m),
u=$CLJS.D(t),v=$CLJS.te(u);a:for(var x=0;;)if(x<u){var A=$CLJS.md(t,x);v.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,A,null],null));x+=1}else{t=!0;break a}return t?$CLJS.we($CLJS.ye(v),l($CLJS.mc(m))):$CLJS.we($CLJS.ye(v),null)}v=$CLJS.z(m);return $CLJS.he(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,v,null],null),l($CLJS.Lc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.fF,lwa(d))})},I9=function(a){return $CLJS.kZ.h($CLJS.Zk.j($CLJS.bA(a,$CLJS.H([$CLJS.Di,!0])),0,$CLJS.Ch))},rwa=function(a){var b=
$CLJS.Xa(a)?a:null;return $CLJS.n(b)?(a=$CLJS.y9.h?$CLJS.y9.h(b):$CLJS.y9.call(null,b),$CLJS.E.g($CLJS.aL.h(a),$CLJS.vL)?(b=I9(b.field_ref),$CLJS.R.j(a,$CLJS.o1,$CLJS.kd(b))):a):a},J9=function(a,b){var c=null==a?null:$CLJS.z(a);c=null==c?null:$CLJS.E.g(c,$CLJS.lG);return $CLJS.n(c)?$CLJS.R.j(b,$CLJS.o1,$CLJS.kd(a)):b},K9=function(a){return function(b){var c=function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.y9.h?$CLJS.y9.h(e):$CLJS.y9.call(null,e)}(),d=function(){var e=$CLJS.X1.h(c);return $CLJS.n(e)?
I9(e):null}();return new $CLJS.h(null,3,[$CLJS.Yi,J9(d,c),$CLJS.i6,d,$CLJS.Ij,b.value],null)}};$CLJS.g=B9.prototype;$CLJS.g.P=function(a,b){return new B9(this.fg,this.Ii,this.tc,b)};$CLJS.g.O=function(){return this.ji};$CLJS.g.ki=$CLJS.Bc;$CLJS.g.vg=function(a,b){a=this.tc;a=null==a?null:$CLJS.r9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){return owa(this.tc,b)};
$CLJS.g.zg=function(a,b){a=this.tc;a=null==a?null:$CLJS.t9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ug=function(a,b){return nwa(this.tc,b)};$CLJS.g.tg=function(a,b){a=this.tc;a=null==a?null:$CLJS.fF.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.yg=function(a,b){return pwa(this.tc,b)};
$CLJS.g.rg=function(a,b){a=this.tc;a=null==a?null:$CLJS.q9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.xg=function(a,b){a=this.tc;a=null==a?null:$CLJS.s9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.sg=function(){var a=this.tc,b=this.fg;a=null==a?null:$CLJS.v9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};var L9=function L9(a){switch(arguments.length){case 1:return L9.h(arguments[0]);case 2:return L9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.extract_template_tags",L9);L9.h=function(a){return L9.g(a,$CLJS.N)};L9.g=function(a,b){return $CLJS.ql($CLJS.hva.l($CLJS.H([a,C9(b)])))};L9.A=2;
var M9=function M9(a){return $CLJS.pe(a)?[$CLJS.le(a),"/",$CLJS.jh(a)].join(""):$CLJS.zd(a)?$CLJS.Rt(a,M9):$CLJS.xd(a)?$CLJS.lf.g(M9,a):a},E9=$CLJS.lB(function(a){return $CLJS.db(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=$CLJS.mB(d);const e=d.length-1;d=0<=e&&d.indexOf("?",e)==e?["is-",$CLJS.TA(d,/\?$/,"")].join(""):d;d=$CLJS.UB.h?$CLJS.UB.h(d):$CLJS.UB.call(null,d);c=G9.h?G9.h(c):G9.call(null,c);b[d]=c;return b},{},a)}),F9=$CLJS.lB(function(a){return $CLJS.ze($CLJS.lf.g(G9,a))}),
N9=function N9(a){switch(arguments.length){case 1:return N9.h(arguments[0]);case 2:return N9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.order_by_clause",N9);N9.h=function(a){return N9.g(a,$CLJS.tG)};N9.g=function(a,b){return $CLJS.kva.l($CLJS.H([$CLJS.d9.l($CLJS.H([$CLJS.bA(a,$CLJS.H([$CLJS.Di,!0]))])),$CLJS.Ch.h(b)]))};N9.A=2;
var O9=function O9(a){switch(arguments.length){case 2:return O9.g(arguments[0],arguments[1]);case 3:return O9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.available_binning_strategies",O9);O9.g=function(a,b){return $CLJS.ze($CLJS.c9.l($CLJS.H([a,b])))};O9.j=function(a,b,c){return $CLJS.ze($CLJS.c9.l($CLJS.H([a,b,c])))};O9.A=3;
var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.available_temporal_buckets",P9);P9.g=function(a,b){return $CLJS.ze($CLJS.f9.l($CLJS.H([a,b])))};P9.j=function(a,b,c){return $CLJS.ze($CLJS.f9.l($CLJS.H([a,b,c])))};P9.A=3;
var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.query_EQ_",Q9);Q9.g=function(a,b){return Q9.j(a,b,null)};Q9.j=function(a,b,c){a=H9(a,c);b=H9(b,c);return $CLJS.E.g(a,b)};Q9.A=3;
var R9=function R9(a){switch(arguments.length){case 1:return R9.h(arguments[0]);case 2:return R9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.aggregation_clause",R9);R9.h=function(a){return $CLJS.a9.l($CLJS.H([a]))};R9.g=function(a,b){return $CLJS.a9.l($CLJS.H([a,b]))};R9.A=2;
var S9=function S9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return S9.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.Aa("metabase.lib.js.filter_clause",S9);S9.l=function(a,b,c){return $CLJS.Ve.v($CLJS.zua,a,b,c)};S9.A=2;S9.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};
var T9=function T9(a){switch(arguments.length){case 2:return T9.g(arguments[0],arguments[1]);case 3:return T9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.expressionable_columns",T9);T9.g=function(a,b){return T9.g(a,b)};T9.j=function(a,b,c){return $CLJS.ze($CLJS.jua.l($CLJS.H([a,b,c])))};T9.A=3;
var U9=function U9(a){switch(arguments.length){case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.with_different_database",U9);U9.j=function(a,b,c){return U9.v(a,b,c,null)};U9.v=function(a,b,c,d){return $CLJS.fva.l($CLJS.H([a,D9(b,c),$CLJS.bA(d,$CLJS.H([$CLJS.Di,!0]))]))};U9.A=4;
var swa=K9(function(a){return a.col}),twa=K9(function(a){return a.column}),V9=function V9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return V9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};$CLJS.Aa("metabase.lib.js.drill_thru",V9);V9.l=function(a,b,c,d){return $CLJS.Ve.N($CLJS.bua,a,b,c,d)};V9.A=3;
V9.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){var c=$CLJS.RW(a,b);return $CLJS.ze($CLJS.lf.g(function(d){return $CLJS.R.j(d,$CLJS.XZ,!0)},$CLJS.U0.j(a,b,c)))},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.b9.l($CLJS.H([a,b]));a=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=
$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.jZ,e=$CLJS.iZ;$CLJS.jZ=a;$CLJS.iZ=b;try{return $CLJS.aZ.h(c)}finally{$CLJS.iZ=e,$CLJS.jZ=d}},extract_template_tags:L9,engine:function(a){return $CLJS.jh($CLJS.ava.l($CLJS.H([a])))},field_id:function(a){return $CLJS.oua.l($CLJS.H([a]))},filter_operator:function(a,b,c){return $CLJS.Aua.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.Sta.l($CLJS.H([a]))},query:function(a,b,c){c=$CLJS.kZ.h((0,$CLJS.OY)($CLJS.k2($CLJS.U7(c),
$CLJS.rj,$CLJS.TF)));var d=$CLJS.ZA($CLJS.Ry);$CLJS.n($CLJS.YA("metabase.lib.js",d))&&$CLJS.XA("metabase.lib.js",d,$CLJS.GW("query map: %s",$CLJS.H([$CLJS.Ph.l($CLJS.H([c]))])),null);return $CLJS.mva.l($CLJS.H([D9(a,b),c]))},columns_group_columns:function(a){return $CLJS.ze($CLJS.Xta.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.Lua.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.mB(a):$CLJS.ze(a)},aggregate:function(a,b,c){return $CLJS.Ota.l($CLJS.H([a,b,$CLJS.bA(c,$CLJS.H([$CLJS.Di,!0]))]))},
legacy_card_or_table_id:function(a){return $CLJS.pua.l($CLJS.H([a]))},join_conditions:function(a){return $CLJS.ze($CLJS.Kua.l($CLJS.H([a])))},query_EQ_:Q9,temporal_bucket:function(a){return $CLJS.zva.l($CLJS.H([a]))},available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.b9.l($CLJS.H([a,b])),l=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),k);k=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(v,
x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),k);var m=$CLJS.jZ,t=$CLJS.iZ;$CLJS.jZ=l;$CLJS.iZ=k;try{var u=function(){var v=c.field_ref;return $CLJS.n(v)?I9(v):null}();return $CLJS.ze($CLJS.aua.l($CLJS.H([a,b,$CLJS.nl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Yi,J9(u,$CLJS.y9.h?$CLJS.y9.h(c):$CLJS.y9.call(null,c)),$CLJS.i6,u,$CLJS.Ij,void 0===d?null:null==d?$CLJS.Y2:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.h6,$CLJS.Yk.g(swa,
e)],null):null,$CLJS.n($CLJS.Ie(f))?new $CLJS.h(null,1,[$CLJS.TL,$CLJS.Yk.g(twa,f)],null):null]))])))}finally{$CLJS.iZ=t,$CLJS.jZ=m}},remove_clause:function(a,b,c){return $CLJS.pva.l($CLJS.H([a,b,$CLJS.d9.l($CLJS.H([$CLJS.bA(c,$CLJS.H([$CLJS.Di,!0]))]))]))},replace_clause:function(a,b,c,d){return $CLJS.sva.l($CLJS.H([a,b,$CLJS.d9.l($CLJS.H([$CLJS.bA(c,$CLJS.H([$CLJS.Di,!0]))])),$CLJS.d9.l($CLJS.H([$CLJS.bA(d,$CLJS.H([$CLJS.Di,!0]))]))]))},drop_stage:function(a){return $CLJS.vva.l($CLJS.H([a]))},join_lhs_display_name:function(a,
b,c,d){return $CLJS.Mua.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.nua.l($CLJS.H([a,b,c]))},pivot_types:function(a){return $CLJS.ze($CLJS.dua.l($CLJS.H([a])))},native_extras:function(a){return $CLJS.ql($CLJS.dva.l($CLJS.H([a])))},available_temporal_buckets:P9,with_binning:function(a,b){return $CLJS.Tta.l($CLJS.H([a,b]))},append_stage:function(a){return $CLJS.uva.l($CLJS.H([a]))},join:function(a,b,c){return $CLJS.Eua.l($CLJS.H([a,b,c]))},aggregations:function(a,b){return $CLJS.ze($CLJS.b9.l($CLJS.H([a,
b])))},drill_thru:V9,raw_native_query:function(a){return $CLJS.Yua.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.ze($CLJS.yua.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.Ava.l($CLJS.H([a,b]))},with_join_conditions:function(a,b){return $CLJS.Tua.l($CLJS.H([a,$CLJS.bA(b,$CLJS.H([$CLJS.Di,!0]))]))},available_metrics:function(a){return $CLJS.ze($CLJS.Wua.l($CLJS.H([a])))},with_different_database:U9,with_fields:function(a,b,c){return $CLJS.uua.l($CLJS.H([a,
b,c]))},is_column_metadata:function(a){return $CLJS.zd(a)&&$CLJS.E.g($CLJS.ZK,$CLJS.Jj.h(a))},join_clause:function(a,b){return $CLJS.Fua.l($CLJS.H([a,b]))},aggregation_clause:R9,external_op:function(a){a=$CLJS.Zta.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.JE);var c=$CLJS.J.g(b,$CLJS.kj);b=$CLJS.J.g(b,$CLJS.mt);return{operator:a,options:$CLJS.ql(c),args:$CLJS.ze(b)}},with_join_fields:function(a,b){return $CLJS.Rua.l($CLJS.H([a,"string"===typeof b?$CLJS.Ch.h(b):b]))},find_visible_column_for_legacy_ref:function(a,
b,c){return $CLJS.sua.l($CLJS.H([a,b,c]))},visible_columns:function(a,b){var c=$CLJS.RW(a,b),d=$CLJS.W0.j(a,b,c);c=$CLJS.U0.j(a,b,c);return $CLJS.ze($CLJS.I3(a,b,d,c))},available_binning_strategies:O9,find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.lG.h($CLJS.RW(a,b)),f=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null)}),e);e=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(u,
v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null)}),e);var k=$CLJS.jZ,l=$CLJS.iZ;$CLJS.jZ=f;$CLJS.iZ=e;try{var m=$CLJS.Yk.g(rwa,c),t=$CLJS.lf.g(I9,d);return $CLJS.ze(mwa(a,b,t,m))}finally{$CLJS.iZ=l,$CLJS.jZ=k}},expression:function(a,b,c,d){return $CLJS.gua.l($CLJS.H([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.Ch.h(a):a;b="string"===typeof b?$CLJS.Ch.h(b):b;return $CLJS.yva.l($CLJS.H([a,
b]))},order_bys:function(a,b){return $CLJS.ze($CLJS.lva.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.j0($CLJS.Ch.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),c,function(d){return G9($CLJS.Uua.l($CLJS.H([$CLJS.a4(a,b),b,d])))})},fieldable_columns:function(a,b){return $CLJS.ze($CLJS.qua.l($CLJS.H([a,b])))},template_tags:function(a){return $CLJS.ql($CLJS.$ua.l($CLJS.H([a])))},selected_aggregation_operators:function(a,b){return $CLJS.ze($CLJS.Rta.l($CLJS.H([$CLJS.y(a),b])))},
order_by_clause:N9,filterable_columns:function(a,b){return $CLJS.ze($CLJS.xua.l($CLJS.H([a,b])))},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.ze($CLJS.Iua.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.Jua.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.ze($CLJS.wua.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.ze($CLJS.Gua.l($CLJS.H([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.O1(a,
b)},with_expression_name:function(a,b){return $CLJS.kua.l($CLJS.H([a,b]))},orderable_columns:function(a,b){return $CLJS.ze($CLJS.Q7.g(a,b))},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Bua.l($CLJS.H([a,b,$CLJS.bA(c,$CLJS.H([$CLJS.Di,!0]))]))},suggestedName:function(a){return $CLJS.Vua.l($CLJS.H([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.eua.l($CLJS.H([a,b,c,d]))},has_write_permission:function(a){return $CLJS.gva.l($CLJS.H([a]))},metadataProvider:D9,filter_clause:S9,
fields:function(a,b){return $CLJS.ze($CLJS.rua.l($CLJS.H([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.ze($CLJS.Qta.l($CLJS.H([a,b])))},expression_name:function(a){return $CLJS.hua.l($CLJS.H([a]))},breakouts:function(a,b){return $CLJS.ze($CLJS.Wta.l($CLJS.H([a,b])))},joinable_columns:function(a,b,c){return $CLJS.ze($CLJS.Oua.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.ze($CLJS.Yta.l($CLJS.H([a])))},describe_temporal_unit:function(a,b){b="string"===typeof b?
$CLJS.Ch.h(b):b;return $CLJS.wva.l($CLJS.H([a,b]))},joins:function(a,b){return $CLJS.ze($CLJS.Pua.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.Xua.l($CLJS.H([D9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.ze($CLJS.Dua.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.ql.l(M9($CLJS.aZ.h(a)),$CLJS.H([$CLJS.Bk,$CLJS.mB]))},stage_count:function(a){return $CLJS.nva.l($CLJS.H([a]))},expression_clause:function(a,b,c){return $CLJS.lua.l($CLJS.H([$CLJS.Ch.h(a),
b,$CLJS.bA(c,$CLJS.H([$CLJS.Di,!0]))]))},find_matching_column:function(a,b,c,d){return $CLJS.fua.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.eva.l($CLJS.H([a,$CLJS.bA(b,$CLJS.H([$CLJS.Di,!0]))]))},column_filter_drill_details:function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.TF);var c=$CLJS.J.g(b,$CLJS.Yi);b=$CLJS.J.g(b,$CLJS.QL);return{column:c,query:a,stageNumber:b}},join_strategy:function(a){return $CLJS.Nua.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.Jj.h(b);
switch(c instanceof $CLJS.M?c.T:null){case "metadata/table":return{databaseId:$CLJS.gQ.h(a),tableId:$CLJS.dj.h(b)};case "metadata/card":return{databaseId:$CLJS.gQ.h(a),tableId:["card__",$CLJS.p.h($CLJS.dj.h(b))].join(""),cardId:$CLJS.dj.h(b),isModel:$CLJS.OZ.h(b)};default:return a=$CLJS.ZA($CLJS.Uy),$CLJS.n($CLJS.YA("metabase.lib.js",a))&&$CLJS.XA("metabase.lib.js",a,$CLJS.Qw.l($CLJS.H(["Cannot provide picker-info for",$CLJS.Jj.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.mua.l($CLJS.H([a,
b,c]));return $CLJS.GV(function(d){if($CLJS.zd(d)&&$CLJS.E.g($CLJS.D8,$CLJS.Jj.h(d))){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.JE);var f=$CLJS.J.g(e,$CLJS.kj);e=$CLJS.J.g(e,$CLJS.mt);return{operator:$CLJS.jh(d),options:$CLJS.ql($CLJS.km(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EG,$CLJS.mk],null))),args:$CLJS.ze($CLJS.lf.g(function(k){return k instanceof $CLJS.M?$CLJS.mB(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,b,c){b=$CLJS.b9.l($CLJS.H([a,b]));a=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(f,
k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.hg.j($CLJS.N,$CLJS.mm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.NE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.jZ,e=$CLJS.iZ;$CLJS.jZ=a;$CLJS.iZ=b;try{return $CLJS.kZ.h(c)}finally{$CLJS.iZ=e,$CLJS.jZ=d}},format_relative_date_range:function(a,b,c,d,e){b=$CLJS.Ch.h(b);d=null==d?null:$CLJS.Ch.h(d);e=
$CLJS.bA(e,$CLJS.H([$CLJS.Di,!0]));return $CLJS.M2((0,$CLJS.ZI)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.ze($CLJS.Pta.l($CLJS.H([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.Cua.l($CLJS.H([a,b,c]))},available_segments:function(a,b){return $CLJS.ze($CLJS.tva.l($CLJS.H([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.ze($CLJS.Hua.l($CLJS.H([a,b,c,d])))},expressionable_columns:T9,filter:function(a,b,c){return $CLJS.vua.l($CLJS.H([a,
b,$CLJS.bA(c,$CLJS.H([$CLJS.Di,!0]))]))},change_direction:function(a,b){return $CLJS.iva.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.cua.l($CLJS.H([a,b]))},legacy_field_ref:function(a){return $CLJS.ql($CLJS.Zk.v($CLJS.aZ.h($CLJS.e9.l($CLJS.H([a]))),2,$CLJS.Rt,function(b){return $CLJS.pe(b)?$CLJS.mB(b):b}))},remove_field:function(a,b,c){return $CLJS.tua.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.bva.l($CLJS.H([a,C9(b)]))},expressions:function(a,b){return $CLJS.ze($CLJS.iua.l($CLJS.H([a,
b])))},suggested_join_conditions:function(a,b,c){return $CLJS.ze($CLJS.Qua.l($CLJS.H([a,b,c])))},with_join_strategy:function(a,b){return $CLJS.Sua.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.jva.l($CLJS.H([a,b,c,$CLJS.Ch.h(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.Ch.h(a):a;b="string"===typeof b?$CLJS.Ch.h(b):b;return $CLJS.xva.l($CLJS.H([a,b]))},breakout:function(a,b,c){return $CLJS.Uta.l($CLJS.H([a,b,$CLJS.e9.l($CLJS.H([c]))]))},database_id:function(a){return $CLJS.$ta.l($CLJS.H([a]))},
required_native_extras:function(a,b){return $CLJS.ze($CLJS.lf.g($CLJS.mB,$CLJS.cva.l($CLJS.H([D9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.qva.l($CLJS.H([a,b,c]))},joined_thing:function(a,b){return $CLJS.V3(a,b)},with_native_query:function(a,b){return $CLJS.Zua.l($CLJS.H([a,b]))},breakoutable_columns:function(a,b){return $CLJS.ze($CLJS.Vta.l($CLJS.H([a,b])))},rename_join:function(a,b,c,d){return $CLJS.rva.l($CLJS.H([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.ova.l($CLJS.H([a,
b]))}};