var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var JV;$CLJS.EV=function(a){return a instanceof Error?a.message:null};
$CLJS.FV=function(a,b,c){if($CLJS.ce(c)){var d=$CLJS.Ve.g($CLJS.V,$CLJS.lf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.Cf(c)?(d=new $CLJS.ag(function(){var e=$CLJS.Fb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Gb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Fd(c)?(d=$CLJS.ph($CLJS.lf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.Ad(c)?(d=$CLJS.db(function(e,f){return $CLJS.ee.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.vd(c)?
(d=$CLJS.hg.g($CLJS.ld(c),$CLJS.lf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.HV=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ch.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return $CLJS.GV(function(c){return $CLJS.zd(c)?$CLJS.hg.g($CLJS.N,$CLJS.lf.g(b,c)):c},a)};
$CLJS.IV=function(a){var b=new $CLJS.Fa;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.z(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.KV=function(a,b,c){a=$CLJS.TA(a,/''/,"'");var d=$CLJS.gu(a,JV);d=$CLJS.E.g($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.rh(/.*\{0\}.*/,a);return $CLJS.Mt.ngettext($CLJS.Mt.msgid($CLJS.ql(d),$CLJS.n(a)?c:""),$CLJS.TA($CLJS.TA(b,/''/,"'"),JV,$CLJS.p.h(c)),c)};
$CLJS.LV=function(a,b){return $CLJS.db(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Kd(a,e)?$CLJS.R.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.Ve.j($CLJS.Nk,a,$CLJS.$g(b)),b)};$CLJS.MV=function(a,b,c){return $CLJS.zd(c)?$CLJS.mf(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.ee.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.H([c])):$CLJS.xd(c)?$CLJS.mf($CLJS.Ye(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.ee.g(b,$CLJS.z(c)):b),$CLJS.H([c])):null};
$CLJS.GV=function GV(a,b){return $CLJS.FV($CLJS.Ye(GV,a),a,b)};JV=/\{0\}/;$CLJS.NV=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.OV=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);$CLJS.PV=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.QV=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var yka=$CLJS.Lg([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tJ,$CLJS.mJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$I,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xJ,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.tJ,$CLJS.dJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tJ,$CLJS.rJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fJ,null],null)],[$CLJS.Ei,$CLJS.nH,$CLJS.EJ,$CLJS.jJ,$CLJS.Ai,$CLJS.Lj,$CLJS.nj,$CLJS.rk,$CLJS.Li,$CLJS.jJ,$CLJS.FJ,$CLJS.kH]),SV;$CLJS.ih($CLJS.lf.g($CLJS.z,$CLJS.$g(yka)));var RV,zka=$CLJS.$e($CLJS.N),Aka=$CLJS.$e($CLJS.N),Bka=$CLJS.$e($CLJS.N),Cka=$CLJS.$e($CLJS.N),Dka=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
RV=new $CLJS.hi($CLJS.Ih.g("metabase.mbql.util","negate*"),$CLJS.z,Dka,zka,Aka,Bka,Cka);RV.m(null,$CLJS.Js,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});RV.m(null,$CLJS.Os,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Hs],null),$CLJS.lf.g(RV,a))});RV.m(null,$CLJS.Hs,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Os],null),$CLJS.lf.g(RV,a))});
RV.m(null,$CLJS.yk,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,b,a],null)});RV.m(null,$CLJS.OG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yk,b,a],null)});RV.m(null,$CLJS.ds,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,b,a],null)});
RV.m(null,$CLJS.hs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fs,b,a],null)});RV.m(null,$CLJS.fs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hs,b,a],null)});RV.m(null,$CLJS.js,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,b,a],null)});
RV.m(null,$CLJS.ZF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hs,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,b,a],null)],null)});RV.m(null,$CLJS.vG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Js,a],null)});RV.m(null,$CLJS.nG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Js,a],null)});
RV.m(null,$CLJS.HG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Js,a],null)});
SV=function SV(a){return $CLJS.zd(a)?$CLJS.Ie($CLJS.hg.g($CLJS.N,function(){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=SV.h?SV.h(u):SV.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}k=$CLJS.z(f);
m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=SV.h?SV.h(k):SV.call(null,k);if(null!=k)return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[m,k],null),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(a)}())):$CLJS.xd(a)?$CLJS.Ie($CLJS.hg.g($CLJS.ld(a),$CLJS.of($CLJS.Wa,$CLJS.lf.g(SV,a)))):a};
$CLJS.TV=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.w(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),k=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.Ie(SV($CLJS.Ve.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,d],null):$CLJS.E.g(f,$CLJS.wG)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();