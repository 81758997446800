var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var NL,PL,RL,UL,ZL;$CLJS.KL=function(a){return $CLJS.Zh($CLJS.q($CLJS.uC),a,$CLJS.AG)};$CLJS.LL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.i6=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.ML=new $CLJS.M(null,"object-id","object-id",-754527291);NL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.OL=new $CLJS.M(null,"operators","operators",-2064102509);
PL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.QL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);RL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.SL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.TL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
UL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.VL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.WL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.XL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.YL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
ZL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.$L=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.l7=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);$CLJS.X(PL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.LE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.tE],null)],null)],null));$CLJS.X(ZL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,PL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GB,$CLJS.PE],null)],null)],null));$CLJS.X(RL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.wG],null),ZL,$CLJS.GE],null));
$CLJS.X(UL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.wG],null),PL,$CLJS.tL],null));
$CLJS.XG.g($CLJS.wG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.wG],null),PL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.tL,$CLJS.GE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ti,function(a){return $CLJS.xd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.sC(a)):null},$CLJS.Ht,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.yB,UL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xB,RL],null)],null)],null));$CLJS.EF($CLJS.wG,$CLJS.AG);$CLJS.$E.m(null,$CLJS.wG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.PA($CLJS.Ki,$CLJS.GB)(b);return $CLJS.n(a)?a:$CLJS.mF});$CLJS.LF($CLJS.DB,$CLJS.H([$CLJS.GE]));$CLJS.$E.m(null,$CLJS.DB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.PA($CLJS.Ki,$CLJS.GB)(b);return $CLJS.n(a)?a:$CLJS.mF});
$CLJS.EF($CLJS.DB,$CLJS.AG);$CLJS.X(NL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.LE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.GE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.GE],null)],null)],null));$CLJS.XG.g($CLJS.lG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.lG],null),NL,$CLJS.ek],null));
$CLJS.$E.m(null,$CLJS.lG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.PA($CLJS.Ki,$CLJS.GB)(b);return $CLJS.n(a)?a:$CLJS.mF});$CLJS.EF($CLJS.lG,$CLJS.AG);$CLJS.LF($CLJS.VF,$CLJS.H([$CLJS.At,$CLJS.Qj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bL],null)],null)]));$CLJS.EF($CLJS.VF,$CLJS.AG);
$CLJS.LF($CLJS.rG,$CLJS.H([$CLJS.At,$CLJS.mF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$K],null)],null)]));$CLJS.EF($CLJS.rG,$CLJS.AG);
$CLJS.X($CLJS.AG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,$CLJS.bF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Jt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.St(", ",$CLJS.iH($CLJS.Md,$CLJS.rl.g($CLJS.q($CLJS.uC),$CLJS.AG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.KL(a)}],null)],null));