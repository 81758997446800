var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Yoa,Zoa,$oa,apa,L3,M3,N3,O3,bpa,cpa,dpa,epa,fpa,gpa,hpa,ipa,jpa,Y3,Z3,kpa,lpa,mpa,X3,W3,npa,$3;$CLJS.G3=function(a,b){$CLJS.db(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Yoa=function(a){return function(b){var c=$CLJS.cf(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Ml(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Zoa=function(a,b){return function f(d,e){return new $CLJS.qe(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Kd(k,t))l=$CLJS.Lc(m);else{k=$CLJS.he(l,f($CLJS.Lc(m),$CLJS.ee.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.eh)};
$oa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Va($CLJS.y($CLJS.QA(c,d)))?c:$CLJS.Ve.N($CLJS.IX,c,d,e,b)};$CLJS.H3=function(a,b){return $CLJS.e1.g(a,b instanceof $CLJS.M?b:$CLJS.wi.h(b))};
$CLJS.I3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.Yk.g($CLJS.CW,d);var e=$CLJS.hg.j($CLJS.eh,$CLJS.lf.h(function(f){return $CLJS.x2.v(a,b,f,c)}),d);return $CLJS.Yk.g(function(f){return $CLJS.R.j(f,$CLJS.XZ,$CLJS.Kd(e,f))},c)}return null};$CLJS.J3=function(a){return $CLJS.E.g($CLJS.sC(a),$CLJS.xL)};apa=function(a,b){return $CLJS.n($CLJS.fF.h(a))?$CLJS.Zk.j(a,$CLJS.fF,function(c){return $CLJS.xd(c)?$CLJS.Yk.g(function(d){return $CLJS.K3.g?$CLJS.K3.g(d,b):$CLJS.K3.call(null,d,b)},c):c}):a};
L3=function(a){if($CLJS.n(a)){var b=$CLJS.of;a:try{if($CLJS.Bd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.Bd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ke(d,$CLJS.wG))try{var e=$CLJS.F(a,3);if($CLJS.Bd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ke(f,$CLJS.wG)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Wa,k)))}else a=null;return a};M3=function(a){if($CLJS.n(L3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};N3=function(a){return $CLJS.n(L3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
O3=function(a,b){var c=$CLJS.K3;if($CLJS.Va(L3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Ve.j(c,a,b)],null)};
bpa=function(a,b,c){return $CLJS.ud($CLJS.FL.h(a))?a:$CLJS.n(b)?$oa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.FL],null),function(d){return function l(f,k){try{if($CLJS.Bd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ke(m,$CLJS.wG))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.Bc===t.mf||(t.C?0:$CLJS.Za($CLJS.wb,t)):$CLJS.Za($CLJS.wb,t))try{var u=$CLJS.J.j(t,$CLJS.GP,$CLJS.PV);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.K3.g?$CLJS.K3.g(k,c):$CLJS.K3.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.kX(l,f,k);throw v;}throw x;}}($CLJS.Ff,d)}):$CLJS.Zk.j(a,$CLJS.FL,function(d){return $CLJS.Yk.g(function(e){return O3(e,$CLJS.H([c]))},d)})};
$CLJS.K3=function(a,b){var c=$CLJS.sC(a);switch(c instanceof $CLJS.M?c.T:null){case "field":return $CLJS.VE(a,$CLJS.TE,$CLJS.H([$CLJS.GP,b]));case "metadata/column":return $CLJS.TE(a,$CLJS.j_,b);case "mbql/join":return c=$CLJS.P1(a),bpa(apa($CLJS.TE(a,$CLJS.jF,b),b),c,b);default:return b=$CLJS.ZA($CLJS.Sy),$CLJS.n($CLJS.YA("metabase.lib.join",b))&&$CLJS.XA("metabase.lib.join",b,$CLJS.Qw.l($CLJS.H(["with-join-value should not be called with",$CLJS.Ph.l($CLJS.H([a]))])),null),a}};
$CLJS.P3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.PA($CLJS.JZ,$CLJS.T)(c);a=$CLJS.Q1($CLJS.jF.h(a),$CLJS.PA($CLJS.JZ,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.JZ,e,$CLJS.H([$CLJS.WZ,b]))};cpa=function(a,b){return $CLJS.Va(b)?a:$CLJS.Yk.g(function(c){var d=N3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.P1(d))?null:O3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.Q3=function(a,b){b=cpa($CLJS.Yk.g($CLJS.DW,b),$CLJS.P1(a));return $CLJS.TE(a,$CLJS.FL,$CLJS.Ie(b))};
$CLJS.R3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.Ff)?$CLJS.uA:$CLJS.Ie($CLJS.hg.j($CLJS.Ff,$CLJS.Wk.g($CLJS.lf.h($CLJS.CW),function(){var c=$CLJS.P1(a);return $CLJS.n(c)?$CLJS.lf.h(function(d){return $CLJS.K3(d,c)}):$CLJS.Xd}()),b));return $CLJS.TE(a,$CLJS.fF,b)};
dpa=function(a,b){b=$CLJS.im(function(d){return $CLJS.x2.g(d,a)},b);var c=$CLJS.JH($CLJS.PA($CLJS.N_,$CLJS.O_),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.JH($CLJS.N_,a);if($CLJS.n(b))return b;b=$CLJS.JH($CLJS.O_,a);return $CLJS.n(b)?b:$CLJS.z(a)};epa=function(a){return"string"===typeof a?(0,$CLJS.na)($CLJS.TA(a,/ id$/i,"")):null};
fpa=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Mh(function(){return $CLJS.R2(b)});var d=new $CLJS.Mh(function(){return $CLJS.R2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
gpa=function(a,b,c){var d=$CLJS.l0.g($CLJS.E.g($CLJS.Jj.h(b),$CLJS.tW)?b:a,b),e=$CLJS.n(c)?epa($CLJS.l0.g(a,c)):null,f=fpa(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Va($CLJS.qh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
hpa=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.TY(l,$CLJS.wG)&&$CLJS.Gd($CLJS.x2.v(a,b,l,e)))return $CLJS.K3(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.kX(m,k,l);throw t;}throw u;}}($CLJS.Ff,c)};
ipa=function(a,b,c,d,e,f){c=hpa(a,b,c,d,f);return function t(l,m){try{if($CLJS.Bd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.TY(u,$CLJS.wG))try{var v=$CLJS.F(m,3);if($CLJS.TY(v,$CLJS.wG)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.P1(A),S=$CLJS.P1(x);if($CLJS.Va($CLJS.n(K)?K:S))return $CLJS.n($CLJS.x2.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.K3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.K3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var Y=
$CLJS.VE(A,$CLJS.Nk,$CLJS.H([$CLJS.GP])),ca=$CLJS.VE(x,$CLJS.Nk,$CLJS.H([$CLJS.GP])),ja=null==$CLJS.x2.v(a,b,Y,e);var va=ja?$CLJS.x2.v(a,b,ca,e):ja;return $CLJS.n(va)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,ca],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,Y,x],null)}return m}throw $CLJS.Z;}catch(gb){if(gb instanceof Error){var Qa=gb;if(Qa===$CLJS.Z)throw $CLJS.Z;throw Qa;}throw gb;}else throw $CLJS.Z;}catch(gb){if(gb instanceof Error){Qa=gb;if(Qa===$CLJS.Z)throw $CLJS.Z;throw Qa;}throw gb;}else throw $CLJS.Z;
}catch(gb){if(gb instanceof Error){Qa=gb;if(Qa===$CLJS.Z)return $CLJS.kX(t,l,m);throw Qa;}throw gb;}}($CLJS.Ff,c)};jpa=function(a,b){var c=$CLJS.UW();$CLJS.G3(c,b);return c(a)};
$CLJS.S3=function(a,b,c){if($CLJS.Kd(c,$CLJS.jF))return c;var d=$CLJS.RW(a,b),e=$CLJS.W0.j(a,b,d),f=$CLJS.y($CLJS.of($CLJS.Wa,function v(t,u){try{if($CLJS.Bd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.cl.j(u,0,1);if($CLJS.Bd(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ke(A,$CLJS.wG))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.MV(v,t,u);throw C;}throw G;}}($CLJS.Ff,$CLJS.FL.h(c))));f=dpa(e,f);var k=jpa(gpa(a,c,f),$CLJS.im($CLJS.jF,$CLJS.QN.h(d))),l=$CLJS.U0.h($CLJS.J1.g(a,$CLJS.CL.h(c)));return $CLJS.K3($CLJS.Zk.j(c,$CLJS.FL,function(t){return $CLJS.Yk.g(function(u){return ipa(a,b,u,k,e,l)},t)}),k)};$CLJS.T3=function(a){return $CLJS.FL.h(a)};
$CLJS.U3=function(a){return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.GL,$CLJS.RE,a],null),$CLJS.E.g(a,$CLJS.oF)?new $CLJS.h(null,1,[$CLJS.gi,!0],null):null]))};$CLJS.V3=function(a,b){b=$CLJS.z($CLJS.CL.h(b));return $CLJS.n($CLJS.$V.h(b))?$CLJS.h0(a,$CLJS.$V.h(b)):$CLJS.n($CLJS.rP.h(b))?$CLJS.f0(a,$CLJS.rP.h(b)):null};
Y3=function(a){a=$CLJS.IH(function(d){return $CLJS.O_(d)?W3:$CLJS.N_(d)?X3:$CLJS.ku},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,W3);var c=$CLJS.J.g(b,X3);b=$CLJS.J.g(b,$CLJS.ku);return $CLJS.kf.l(a,c,$CLJS.H([b]))};Z3=function(a,b,c,d){return $CLJS.Va(c)?d:$CLJS.Yk.g(function(e){return $CLJS.n($CLJS.XZ.h(e))?$CLJS.H3(e,$CLJS.P2(c)):e},$CLJS.I3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
kpa=function(a,b,c,d){var e=new $CLJS.Mh(function(){return $CLJS.W0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.A0,!1,$CLJS.I0,!1],null))});return $CLJS.Ie($CLJS.hg.j($CLJS.Ff,$CLJS.cm(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.lL);return $CLJS.n(function(){var m=$CLJS.N_(k);return m?l:m}())?(f=$CLJS.JH(function(m){return $CLJS.E.g(l,$CLJS.dj.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,$3,f):null):null}),$CLJS.W0.j(a,b,c)))};lpa={};
mpa=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);X3=new $CLJS.M(null,"fk","fk",398442651);W3=new $CLJS.M(null,"pk","pk",-771936732);npa=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);$3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.L0.m(null,$CLJS.xL,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.CL);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.rP);b=$CLJS.J.g(b,$CLJS.$V);c=$CLJS.n(c)?$CLJS.BE.h($CLJS.f0(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.h0(a,b),a=$CLJS.n(c)?$CLJS.l0.j(a,0,c):$CLJS.U1(b)):a=null;return $CLJS.n(a)?a:$CLJS.YE("Native Query")});$CLJS.S0.m(null,$CLJS.xL,function(a,b,c){a=$CLJS.l0.j(a,b,c);c=$CLJS.jF.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.BE,a],null)});
$CLJS.R0.m(null,$CLJS.xL,function(){throw $CLJS.li("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.L0.m(null,$CLJS.GL,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.RE);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.YE("Left outer join");case "right-join":return $CLJS.YE("Right outer join");case "inner-join":return $CLJS.YE("Inner join");case "full-join":return $CLJS.YE("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.S0.m(null,$CLJS.GL,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.RE);c=$CLJS.J.g(d,$CLJS.gi);a=new $CLJS.h(null,2,[$CLJS.J0,$CLJS.mB(e),$CLJS.BE,$CLJS.l0.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.gi,!0):a});
$CLJS.T0.m(null,$CLJS.xL,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.jF),k=$CLJS.J.j(e,$CLJS.fF,$CLJS.uA),l=$CLJS.J.g(e,$CLJS.CL);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.G0);if($CLJS.E.g(k,$CLJS.uA))return null;var t="undefined"!==typeof $CLJS.sM&&"undefined"!==typeof lpa&&"undefined"!==typeof $CLJS.opa&&"undefined"!==typeof $CLJS.a4?new $CLJS.Gc(function(){return $CLJS.a4},$CLJS.qd(npa,new $CLJS.h(null,1,[$CLJS.gk,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.CL,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.My)?$CLJS.U0.v(u,-1,$CLJS.sd(l),c):function(){return function A(x){return new $CLJS.qe(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.Cd(C)){var G=$CLJS.lc(C),K=$CLJS.D(G),S=$CLJS.te(K);a:for(var Y=0;;)if(Y<K){var ca=$CLJS.md(G,Y);ca=$CLJS.VE(ca,$CLJS.Nk,$CLJS.H([$CLJS.GP]));ca=$CLJS.k0.j(u,-1,ca);S.add(ca);Y+=1}else{G=!0;break a}return G?$CLJS.we($CLJS.ye(S),A($CLJS.mc(C))):$CLJS.we($CLJS.ye(S),null)}S=$CLJS.z(C);S=$CLJS.VE(S,$CLJS.Nk,
$CLJS.H([$CLJS.GP]));return $CLJS.he($CLJS.k0.j(u,-1,S),A($CLJS.Lc(C)))}return null}},null,null)}(k)}();return $CLJS.Yk.g(function(v){v=$CLJS.R.j(v,$CLJS.h_,f);v=$CLJS.K3($CLJS.R.l(v,$CLJS.BE,$CLJS.l0.j(a,b,v),$CLJS.H([$CLJS.aL,$CLJS.TZ])),f);return $CLJS.P3(e,m,v)},c)});$CLJS.V0.m(null,$CLJS.xL,function(a,b,c,d){return $CLJS.U0.v(a,b,$CLJS.R.j(c,$CLJS.fF,$CLJS.My),d)});
var b4,ppa=$CLJS.$e($CLJS.N),qpa=$CLJS.$e($CLJS.N),rpa=$CLJS.$e($CLJS.N),spa=$CLJS.$e($CLJS.N),tpa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));b4=new $CLJS.hi($CLJS.Ih.g("metabase.lib.join","join-clause-method"),$CLJS.sC,tpa,ppa,qpa,rpa,spa);b4.m(null,$CLJS.xL,function(a){return a});b4.m(null,$CLJS.tW,function(a){return $CLJS.BW(new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.xL,$CLJS.CL,$CLJS.CL.h($CLJS.PW(a))],null))});
b4.m(null,$CLJS.gW,function(a){return $CLJS.BW(new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.xL,$CLJS.CL,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});b4.m(null,$CLJS.uL,function(a){return $CLJS.BW(new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.xL,$CLJS.CL,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.$V,$CLJS.dj.h(a),$CLJS.Jj,$CLJS.gW],null)],null)],null))});
b4.m(null,$CLJS.fL,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.j_);a=$CLJS.J.g(b,mpa);b=b4.h(new $CLJS.h(null,3,[$CLJS.Jj,$CLJS.gW,$CLJS.HB,new $CLJS.h(null,1,[$CLJS.NE,$CLJS.p.h($CLJS.XE())],null),$CLJS.rP,$CLJS.dj.h(b)],null));c=$CLJS.n(c)?$CLJS.K3(b,c):b;return $CLJS.n(a)?$CLJS.R3.g?$CLJS.R3.g(c,a):$CLJS.R3.call(null,c,a):c});
$CLJS.c4=function(){function a(d,e){return $CLJS.Q3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.k2(b4.h(d),$CLJS.fF,$CLJS.My)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.upa=function(){function a(d,e,f){var k=$CLJS.c4.h(f);f=$CLJS.ud($CLJS.T3.h?$CLJS.T3.h(k):$CLJS.T3.call(null,k))?function(){var m=$CLJS.V3.g?$CLJS.V3.g(d,k):$CLJS.V3.call(null,d,k);return $CLJS.d4.j?$CLJS.d4.j(d,e,m):$CLJS.d4.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.Q3(k,f):k;var l=$CLJS.S3(d,e,f);return $CLJS.bX.l(d,e,$CLJS.Zk,$CLJS.H([$CLJS.QN,function(m){return $CLJS.ee.g($CLJS.Gf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.e4=function(){function a(d,e){return $CLJS.Ie($CLJS.J.g($CLJS.RW(d,e),$CLJS.QN))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.vpa=function(){function a(d){d=$CLJS.F1($CLJS.e0(d));d=$CLJS.vA.h(d);return $CLJS.hg.j($CLJS.Ff,$CLJS.Wk.g($CLJS.nf($CLJS.Ye($CLJS.Kd,d)),$CLJS.lf.h($CLJS.U3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.oF,$CLJS.eF,$CLJS.sF,$CLJS.vF],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.wpa=function(){function a(d,e,f,k){var l=$CLJS.J3(f)?$CLJS.P1(f):null,m=$CLJS.hg.j($CLJS.eh,$CLJS.Wk.g($CLJS.lf.h($CLJS.P1),Yoa(function(t){return $CLJS.Tk.g(t,l)})),$CLJS.e4.g(d,e));f=$CLJS.n(k)?k:$CLJS.J3(f)?M3($CLJS.z($CLJS.T3(f))):null;return Y3(Z3(d,e,f,$CLJS.Xk.g(function(t){t=$CLJS.P1(t);return $CLJS.n(t)?$CLJS.Kd(m,t):null},$CLJS.W0.v(d,e,$CLJS.RW(d,e),new $CLJS.h(null,1,[$CLJS.A0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.xpa=function(){function a(d,e,f,k,l){k=$CLJS.J3(f)?$CLJS.V3(d,f):f;var m=$CLJS.J3(f)?$CLJS.P1(f):null;f=$CLJS.n(l)?l:$CLJS.J3(f)?N3($CLJS.z($CLJS.T3(f))):null;f=$CLJS.n(f)?$CLJS.Va(m)?$CLJS.VE(f,$CLJS.Nk,$CLJS.H([$CLJS.GP])):f:null;return Y3(Z3(d,e,f,$CLJS.lf.g(function(t){t=$CLJS.R.j(t,$CLJS.aL,$CLJS.TZ);return $CLJS.n(m)?$CLJS.K3(t,m):t},$CLJS.W0.v(d,e,k,new $CLJS.h(null,1,[$CLJS.A0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.ypa=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.C2}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.C2};return b}();
$CLJS.d4=function(){function a(d,e,f){function k(u,v){u=$CLJS.Nk.g(u,$CLJS.W1);v=$CLJS.Nk.g(v,$CLJS.W1);return $CLJS.F3.l($CLJS.A2.h($CLJS.yk),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Ie(Zoa(function(x){return $CLJS.dj.h($3.h(x))},kpa(d,e,u,v)))}var m=$CLJS.RW(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.Yk.g(function(v){return k(v,$3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.Yk.g(function(u){return k($3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.zpa=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.J3(k)?M3($CLJS.z($CLJS.T3(k))):null;$CLJS.n(l)?(l=$CLJS.o0.j(e,f,l),l=$CLJS.QA(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$W,$CLJS.BE],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.QW(e,f)){l=$CLJS.e4.g(e,f);var m=$CLJS.ud(l);k=m?m:$CLJS.J3(k)?$CLJS.E.g($CLJS.jF.h(k),$CLJS.jF.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.$_(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.$_(e),k=$CLJS.f0(e,k),e=$CLJS.l0.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.YE("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Apa=function(){function a(d,e,f,k){f=$CLJS.DW.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.wi.h(k);e=$CLJS.QW(d,e);l=$CLJS.i1.j(d,e,l);d=$CLJS.i1.j(d,e,m);m=null==k||$CLJS.Kd($CLJS.ih($CLJS.lf.g($CLJS.wi,l)),k);d=null==k||$CLJS.Kd($CLJS.ih($CLJS.lf.g($CLJS.wi,d)),k);f=m?$CLJS.Zk.v(f,2,$CLJS.H3,k):f;return d?$CLJS.Zk.v(f,3,$CLJS.H3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.O0.m(null,$CLJS.QN,function(a,b){var c=$CLJS.Ie($CLJS.e4.g(a,b));c=null==c?null:$CLJS.lf.g(function(d){return $CLJS.l0.j(a,b,d)},c);return null==c?null:$CLJS.St(" + ",c)});