var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var zF,IF,FF,Zea;zF=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=zF[$CLJS.xa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=zF._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.ab("Cached.-cache",a);}return a};
$CLJS.AF=function(a,b,c){if(null!=a&&$CLJS.Bc===a.Wa){var d=zF(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.Rh.v(d,$CLJS.R,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.CF=function(a,b,c){a=$CLJS.BF.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.DF=function(a,b){return $CLJS.CF(a,b,null)};$CLJS.EF=function(a,b){$CLJS.Rh.v($CLJS.uC,$CLJS.tB,a,b)};$CLJS.GF=function(a,b){a=FF(a,b);return $CLJS.n(a)?a:$CLJS.Fj};
IF=function(){return $CLJS.hg.j(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ns,new $CLJS.h(null,2,[$CLJS.Ti,$CLJS.z,$CLJS.Jt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return $CLJS.Bd(a)?["Invalid ",$CLJS.Ph.l($CLJS.H([$CLJS.z(a)]))," clause: ",$CLJS.Ph.l($CLJS.H([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"not a known MBQL clause"],null),$CLJS.Xe(!1)],null)],null)],null),$CLJS.lf.h(function(a){return new $CLJS.P(null,
2,5,$CLJS.Q,[a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.Ch.g("mbql.clause",$CLJS.jh(a))],null)],null)}),$CLJS.q(HF))};$CLJS.LF=function(a,b){$CLJS.Ve.v(JF,KF,a,b)};$CLJS.NF=function(a,b){$CLJS.Ve.v(JF,MF,a,b)};$CLJS.Fp.prototype.ia=$CLJS.wa(26,function(){return this.cache});$CLJS.Hp.prototype.ia=$CLJS.wa(25,function(){return this.cache});$CLJS.Jp.prototype.ia=$CLJS.wa(24,function(){return this.cache});$CLJS.Lp.prototype.ia=$CLJS.wa(23,function(){return this.cache});
$CLJS.Np.prototype.ia=$CLJS.wa(22,function(){return this.cache});$CLJS.Pp.prototype.ia=$CLJS.wa(21,function(){return this.cache});$CLJS.Sp.prototype.ia=$CLJS.wa(20,function(){return this.cache});$CLJS.Up.prototype.ia=$CLJS.wa(19,function(){return this.cache});$CLJS.Wp.prototype.ia=$CLJS.wa(18,function(){return this.cache});$CLJS.Yp.prototype.ia=$CLJS.wa(17,function(){return this.cache});$CLJS.$p.prototype.ia=$CLJS.wa(16,function(){return this.cache});$CLJS.bq.prototype.ia=$CLJS.wa(15,function(){return this.cache});
$CLJS.dq.prototype.ia=$CLJS.wa(14,function(){return this.cache});$CLJS.fq.prototype.ia=$CLJS.wa(13,function(){return this.cache});$CLJS.hq.prototype.ia=$CLJS.wa(12,function(){return this.cache});$CLJS.jq.prototype.ia=$CLJS.wa(11,function(){return this.cache});$CLJS.mq.prototype.ia=$CLJS.wa(10,function(){return this.cache});$CLJS.rq.prototype.ia=$CLJS.wa(9,function(){return this.cache});$CLJS.tq.prototype.ia=$CLJS.wa(8,function(){return this.cache});$CLJS.wq.prototype.ia=$CLJS.wa(7,function(){return this.cache});
$CLJS.Gq.prototype.ia=$CLJS.wa(6,function(){return this.cache});$CLJS.lE.prototype.ia=$CLJS.wa(5,function(){return this.cache});$CLJS.BF=function BF(a){switch(arguments.length){case 1:return BF.h(arguments[0]);case 2:return BF.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.BF.h=function(a){return $CLJS.BF.g(a,null)};$CLJS.BF.g=function(a,b){return $CLJS.AF($CLJS.Eo.g(a,b),$CLJS.Dk,$CLJS.Cn)};$CLJS.BF.A=2;
FF=function FF(a,b){return $CLJS.E.g(a,$CLJS.Fj)?null:$CLJS.E.g(b,$CLJS.Fj)?null:$CLJS.GC(a,b)?b:$CLJS.GC(b,a)?a:$CLJS.Ue(function(d){return $CLJS.Ue(function(e){return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[d,e],null),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))?null:FF.g?FF.g(d,e):FF.call(null,d,e)},$CLJS.he(b,$CLJS.ai(b)))},$CLJS.he(a,$CLJS.ai(a)))};$CLJS.OF=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.PF=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.QF=new $CLJS.M(null,"dimension","dimension",543254198);$CLJS.RF=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.SF=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.TF=new $CLJS.M(null,"query","query",-1288509510);$CLJS.UF=new $CLJS.M(null,"power","power",-937852079);$CLJS.VF=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.WF=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.XF=new $CLJS.M(null,"abs","abs",-246026477);
$CLJS.YF=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.ZF=new $CLJS.M(null,"between","between",1131099276);$CLJS.$F=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.aG=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.bG=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.cG=new $CLJS.M(null,"var","var",-769682797);$CLJS.dG=new $CLJS.M(null,"next","next",-117701485);$CLJS.eG=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.fG=new $CLJS.M(null,"case","case",1143702196);
$CLJS.gG=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.hG=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.iG=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.jG=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.kG=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.lG=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.mG=new $CLJS.M(null,"desc","desc",2093485764);$CLJS.nG=new $CLJS.M(null,"starts-with","starts-with",366503009);
$CLJS.oG=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.pG=new $CLJS.M(null,"short","short",1928760516);$CLJS.qG=new $CLJS.M(null,"avg","avg",197406200);$CLJS.rG=new $CLJS.M(null,"metric","metric",408798077);$CLJS.sG=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.tG=new $CLJS.M(null,"asc","asc",356854569);$CLJS.uG=new $CLJS.M(null,"floor","floor",1882041021);$CLJS.vG=new $CLJS.M(null,"contains","contains",676899812);
$CLJS.wG=new $CLJS.M(null,"field","field",-1302436500);$CLJS.xG=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.yG=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.zG=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.AG=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.BG=new $CLJS.M(null,"round","round",2009433328);$CLJS.CG=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);
$CLJS.DG=new $CLJS.M(null,"share","share",-589433933);$CLJS.EG=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.FG=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.GG=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.HG=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.IG=new $CLJS.M(null,"time-interval","time-interval",704622015);$CLJS.JG=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.KG=new $CLJS.M(null,"median","median",569566131);Zea=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.MG=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.NG=new $CLJS.M(null,"trim","trim",774319767);$CLJS.OG=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.PG=new $CLJS.M(null,"ltrim","ltrim",1654269283);$CLJS.QG=new $CLJS.M(null,"category","category",-593092832);
$CLJS.RG=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.SG=new $CLJS.M(null,"/","/",1282502798);$CLJS.TG=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.UG=new $CLJS.M(null,"log","log",-1595516004);$CLJS.VG=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.WG=new $CLJS.M(null,"rtrim","rtrim",-661336449);var HF=$CLJS.$e($CLJS.eh),MF;$CLJS.X($CLJS.bF,IF());$CLJS.vC(HF,Zea,function(){return $CLJS.X($CLJS.bF,IF())});
$CLJS.XG=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.$E.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.Ch.g("mbql.clause",$CLJS.jh(d));$CLJS.X(f,e);$CLJS.Kd($CLJS.q(HF),d)||$CLJS.Rh.j(HF,$CLJS.ee,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
MF=function MF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return MF.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
MF.l=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,$CLJS.hg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.Ht,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,a],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LE],null)],null)],null)],null),b)],null)};MF.A=1;
MF.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};var KF=function KF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return KF.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
KF.l=function(a,b){return $CLJS.hg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.h(null,1,[$CLJS.Ht,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LE],null)],null),b)};KF.A=1;KF.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var JF=function JF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return JF.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};JF.l=function(a,b,c){c=$CLJS.E.g($CLJS.z(c),$CLJS.At)?$CLJS.he($CLJS.jd(c),$CLJS.ef(2,c)):$CLJS.he(null,c);var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);a=$CLJS.Ve.j(a,b,d);return $CLJS.n(c)?$CLJS.XG.v(b,$CLJS.At,c,a):$CLJS.XG.g(b,a)};JF.A=2;
JF.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};