var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var l1,zna,Ana,m1,n1;$CLJS.j1=function(a,b){return $CLJS.gc($CLJS.db(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Ff),b))};l1=function(a){return $CLJS.n(k1)?["(",$CLJS.p.h(a),")"].join(""):a};zna=function(a,b,c){var d=$CLJS.RW(a,b);a=$CLJS.W0.j(a,b,d);var e=$CLJS.nB(c);return $CLJS.Ue(function(f){return $CLJS.E.g($CLJS.nB($CLJS.T.h(f)),e)},a)};
Ana=function(a,b){var c=$CLJS.Zk.v(a,$CLJS.lQ,$CLJS.gE($CLJS.ee,$CLJS.Ff),b);return $CLJS.Bd($CLJS.fF.h(a))?$CLJS.Zk.v(c,$CLJS.fF,$CLJS.ee,$CLJS.BW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DB,$CLJS.N,$CLJS.KW(b)],null))):c};m1=function(a,b,c){var d=$CLJS.KW(c);return $CLJS.R.l($CLJS.k0.j(a,b,c),$CLJS.aL,$CLJS.QZ,$CLJS.H([$CLJS.T,d,$CLJS.BE,d]))};n1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.o1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var p1=function(){function a(d,e,f){var k=$CLJS.RW(d,e);k=$CLJS.JH($CLJS.Wk.g($CLJS.fh([f]),$CLJS.KW),$CLJS.lQ.h(k));if($CLJS.n(k))return k;throw $CLJS.li($CLJS.wE("No expression named {0}",$CLJS.H([$CLJS.Ph.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.mS,f,$CLJS.TF,d,$CLJS.QL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.P0.m(null,$CLJS.DB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=p1.j(a,b,c);return $CLJS.Q0.j(a,b,c)});$CLJS.R0.m(null,$CLJS.DB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Jj,$CLJS.ZK,$CLJS.o1,$CLJS.NE.h(d),$CLJS.T,e,$CLJS.FE,e,$CLJS.BE,$CLJS.l0.j(a,b,c),$CLJS.GB,$CLJS.Q0.j(a,b,c),$CLJS.aL,$CLJS.QZ],null)});$CLJS.L0.m(null,$CLJS.yB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.L0.m(null,$CLJS.wB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.L0.m(null,$CLJS.xB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.L0.m(null,$CLJS.MB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.L0.m(null,$CLJS.DB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.M0.m(null,$CLJS.DB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var k1=!1,Bna=new $CLJS.h(null,4,[$CLJS.ls,"+",$CLJS.At,"-",$CLJS.ms,"×",$CLJS.SG,"÷"],null),q1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ls,$CLJS.At,$CLJS.SG,$CLJS.ms],null)),r1=null,s1=0,t1=0;;)if(t1<s1){var Cna=r1.X(null,t1);$CLJS.EF(Cna,n1);t1+=1}else{var u1=$CLJS.y(q1);if(u1){var v1=u1;if($CLJS.Cd(v1)){var w1=$CLJS.lc(v1),Dna=$CLJS.mc(v1),Ena=w1,Fna=$CLJS.D(w1);q1=Dna;r1=Ena;s1=Fna}else{var Gna=$CLJS.z(v1);$CLJS.EF(Gna,n1);q1=$CLJS.B(v1);r1=null;s1=0}t1=0}else break}
$CLJS.L0.m(null,n1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(Bna,c);a:{var e=k1;k1=!0;try{var f=$CLJS.St([" ",$CLJS.jh(c)," "].join(""),$CLJS.lf.g($CLJS.qu($CLJS.l0,a,b),d));break a}finally{k1=e}f=void 0}return l1(f)});$CLJS.M0.m(null,n1,function(){return"expression"});
$CLJS.P0.m(null,$CLJS.PH,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Td($CLJS.GF,function(){return function k(f){return new $CLJS.qe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);x=$CLJS.Q0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}u=$CLJS.z(l);return $CLJS.he($CLJS.Q0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.L0.m(null,$CLJS.vJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.l0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.d1.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=$CLJS.OA(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=l1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.M0.m(null,$CLJS.vJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.N0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.d1.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=$CLJS.OA(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.L0.m(null,$CLJS.zG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.l0.v(a,b,e,d)});$CLJS.M0.m(null,$CLJS.zG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.N0.j(a,b,d)});
$CLJS.Hna=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(zna(d,e,f)))throw $CLJS.li("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.mS,f],null));return $CLJS.bX.l(d,e,Ana,$CLJS.H([$CLJS.LW($CLJS.DW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Ina=function(){function a(d,e){var f=$CLJS.Ie($CLJS.lQ.h($CLJS.RW(d,e)));return null==f?null:$CLJS.Yk.g($CLJS.qu(m1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.x1=function(){function a(d,e){return $CLJS.Ie($CLJS.lQ.h($CLJS.RW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.AW.m(null,$CLJS.DB,function(a){return a});
$CLJS.Jna=function(){function a(d,e,f){var k=$CLJS.hg.g($CLJS.N,$CLJS.jm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KW(t),m],null)},$CLJS.x1.g(d,e))),l=$CLJS.RW(d,e);d=$CLJS.W0.j(d,e,l);return $CLJS.Ie($CLJS.j1(function(m){return $CLJS.Va(f)||$CLJS.Tk.g($CLJS.aL.h(m),$CLJS.QZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Kna=function(){function a(d,e,f){return $CLJS.CW(m1(d,e,p1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();