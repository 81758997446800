var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var Era,Fra,W7,X7,Gra;Era=function(a){if("string"===typeof a)return $CLJS.qh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.qh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.pm(a));};$CLJS.U7=function(a){return $CLJS.Xa(a)?$CLJS.bA(a,$CLJS.H([$CLJS.Di,!0])):a};
Fra=function(a,b,c){var d=Era(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.OA(d))/Math.log(10)));return $CLJS.Ue(function(f){f*=e;return f>=d?f:null},$CLJS.$pa)};
$CLJS.V7=function(a,b){var c=$CLJS.o7(a);if($CLJS.n(c)){var d=$CLJS.RE.h(c);d=d instanceof $CLJS.M?d.T:null;switch(d){case "num-bins":return a=$CLJS.QA(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eO,$CLJS.rj,$CLJS.Uj],null)),$CLJS.n(a)?(d=$CLJS.O(a),a=$CLJS.J.g(d,$CLJS.fo),d=$CLJS.J.g(d,$CLJS.pk),c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.zE),c=Fra(a,d,c),new $CLJS.h(null,3,[$CLJS.QE,c,$CLJS.SL,b,$CLJS.XL,b+c],null)):null;case "bin-width":return c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.QE),new $CLJS.h(null,3,[$CLJS.QE,c,
$CLJS.SL,b,$CLJS.XL,b+c],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}else return null};W7=function(a){var b=$CLJS.vA.h($CLJS.F1($CLJS.e0(a)));b=b.h?b.h($CLJS.T7):b.call(null,$CLJS.T7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.ud($CLJS.I4.g(b,-1))&&$CLJS.ud($CLJS.X5.g(b,-1))){if(b=$CLJS.Zk.j(b,$CLJS.CL,$CLJS.td),!$CLJS.y($CLJS.CL.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
X7=function(a,b){var c=W7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.Nk.l(b,$CLJS.VW,$CLJS.H([$CLJS.aX]));var d=$CLJS.U0,e=d.j,f=a;var k=a;var l=$CLJS.m2(k,-1);k=$CLJS.n(l)?$CLJS.RW(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.x2.v(a,-2,$CLJS.CW(b),d);if($CLJS.n(b))a=$CLJS.Zk.j(a,$CLJS.CL,$CLJS.td);else return null}};
Gra=function(a,b,c){var d=function(){if($CLJS.n($CLJS.o7(b))){var e=$CLJS.V7(b,c);if($CLJS.n(e)){var f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.SL);f=$CLJS.J.g(f,$CLJS.XL);var k=$CLJS.w5(b,null);e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.l2($CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[k,e],null)),$CLJS.l2($CLJS.hs,new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null))],null)}else e=null}else e=null;return $CLJS.n(e)?e:new $CLJS.P(null,1,5,$CLJS.Q,[(0,$CLJS.B3)(b,c)],null)}();return $CLJS.db(function(e,f){return $CLJS.C3.j(e,-1,f)},
a,d)};$CLJS.c6.m(null,$CLJS.W6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.R6);b=$CLJS.J.g(b,$CLJS.YZ);return new $CLJS.h(null,3,[$CLJS.rj,$CLJS.W6,$CLJS.R6,a,$CLJS.YZ,b],null)});
$CLJS.b6.m(null,$CLJS.W6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){var f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.i6),l=$CLJS.J.g(f,$CLJS.TL),m=W7(c),t=$CLJS.bX.l(m,-1,$CLJS.Nk,$CLJS.H([$CLJS.lG,$CLJS.kG,$CLJS.aS,$CLJS.xS,$CLJS.fF]));d=$CLJS.db(function(v,x){var A=$CLJS.O(x);x=$CLJS.J.g(A,$CLJS.Yi);A=$CLJS.J.g(A,$CLJS.Ij);return Gra(v,x,A)},
t,function(){return function A(x){return new $CLJS.qe(null,function(){for(var C=x;;){var G=$CLJS.y(C);if(G){var K=G;if($CLJS.Cd(K)){var S=$CLJS.lc(K),Y=$CLJS.D(S),ca=$CLJS.te(Y);return function(){for(var va=0;;)if(va<Y){var Qa=$CLJS.md(S,va);Qa=$CLJS.Zk.j(Qa,$CLJS.Yi,function(){return function(gb){return X7(c,gb)}}(va,C,Qa,S,Y,ca,K,G,m,t,e,f,k,l));$CLJS.Tk.g($CLJS.aL.h($CLJS.Yi.h(Qa)),$CLJS.vL)&&ca.add(Qa);va+=1}else return!0}()?$CLJS.we($CLJS.ye(ca),A($CLJS.mc(K))):$CLJS.we($CLJS.ye(ca),null)}var ja=
$CLJS.z(K);ja=$CLJS.Zk.j(ja,$CLJS.Yi,function(){return function(va){return X7(c,va)}}(C,ja,K,G,m,t,e,f,k,l));if($CLJS.Tk.g($CLJS.aL.h($CLJS.Yi.h(ja)),$CLJS.vL))return $CLJS.he(ja,A($CLJS.Lc(K)));C=$CLJS.Lc(K)}else return null}},null,null)}(l)}());var u=function(){var v=$CLJS.kd(k);return $CLJS.n(v)?$CLJS.JH(function(x){return $CLJS.E.g($CLJS.F5(x),v)},$CLJS.I4.g(m,-1)):null}();return $CLJS.db(function(v,x){return $CLJS.C3.j(v,-1,x)},d,$CLJS.n(u)?function(){var v=$CLJS.z(u);switch(v instanceof $CLJS.M?
v.T:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kd(u)],null);case "metric":return $CLJS.D3.g($CLJS.R.j($CLJS.kZ.h((0,$CLJS.OY)($CLJS.k2($CLJS.U7(new $CLJS.h(null,2,[$CLJS.rj,$CLJS.TF,$CLJS.TF,$CLJS.U7($CLJS.RZ.h($CLJS.i0(c,$CLJS.kd(u))))],null)),$CLJS.rj,$CLJS.TF))),$CLJS.gQ,$CLJS.gQ.h(c)),-1);default:return null}}():null)}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Lc(c);return b(d,
e,f,c)};a.l=b;return a}());