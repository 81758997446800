var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var Rea,DE,HE,SE;
$CLJS.uE=function(a,b){return function(){function c(l,m,t){return $CLJS.Gd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Gd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Gd(l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Gd(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Te(function(C){var G=a.h?a.h(C):a.call(null,C);return $CLJS.n(G)?
b.h?b.h(C):b.call(null,C):G},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.z(t);t=$CLJS.B(t);var v=$CLJS.z(t);t=$CLJS.B(t);var x=$CLJS.z(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};$CLJS.vE=function(a){return $CLJS.Wk.g($CLJS.lf.h(a),$CLJS.FC)};$CLJS.wE=function(a,b){a=$CLJS.gu($CLJS.TA(a,/''/,"'"),/\{\d+\}/);return $CLJS.Ve.j($CLJS.Mt.t,$CLJS.ql(a),$CLJS.ql(b))};$CLJS.xE=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.yE=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);
$CLJS.zE=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.AE=new $CLJS.M(null,"mbql","mbql",69346710);$CLJS.BE=new $CLJS.M(null,"display-name","display-name",694513143);Rea=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.CE=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
DE=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.EE=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);$CLJS.FE=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.GE=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);HE=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);
$CLJS.IE=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.JE=new $CLJS.M(null,"operator","operator",-1860875338);$CLJS.KE=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.LE=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.ME=new $CLJS.M("metabase.lib.schema.common","positive-number","metabase.lib.schema.common/positive-number",2061626826);$CLJS.NE=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.OE=new $CLJS.M(null,"x","x",2099068185);$CLJS.PE=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.QE=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.RE=new $CLJS.M(null,"strategy","strategy",-1471631918);
SE=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.X($CLJS.GE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.fo,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"non-blank string"],null),$CLJS.We($CLJS.UA)],null)],null));$CLJS.X($CLJS.KE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.h(null,1,[$CLJS.fo,0],null)],null));$CLJS.X($CLJS.yE,$CLJS.Vl);
$CLJS.X($CLJS.ME,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"positive number"],null),$CLJS.uE($CLJS.Hl,$CLJS.$l)],null));$CLJS.X(SE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,2,[$CLJS.fo,36,$CLJS.pk,36],null)],null));
$CLJS.X(HE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,2,[$CLJS.Ht,"valid semantic type",$CLJS.Jt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return["Not a valid semantic type: ",$CLJS.Ph.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.GC(a,$CLJS.xj)}],null));
$CLJS.X(DE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,2,[$CLJS.Ht,"valid relation type",$CLJS.Jt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return["Not a valid relation type: ",$CLJS.Ph.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.GC(a,$CLJS.ri)}],null));$CLJS.X($CLJS.CE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DE],null)],null));
$CLJS.X($CLJS.PE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,2,[$CLJS.Ht,"valid base type",$CLJS.Jt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return["Not a valid base type: ",$CLJS.Ph.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.GC(a,$CLJS.Fj)}],null));
$CLJS.X($CLJS.LE,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NE,SE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GB,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.PE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.PE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ak,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,
$CLJS.CE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.GE],null)],null)],null));
$CLJS.X(Rea,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,$CLJS.ek,$CLJS.bj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.pi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kj,new $CLJS.h(null,1,[$CLJS.bt,!0],null),$CLJS.LE],null)],null));