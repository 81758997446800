var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var $ea,afa,bfa,cfa,dfa,efa,ffa,cH,gfa,hfa,ifa,jfa,kfa,lfa,mfa,nfa,eH,ofa;$CLJS.YG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);$ea=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.ZG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);afa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
bfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.$G=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.aH=new $CLJS.M(null,"column-name","column-name",551523580);cfa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.bH=new $CLJS.M(null,"display-info","display-info",-816930907);dfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
efa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);ffa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);cH=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);gfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);hfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ifa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
jfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);kfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);lfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);mfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.dH=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);nfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
eH=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.fH=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);ofa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.NF($CLJS.gy,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null)],null)],null)]));$CLJS.NF($CLJS.xG,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null)],null)],null)]));
$CLJS.LF($CLJS.qG,$CLJS.H([$CLJS.At,$CLJS.bE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));$CLJS.LF($CLJS.bG,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lF],null)],null)]));$CLJS.LF($CLJS.FG,$CLJS.H([$CLJS.At,$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)]));
$CLJS.LF($CLJS.pk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.EF($CLJS.pk,$CLJS.qF);$CLJS.LF($CLJS.KG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));$CLJS.EF($CLJS.KG,$CLJS.qF);$CLJS.LF($CLJS.fo,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)]));$CLJS.EF($CLJS.fo,$CLJS.qF);
$CLJS.X(cH,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Os,new $CLJS.h(null,1,[$CLJS.Ht,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Ht,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.LF($CLJS.hG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cH],null)]));$CLJS.EF($CLJS.hG,$CLJS.qF);
$CLJS.LF($CLJS.DG,$CLJS.H([$CLJS.At,$CLJS.bE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)]));$CLJS.LF($CLJS.MG,$CLJS.H([$CLJS.At,$CLJS.bE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));$CLJS.LF($CLJS.JG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));
$CLJS.LF($CLJS.TG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));$CLJS.EF($CLJS.JG,$CLJS.qF);$CLJS.EF($CLJS.TG,$CLJS.qF);$CLJS.LF($CLJS.RG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kF],null)],null)]));$CLJS.EF($CLJS.RG,$CLJS.qF);
$CLJS.LF($CLJS.cG,$CLJS.H([$CLJS.At,$CLJS.bE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null)]));$CLJS.X(eH,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.Hs,nfa,lfa,efa,afa,$ea,dfa,ffa,gfa,hfa,kfa,ifa,ofa,mfa,bfa,jfa,$CLJS.Jl],null));$CLJS.X($CLJS.ZG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.fo,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eH],null)],null));
$CLJS.gH=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.pG,$CLJS.gy,$CLJS.$G,!1,$CLJS.dH,$CLJS.RF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Count of rows"),$CLJS.aH,$CLJS.YE("Count"),$CLJS.au,$CLJS.YE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.pG,$CLJS.JG,$CLJS.YG,$CLJS.lk,$CLJS.$G,!0,$CLJS.dH,$CLJS.RF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Sum of ..."),$CLJS.aH,$CLJS.YE("Sum"),$CLJS.au,$CLJS.YE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.pG,$CLJS.qG,$CLJS.YG,$CLJS.lk,$CLJS.$G,!0,$CLJS.dH,$CLJS.RF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Average of ..."),$CLJS.aH,$CLJS.YE("Average"),$CLJS.au,$CLJS.YE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.pG,$CLJS.KG,$CLJS.YG,$CLJS.lk,$CLJS.$G,!0,$CLJS.dH,$CLJS.sG,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Median of ..."),$CLJS.aH,$CLJS.YE("Median"),$CLJS.au,$CLJS.YE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.pG,$CLJS.bG,$CLJS.YG,$CLJS.pi,$CLJS.$G,!0,$CLJS.dH,$CLJS.RF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Number of distinct values of ..."),$CLJS.aH,$CLJS.YE("Distinct values"),$CLJS.au,$CLJS.YE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.pG,$CLJS.TG,$CLJS.YG,$CLJS.lk,$CLJS.$G,!0,$CLJS.dH,$CLJS.RF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Cumulative sum of ..."),
$CLJS.aH,$CLJS.YE("Sum"),$CLJS.au,$CLJS.YE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.pG,$CLJS.xG,$CLJS.$G,!1,$CLJS.dH,$CLJS.RF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Cumulative count of rows"),$CLJS.aH,$CLJS.YE("Count"),$CLJS.au,$CLJS.YE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.pG,$CLJS.MG,$CLJS.YG,$CLJS.lk,$CLJS.$G,
!0,$CLJS.dH,$CLJS.GG,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Standard deviation of ..."),$CLJS.aH,$CLJS.YE("SD"),$CLJS.au,$CLJS.YE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.pG,$CLJS.fo,$CLJS.YG,$CLJS.cj,$CLJS.$G,!0,$CLJS.dH,$CLJS.RF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Minimum of ..."),$CLJS.aH,$CLJS.YE("Min"),$CLJS.au,$CLJS.YE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.pG,$CLJS.pk,$CLJS.YG,$CLJS.cj,$CLJS.$G,!0,$CLJS.dH,$CLJS.RF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.BE,$CLJS.YE("Maximum of ..."),$CLJS.aH,$CLJS.YE("Max"),$CLJS.au,$CLJS.YE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(cfa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yk,$CLJS.fH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pG,$CLJS.hg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Es],null),$CLJS.lf.h($CLJS.pG),$CLJS.gH)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YG,new $CLJS.h(null,1,[$CLJS.bt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.bj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$G,$CLJS.Ys],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.dH,$CLJS.bj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bH,$CLJS.od],null)],null));