var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var hE,iE,jE,Qea,kE,mE,nE;
$CLJS.gE=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Ve.N(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.z(t);t=$CLJS.B(t);var v=$CLJS.z(t);t=$CLJS.B(t);var x=$CLJS.z(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};hE=function(a,b){this.db=a;this.Ah=b;this.C=393216;this.I=0};iE=function(a){for(var b=null;;){var c;a=$CLJS.Eo.g(a,b);a=c=$CLJS.Xn(a)?$CLJS.Rn(a):a;if(!$CLJS.Xn(c))return a}};jE=function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return!($CLJS.zd(a)&&!0===$CLJS.bt.h(a))};
Qea=function(a,b,c){var d=iE($CLJS.Eo.g(a,c));return $CLJS.Kq($CLJS.In(d),$CLJS.Fn(d),function(){var e=$CLJS.Hn(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.Gn($CLJS.Eo.g(d,null));return $CLJS.n(e)?e:c}())};kE=function(a){return function(b,c,d){var e=$CLJS.y(c);b=$CLJS.z(e);e=$CLJS.B(e);c=$CLJS.Yk.g(function(f){return $CLJS.Eo.g(f,d)},c);return new $CLJS.P(null,3,5,$CLJS.Q,[c,$CLJS.Yk.g($CLJS.vt,c),$CLJS.db(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.lE=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,C,G,K,S,Y){this.form=a;this.options=b;this.forms=c;this.U=d;this.Yd=e;this.za=f;this.children=k;this.min=l;this.Eb=m;this.Rb=t;this.Ne=u;this.parent=v;this.re=x;this.Ri=A;this.type=C;this.Ve=G;this.cache=K;this.max=S;this.ai=Y;this.C=393216;this.I=0};mE=function(a,b,c,d,e,f,k,l,m){this.Ve=a;this.re=b;this.type=c;this.min=d;this.max=e;this.Yd=f;this.Eb=k;this.Rb=l;this.Ne=m;this.C=393216;this.I=0};
nE=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.rj),d=$CLJS.J.g(b,$CLJS.fo),e=$CLJS.J.g(b,$CLJS.pk),f=$CLJS.J.g(b,$CLJS.Kt),k=$CLJS.J.g(b,$CLJS.lq),l=$CLJS.J.g(b,$CLJS.Wi);return new mE(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.rj,$CLJS.Rp],null))};$CLJS.X=function(a,b){$CLJS.Rh.v(oE,$CLJS.R,a,b);$CLJS.af($CLJS.pE,$CLJS.N);return null};hE.prototype.P=function(a,b){return new hE(this.db,b)};hE.prototype.O=function(){return this.Ah};hE.prototype.sd=$CLJS.Bc;
hE.prototype.cd=function(a,b){return $CLJS.tn($CLJS.vn($CLJS.q(this.db)),b)};var qE=function qE(a){switch(arguments.length){case 2:return qE.g(arguments[0],arguments[1]);case 3:return qE.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};qE.g=function(a,b){return qE.j(a,b,null)};
qE.j=function(a,b,c){function d(v,x){return $CLJS.E.g($CLJS.ok,v)?new $CLJS.P(null,2,5,$CLJS.Q,[null,x],null):$CLJS.kf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Zq.h(x)],null),$CLJS.ar.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.nl.l($CLJS.H([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?iE($CLJS.Eo.g(a,c)):null;b=$CLJS.n(b)?iE($CLJS.Eo.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.lo.h(a):null,k=$CLJS.n(b)?$CLJS.lo.h(b):null,l=$CLJS.O(c),m=$CLJS.J.j(l,$CLJS.Ft,function(v,x){return x}),t=$CLJS.J.j(l,$CLJS.It,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Va(function(){var v=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.Os,null,$CLJS.ok,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.Os,null,$CLJS.ok,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.E.g(f,$CLJS.ok)&&$CLJS.E.g(k,$CLJS.ok)){l=e($CLJS.Fn(a),$CLJS.Fn(b));var u=$CLJS.$e($CLJS.eh);a=$CLJS.db(function(v,x){var A=
$CLJS.I(x,0,null);if($CLJS.n(function(){var C=$CLJS.q(u);return C.h?C.h(A):C.call(null,A)}()))return $CLJS.db(function(C,G){var K=$CLJS.I(G,0,null),S=$CLJS.ee.g;if($CLJS.E.g(K,A)){K=$CLJS.I(G,0,null);var Y=$CLJS.I(G,1,null),ca=$CLJS.I(G,2,null);$CLJS.I(x,0,null);var ja=$CLJS.I(x,1,null),va=$CLJS.I(x,2,null);G=jE(G);var Qa=jE(x);G=t.g?t.g(G,Qa):t.call(null,G,Qa);Y=$CLJS.nl.l($CLJS.H([Y,ja]));va=new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.R.j(Y,$CLJS.bt,$CLJS.Va(G)),qE.j?qE.j(ca,va,c):qE.call(null,ca,va,
c)],null);K=$CLJS.I(va,0,null);ca=$CLJS.I(va,1,null);va=$CLJS.I(va,2,null);K=$CLJS.Va(va)?new $CLJS.P(null,2,5,$CLJS.Q,[K,ca],null):$CLJS.n($CLJS.n(ca)?!1===$CLJS.bt.h(ca)&&$CLJS.E.g(1,$CLJS.D(ca)):ca)?new $CLJS.P(null,2,5,$CLJS.Q,[K,va],null):$CLJS.Va($CLJS.y(ca))?new $CLJS.P(null,2,5,$CLJS.Q,[K,va],null):!1===$CLJS.bt.h(ca)?new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.Nk.g(ca,$CLJS.bt),va],null):new $CLJS.P(null,3,5,$CLJS.Q,[K,ca,va],null)}else K=G;return S.call($CLJS.ee,C,K)},$CLJS.Ff,v);$CLJS.Rh.j(u,
$CLJS.ee,A);return $CLJS.ee.g(v,x)},$CLJS.Ff,$CLJS.hg.g($CLJS.Hn(a),$CLJS.Hn(b)));return $CLJS.Kq($CLJS.ok,l,a,c)}return function(v,x){var A=$CLJS.y(v);v=$CLJS.z(A);var C=$CLJS.B(A);A=$CLJS.z(C);C=$CLJS.B(C);var G=$CLJS.y(x);x=$CLJS.z(G);var K=$CLJS.B(G);G=$CLJS.z(K);K=$CLJS.B(K);return $CLJS.Kq($CLJS.Os,e(v,x),$CLJS.kf.l(new $CLJS.P(null,1,5,$CLJS.Q,[qE.j(A,G,c)],null),C,$CLJS.H([K])),c)}(d(f,a),d(k,b))};qE.A=3;
var rE=function rE(a){switch(arguments.length){case 2:return rE.g(arguments[0],arguments[1]);case 3:return rE.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};rE.g=function(a,b){return rE.j(a,b,null)};
rE.j=function(a,b,c){return qE.j(a,b,$CLJS.Zk.j($CLJS.Zk.j(c,$CLJS.Ft,$CLJS.gE($CLJS.Xd,function(d,e,f){var k=$CLJS.E.g($CLJS.vt.g(d,null),$CLJS.vt.g(e,null));return $CLJS.n(k)?d:$CLJS.Eo.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,d,e],null),f)})),$CLJS.It,$CLJS.gE($CLJS.Xd,function(d,e){return $CLJS.n(d)?e:d})))};rE.A=3;
var sE=function sE(a){switch(arguments.length){case 2:return sE.g(arguments[0],arguments[1]);case 3:return sE.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};sE.g=function(a,b){return sE.j(a,b,null)};sE.j=function(a,b,c){var d=$CLJS.ih(b);return Qea(a,function(e){return $CLJS.of(function(f){f=$CLJS.I(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};sE.A=3;$CLJS.g=$CLJS.lE.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.lE(this.form,this.options,this.forms,this.U,this.Yd,this.za,this.children,this.min,this.Eb,this.Rb,this.Ne,this.parent,this.re,this.Ri,this.type,this.Ve,this.cache,this.max,b)};$CLJS.g.O=function(){return this.ai};$CLJS.g.Ya=$CLJS.Bc;$CLJS.g.ya=function(){return $CLJS.Cn(this.za)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.mp(this,new $CLJS.P(null,1,5,$CLJS.Q,[this.za],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.ua=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.Dn(this.za,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.Bc;$CLJS.g.ia=$CLJS.ua(5);$CLJS.g.je=$CLJS.Bc;$CLJS.g.Ld=function(){return this.za};$CLJS.g=mE.prototype;$CLJS.g.P=function(a,b){return new mE(this.Ve,this.re,this.type,this.min,this.max,this.Yd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Ne};$CLJS.g.Xa=$CLJS.Bc;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.go(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.Gf(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.I(a,0,null),k=$CLJS.I(a,1,null),l=$CLJS.I(a,2,null);return new $CLJS.lE(new $CLJS.Mh(function(){return $CLJS.Ko(e.type,b,k,d)}),d,k,b,e.Yd,l,f,e.min,e.Eb,e.Rb,e.Ne,this,e.re,a,e.type,e.Ve,$CLJS.Jo(),e.max,new $CLJS.h(null,1,[$CLJS.rj,$CLJS.pq],null))};
$CLJS.pH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.rH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);$CLJS.tE=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);var oE;$CLJS.pE=$CLJS.$e($CLJS.N);
oE=$CLJS.$e($CLJS.nl.l($CLJS.H([$CLJS.$s(),new $CLJS.h(null,3,[$CLJS.Gt,nE(new $CLJS.h(null,2,[$CLJS.rj,$CLJS.Gt,$CLJS.Wi,kE(qE)],null)),$CLJS.Et,nE(new $CLJS.h(null,2,[$CLJS.rj,$CLJS.Et,$CLJS.Wi,kE(rE)],null)),$CLJS.Lt,nE(new $CLJS.h(null,5,[$CLJS.rj,$CLJS.Lt,$CLJS.Kt,1,$CLJS.fo,2,$CLJS.pk,2,$CLJS.Wi,function(a,b,c){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zk.j(b,0,function(d){return $CLJS.Eo.g(d,c)}),$CLJS.Zk.j(b,0,function(d){return $CLJS.vt.g(d,c)}),$CLJS.Ve.g(sE,$CLJS.ee.g(b,c))],null)}],null))],
null)])));$CLJS.af($CLJS.Cl,$CLJS.vn(new hE(oE,$CLJS.N)));