var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var g9,i9,h9,Bva,o9,Cva,p9,Dva,Eva,Fva,Gva,j9,Hva,u9,Iva,Jva,Kva;g9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Da(a,b):null};i9=function(a,b){return h9(a,b,$CLJS.N)};
h9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,j9,!0);if($CLJS.zd(b))return $CLJS.hg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=g9(b,"_plainObject");d=null==d?null:$CLJS.cA(d);return null==d?null:$CLJS.Ie(d)}():null;return $CLJS.n(c)?$CLJS.hg.j($CLJS.N,a,c):$CLJS.hg.j($CLJS.N,$CLJS.Wk.j($CLJS.lf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,g9(b,d)],null)}),$CLJS.Xk.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.Ca(b))};
Bva=function(a){var b=k9.h(a),c=l9.h(a),d=m9.h(a);return $CLJS.Wk.j($CLJS.lf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.Ch.h($CLJS.rB.h?$CLJS.rB.h(f):$CLJS.rB.call(null,f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.ud(b)?$CLJS.Xd:$CLJS.Xk.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Kd(b,f)}),$CLJS.Va(c)?$CLJS.Xd:$CLJS.lf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,
1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};
o9=function(a,b){var c=Bva(a),d=n9.h(a);return function(e){try{var f=$CLJS.R.j(h9(c,e,b),$CLJS.Jj,d),k=$CLJS.ZA($CLJS.Ry);$CLJS.n($CLJS.YA("metabase.lib.js.metadata",k))&&$CLJS.XA("metabase.lib.js.metadata",k,$CLJS.GW("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.dj.h(f),$CLJS.HW(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.ZA($CLJS.Sy),$CLJS.n($CLJS.YA("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.XA("metabase.lib.js.metadata",k,$CLJS.GW("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.Ph.l($CLJS.H([e])),$CLJS.EV(f)])),f):$CLJS.XA("metabase.lib.js.metadata",k,$CLJS.GW(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.Ph.l($CLJS.H([e])),$CLJS.EV(f)])),null)),null;throw l;}}};Cva=function(a){return i9($CLJS.lf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.Ch.h($CLJS.rB.h?$CLJS.rB.h(c):$CLJS.rB.call(null,c));c=$CLJS.E.g(c,$CLJS.OV)?$CLJS.RE:c;b=$CLJS.E.g(c,$CLJS.RE)?$CLJS.Ch.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
p9=function(a){return $CLJS.Yk.g(o9($CLJS.wG,$CLJS.N),a)};Dva=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.q9=new $CLJS.M(null,"cards","cards",169174038);Eva=new $CLJS.M(null,"binning-info","binning-info",-2117036653);Fva=new $CLJS.M(null,"db","db",993250759);$CLJS.r9=new $CLJS.M(null,"metrics","metrics",394093469);Gva=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.s9=new $CLJS.M(null,"segments","segments",1937535949);
j9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);Hva=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.t9=new $CLJS.M(null,"tables","tables",1334623052);u9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);Iva=new $CLJS.M(null,"schema-name","schema-name",1666725119);Jva=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);Kva=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.v9=new $CLJS.M(null,"databases","databases",2040134125);var k9,Lva=$CLJS.$e($CLJS.N),Mva=$CLJS.$e($CLJS.N),Nva=$CLJS.$e($CLJS.N),Ova=$CLJS.$e($CLJS.N),Pva=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null)),x9,ewa,fwa,gwa,hwa,iwa;k9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.Ch,Pva,Lva,Mva,Nva,Ova);k9.m(null,$CLJS.gi,function(){return null});var l9,Qva=$CLJS.$e($CLJS.N),Rva=$CLJS.$e($CLJS.N),Sva=$CLJS.$e($CLJS.N),Tva=$CLJS.$e($CLJS.N),Uva=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
l9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Ch,Uva,Qva,Rva,Sva,Tva);l9.m(null,$CLJS.gi,function(){return null});var n9,Vva=$CLJS.$e($CLJS.N),Wva=$CLJS.$e($CLJS.N),Xva=$CLJS.$e($CLJS.N),Yva=$CLJS.$e($CLJS.N),Zva=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));n9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","lib-type"),$CLJS.Ch,Zva,Vva,Wva,Xva,Yva);
var m9,$va=$CLJS.$e($CLJS.N),awa=$CLJS.$e($CLJS.N),bwa=$CLJS.$e($CLJS.N),cwa=$CLJS.$e($CLJS.N),dwa=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));m9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Xd,dwa,$va,awa,bwa,cwa);m9.m(null,$CLJS.gi,function(){return null});
$CLJS.w9=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Ch.h(f)},e,a,b,c,d)}();ewa=$CLJS.$e($CLJS.N);fwa=$CLJS.$e($CLJS.N);gwa=$CLJS.$e($CLJS.N);hwa=$CLJS.$e($CLJS.N);iwa=$CLJS.J.j($CLJS.N,$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
x9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Ch,iwa,ewa,fwa,gwa,hwa);$CLJS.w9.m(null,$CLJS.gi,function(a,b){var c=o9(a,$CLJS.N);return i9($CLJS.lf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FW(e),new $CLJS.Mh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),g9(b,x9.h(a)))});n9.m(null,$CLJS.gQ,function(){return $CLJS.rL});
k9.m(null,$CLJS.gQ,function(){return new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.fF,null,$CLJS.t9,null],null),null)});l9.m(null,$CLJS.gQ,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.bA(b,$CLJS.H([$CLJS.Di,!0]));case "features":return $CLJS.hg.j($CLJS.eh,$CLJS.lf.h($CLJS.Ch),b);case "native-permissions":return $CLJS.Ch.h(b);default:return b}}});x9.m(null,$CLJS.gQ,function(){return"databases"});n9.m(null,$CLJS.$W,function(){return $CLJS.fL});
k9.m(null,$CLJS.$W,function(){return new $CLJS.dh(null,new $CLJS.h(null,5,[u9,null,$CLJS.fF,null,$CLJS.s9,null,$CLJS.gQ,null,$CLJS.r9,null],null),null)});l9.m(null,$CLJS.$W,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.Ch.h(b);case "field-order":return $CLJS.Ch.h(b);case "initial-sync-status":return $CLJS.Ch.h(b);case "visibility-type":return $CLJS.Ch.h(b);default:return b}}});
$CLJS.w9.m(null,$CLJS.$W,function(a,b){var c=o9(a,$CLJS.N);return i9($CLJS.Wk.g($CLJS.Xk.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.lf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FW(e),new $CLJS.Mh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),g9(b,"tables"))});n9.m(null,$CLJS.wG,function(){return $CLJS.ZK});
k9.m(null,$CLJS.wG,function(){return new $CLJS.dh(null,new $CLJS.h(null,7,[u9,null,$CLJS.$W,null,$CLJS.TL,null,Kva,null,$CLJS.gQ,null,Jva,null,$CLJS.r9,null],null),null)});m9.m(null,$CLJS.wG,function(){return new $CLJS.h(null,4,[$CLJS.pA,$CLJS.aL,$CLJS.wi,$CLJS.VW,$CLJS.mS,$CLJS.FE,Eva,$CLJS.aX],null)});
l9.m(null,$CLJS.wG,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.Ch.h(b);case "coercion-strategy":return $CLJS.Ch.h(b);case "effective-type":return $CLJS.Ch.h(b);case "fingerprint":return $CLJS.zd(b)?$CLJS.HV(b):$CLJS.bA(b,$CLJS.H([$CLJS.Di,!0]));case "has-field-values":return $CLJS.Ch.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.vL;case "breakout":return $CLJS.pL;default:return $CLJS.Ch.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.Ch.h(b);
case "semantic-type":return $CLJS.Ch.h(b);case "visibility-type":return $CLJS.Ch.h(b);case "id":return $CLJS.Bd(b)?$CLJS.jd(b):b;case "metabase.lib.field/binning":return Cva(b);default:return b}}});
$CLJS.w9.m(null,$CLJS.wG,function(a,b){var c=o9(a,$CLJS.N),d=g9(b,"fields");return i9($CLJS.cm(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.FW(f);if($CLJS.n(l))return l;l=$CLJS.qh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==g9(d,l);return m?$CLJS.FW(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Mh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});n9.m(null,$CLJS.IN,function(){return $CLJS.uL});
k9.m(null,$CLJS.IN,function(){return new $CLJS.dh(null,new $CLJS.h(null,11,[u9,null,$CLJS.wl,null,Gva,null,$CLJS.$W,null,Fva,null,Dva,null,$CLJS.s9,null,$CLJS.gQ,null,Hva,null,$CLJS.r9,null,Iva,null],null),null)});
l9.m(null,$CLJS.IN,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.PA($CLJS.xd,$CLJS.Ua)(b))?p9(b):$CLJS.bA(b,$CLJS.H([$CLJS.Di,!0]));case "fields":return p9(b);case "visibility-type":return $CLJS.Ch.h(b);case "dataset-query":return $CLJS.bA(b,$CLJS.H([$CLJS.Di,!0]));case "dataset":return b;default:return b}}});
$CLJS.w9.m(null,$CLJS.IN,function(a,b){return $CLJS.hg.j($CLJS.N,$CLJS.lf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Mh(function(){var d=o9($CLJS.IN,new $CLJS.h(null,1,[j9,!1],null)),e=o9($CLJS.IN,$CLJS.N),f=$CLJS.nl.l;d=$CLJS.R.j(d(g9(g9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.dj,c);var k=g9(g9(b,"questions"),$CLJS.p.h(c));var l=g9(k,"_card");k=$CLJS.n(l)?l:k;e=e.h?e.h(k):e.call(null,k);return f.call($CLJS.nl,$CLJS.H([d,e]))})],null)}),$CLJS.hg.j($CLJS.hg.j($CLJS.eh,
$CLJS.cm($CLJS.SW),$CLJS.Ca(g9(b,"tables"))),$CLJS.lf.h($CLJS.FW),$CLJS.Ca(g9(b,"questions"))))});n9.m(null,$CLJS.rG,function(){return $CLJS.eL});k9.m(null,$CLJS.rG,function(){return new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.$W,null,$CLJS.gQ,null],null),null)});l9.m(null,$CLJS.rG,function(){return function(a,b){return b}});x9.m(null,$CLJS.rG,function(){return"metrics"});n9.m(null,$CLJS.VF,function(){return $CLJS.cL});
k9.m(null,$CLJS.VF,function(){return new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.$W,null,$CLJS.gQ,null],null),null)});l9.m(null,$CLJS.VF,function(){return function(a,b){return b}});x9.m(null,$CLJS.VF,function(){return"segments"});$CLJS.y9=o9($CLJS.wG,$CLJS.N);