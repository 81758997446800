var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var n7,p7,q7,ara,bra,cra,t7,u7,w7,x7,dra,y7,era,fra;n7=function(a){var b=new $CLJS.h(null,3,[$CLJS.NE,$CLJS.p.h($CLJS.XE()),$CLJS.GB,$CLJS.GB.h(a),$CLJS.Ki,$CLJS.PA($CLJS.Ki,$CLJS.GB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DB,b,$CLJS.PA($CLJS.FE,$CLJS.T)(a)],null)};$CLJS.o7=function(a){return $CLJS.B5.h(a)};p7=function(a){return $CLJS.R.j(a,$CLJS.Jj,$CLJS.S4)};
q7=function(a,b){return $CLJS.E.g($CLJS.AE.h(a),$CLJS.km(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,$CLJS.zE,$CLJS.QE],null)))};ara=function(a){return $CLJS.A1(a,new $CLJS.h(null,1,[$CLJS.RE,$CLJS.Ch],null))};
bra=function(a,b){var c=$CLJS.Ue(function(e){return $CLJS.JH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.ZA($CLJS.Uy);if($CLJS.n($CLJS.YA("metabase.lib.field",c))){var d=$CLJS.wE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Ph.l($CLJS.H([a])),$CLJS.Ph.l($CLJS.H([$CLJS.Yk.g($CLJS.WZ,b)]))]));d instanceof Error?$CLJS.XA("metabase.lib.field",c,$CLJS.Qw(),d):$CLJS.XA("metabase.lib.field",
c,$CLJS.Qw.l($CLJS.H([d])),null)}return null};
cra=function(a,b,c){if($CLJS.n(r7))return null;var d=r7;r7=!0;try{var e=$CLJS.m2(a,b),f=$CLJS.n(e)?$CLJS.RW(a,e):$CLJS.RW(a,b),k=function(){var m=$CLJS.s7.h(f);if($CLJS.n(m))return m;m=$CLJS.QA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zV,$CLJS.OW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.$V.h(f);if($CLJS.n(u))return u;u=$CLJS.rP.h(f);if($CLJS.n(u))return u;u=$CLJS.lQ.h(f);return $CLJS.n(u)?u:$CLJS.fF.h(f)}())?$CLJS.W0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.ZA($CLJS.Uy);if($CLJS.n($CLJS.YA("metabase.lib.field",
m))){var t=$CLJS.wE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Ph.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.XA("metabase.lib.field",m,$CLJS.Qw(),t):$CLJS.XA("metabase.lib.field",m,$CLJS.Qw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?bra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.K3($CLJS.Nk.l(l,$CLJS.dj,$CLJS.H([$CLJS.nL,$CLJS.aX,$CLJS.VW])),null),$CLJS.T,function(){var m=$CLJS.WZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.aL,$CLJS.KZ):l:null}finally{r7=d}};
t7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.GP);var f=$CLJS.I(c,2,null);c=$CLJS.nl.l($CLJS.H([function(){var k=$CLJS.GB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.GB,k],null):null}(),function(){var k=$CLJS.PA($CLJS.Ki,$CLJS.GB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Ki,k],null):null}(),function(){var k=$CLJS.GR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.aX,k],null):null}(),function(){var k=$CLJS.CG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.VW,k],null):null}(),$CLJS.Id(f)?function(){var k=$CLJS.r2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.ZK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.ZK,$CLJS.T,f],null):function(){var k=cra(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.ZK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.K3(c,d):c};u7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.VW);return $CLJS.n($CLJS.n(b)?$CLJS.Kd($CLJS.GH,b):b)?$CLJS.Bj:$CLJS.PA($CLJS.Ki,$CLJS.GB)(a)};
$CLJS.v7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.NE),l=$CLJS.J.g(f,$CLJS.GB),m=$CLJS.J.g(f,$CLJS.GR),t=$CLJS.J.g(f,$CLJS.Ki);e=$CLJS.J.g(f,$CLJS.GP);var u=$CLJS.J.g(f,$CLJS.RN),v=$CLJS.J.g(f,$CLJS.CG),x=$CLJS.nl.l;k=new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.ZK,$CLJS.o1,k],null);f=$CLJS.BE.h(f);a=$CLJS.n(f)?f:$CLJS.l0.j(a,b,d);c=x.call($CLJS.nl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.BE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Ki,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.GB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.VW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.aX,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.mL,u):m;return $CLJS.n(e)?$CLJS.K3(u,e):u};w7=function(a,b,c){return $CLJS.n($CLJS.Ue(function(d){return $CLJS.E.g($CLJS.wi.h(d),c)},a))?$CLJS.Yk.g(function(d){var e=$CLJS.Kd(d,b)?$CLJS.Nk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.wi.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
x7=function(a){var b=$CLJS.n($CLJS.W1.h(a))?null:function(){var d=$CLJS.aL.h(a),e=new $CLJS.dh(null,new $CLJS.h(null,3,[$CLJS.ZZ,null,$CLJS.e_,null,$CLJS.KZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.nl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.NE,$CLJS.p.h($CLJS.XE()),$CLJS.GB,$CLJS.GB.h(a),$CLJS.Ki,u7(a)],null),function(){var d=$CLJS.P1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.GP,d],null):null}(),function(){var d=$CLJS.VW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.CG,d],
null):null}(),function(){var d=$CLJS.ZW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.ZW,d],null):null}(),function(){var d=$CLJS.aX.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.GR,d],null):null}(),function(){var d=$CLJS.mL.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.RN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.PA($CLJS.WZ,$CLJS.T):$CLJS.PA($CLJS.dj,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,c,b],null)};
dra=function(a,b){return $CLJS.of(function(c){return $CLJS.E.g($CLJS.aL.h(c),$CLJS.QZ)},$CLJS.W0.v(a,b,$CLJS.RW(a,b),new $CLJS.h(null,3,[$CLJS.E0,!1,$CLJS.K0,!0,$CLJS.A0,!1],null)))};y7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.s7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);era=new $CLJS.M(null,"earliest","earliest",-1928154382);fra=new $CLJS.M(null,"latest","latest",24323665);var ira;$CLJS.B1.m(null,$CLJS.wG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ch.h(b),$CLJS.A1(c,new $CLJS.h(null,2,[$CLJS.CG,$CLJS.Ch,$CLJS.GR,ara],null)),a],null)});var r7=!1,z7=function z7(a,b){var d=$CLJS.H1(a,y7.h(b));a=$CLJS.n(y7.h(d))?z7.g?z7.g(a,d):z7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Zk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.P0.m(null,$CLJS.ZK,function(a,b,c){return u7(c)});$CLJS.P0.m(null,$CLJS.wG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.CG);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.VW,d):c;return $CLJS.Q0.j(a,b,c)});$CLJS.R0.m(null,$CLJS.ZK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.R0.m(null,$CLJS.wG,function(a,b,c){var d=t7(a,b,c);b=$CLJS.v7(a,b,d,c);return $CLJS.n(y7.h(b))?z7(a,b):b});
$CLJS.L0.m(null,$CLJS.ZK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.BE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.wi),l=$CLJS.J.g(c,$CLJS.aX),m=$CLJS.J.g(c,$CLJS.h_),t=$CLJS.J.g(c,$CLJS.mL),u=$CLJS.J.g(c,$CLJS.nL);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.N1.g($CLJS.M1,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.m0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.H1(a,t),$CLJS.n(t)?a=(0,$CLJS.na)($CLJS.TA($CLJS.BE.h($CLJS.o0.j(a,b,t)),$CLJS.cX,"")):(u=$CLJS.O1(a,u),a=$CLJS.l0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.P1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.pB($CLJS.TA($CLJS.jh(k),"-"," ")),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.y5(l,c),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):m});
$CLJS.L0.m(null,$CLJS.wG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.GR);var k=$CLJS.J.g(f,$CLJS.GP),l=$CLJS.J.g(f,$CLJS.CG);f=$CLJS.J.g(f,$CLJS.RN);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.h_,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.wi,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.aX,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.mL,f):e;return $CLJS.n(e)?$CLJS.l0.v(a,b,e,d):$CLJS.YE("[Unknown Field]")});
$CLJS.M0.m(null,$CLJS.ZK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.M0.m(null,$CLJS.wG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=t7(a,b,c);return $CLJS.n(c)?$CLJS.N0.j(a,b,c):"unknown_field"});
$CLJS.S0.m(null,$CLJS.ZK,function(a,b,c){return $CLJS.nl.l($CLJS.H([function(){var d=$CLJS.ei($CLJS.S0,$CLJS.gi);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.aL.h(c),$CLJS.ZZ)?function(){var d=$CLJS.g_.h(c);return $CLJS.n(d)?(d=$CLJS.h0(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.$W,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.BE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.f1.m(null,$CLJS.wG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.CG.h(b)});
$CLJS.f1.m(null,$CLJS.ZK,function(a){return $CLJS.VW.h(a)});
$CLJS.e1.m(null,$CLJS.wG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Kd($CLJS.GH,b),e=$CLJS.EC($CLJS.ZW,$CLJS.Ki,$CLJS.GB)(c);c=$CLJS.R.l(c,$CLJS.CG,b,$CLJS.H([$CLJS.Ki,d?$CLJS.Bj:e,$CLJS.ZW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,c,a],null)}b=$CLJS.ZW.h(c);c=$CLJS.n(b)?$CLJS.Nk.g($CLJS.R.j(c,$CLJS.Ki,b),$CLJS.ZW):c;c=$CLJS.Nk.g(c,$CLJS.CG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,c,a],null)});
$CLJS.e1.m(null,$CLJS.ZK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.VW,b,$CLJS.H([$CLJS.ZW,$CLJS.EC($CLJS.ZW,$CLJS.Ki,$CLJS.GB)(a)])):$CLJS.Nk.l(a,$CLJS.VW,$CLJS.H([$CLJS.ZW]))});$CLJS.h1.m(null,$CLJS.wG,function(a,b,c){return $CLJS.i1.j(a,b,t7(a,b,c))});
$CLJS.h1.m(null,$CLJS.ZK,function(a,b,c){if($CLJS.Tk.g($CLJS.aL.h(c),$CLJS.QZ)){a=$CLJS.PA($CLJS.Ki,$CLJS.GB)(c);b=null==c?null:$CLJS.eO.h(c);if(null==b)var d=null;else try{var e=$CLJS.FD.h($CLJS.rj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,era),l=$CLJS.J.g(f,fra),m=$CLJS.qna.l($CLJS.H([$CLJS.c1.h(k),$CLJS.c1.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.es.g?$CLJS.es.g(1,m):$CLJS.es.call(null,1,m))?$CLJS.Zi:$CLJS.n($CLJS.es.g?$CLJS.es.g(31,m):$CLJS.es.call(null,31,m))?$CLJS.ou:$CLJS.n($CLJS.es.g?
$CLJS.es.g(365,m):$CLJS.es.call(null,365,m))?$CLJS.tj:$CLJS.Cj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.GC(a,$CLJS.FD)?$CLJS.yna:$CLJS.GC(a,$CLJS.gD)?$CLJS.xna:$CLJS.GC(a,$CLJS.eE)?$CLJS.wna:$CLJS.Ff;d=$CLJS.n(d)?w7(e,$CLJS.gi,d):e;return $CLJS.n($CLJS.VW.h(c))?w7(d,$CLJS.t0,$CLJS.VW.h(c)):d}return $CLJS.Ff});
$CLJS.B5.m(null,$CLJS.wG,function(a){var b=null==a?null:$CLJS.UE(a);b=null==b?null:$CLJS.GR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Jj,$CLJS.A5,$CLJS.H([$CLJS.z5,function(c,d){return t7(c,d,a)}]))});$CLJS.B5.m(null,$CLJS.ZK,function(a){var b=null==a?null:$CLJS.aX.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Jj,$CLJS.A5,$CLJS.H([$CLJS.z5,$CLJS.Xe(a)]))});$CLJS.v5.m(null,$CLJS.wG,function(a,b){return $CLJS.VE(a,$CLJS.TE,$CLJS.H([$CLJS.GR,b]))});
$CLJS.v5.m(null,$CLJS.ZK,function(a,b){return $CLJS.TE(a,$CLJS.aX,b)});$CLJS.C5.m(null,$CLJS.wG,function(a,b,c){return $CLJS.D5.j(a,b,t7(a,b,c))});
$CLJS.C5.m(null,$CLJS.ZK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Ki);var d=$CLJS.J.g(b,$CLJS.eO),e=$CLJS.J.g(b,$CLJS.ak);if($CLJS.Tk.g($CLJS.aL.h(b),$CLJS.QZ)){var f=function(){var m=null==a?null:$CLJS.F1($CLJS.e0(a));m=null==m?null:$CLJS.vA.h(m);return null==m?null:$CLJS.Kd(m,$CLJS.GR)}(),k=$CLJS.QA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,$CLJS.Uj],null)),l=$CLJS.o7(b);return function u(t){return new $CLJS.qe(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Cd(v)){var x=$CLJS.lc(v),
A=$CLJS.D(x),C=$CLJS.te(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.md(x,K),Y=C,ca=S;S=q7(S,l)?$CLJS.R.j(ca,$CLJS.t0,!0):ca;Y.add(S);K+=1}else return!0}()?$CLJS.we($CLJS.ye(C),u($CLJS.mc(v))):$CLJS.we($CLJS.ye(C),null)}var G=$CLJS.z(v);return $CLJS.he(function(){var K=G;return q7(G,l)?$CLJS.R.j(K,$CLJS.t0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.fo.h(k);return $CLJS.n(t)?$CLJS.pk.h(k):t}return f}())?null:$CLJS.GC(e,$CLJS.Dj)?$CLJS.Yk.g(p7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.x5(),new $CLJS.h(null,2,[$CLJS.BE,$CLJS.YE("Bin every 0.1 degrees"),$CLJS.AE,new $CLJS.h(null,2,[$CLJS.RE,$CLJS.QE,$CLJS.QE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.BE,$CLJS.YE("Bin every 1 degree"),$CLJS.AE,new $CLJS.h(null,2,[$CLJS.RE,$CLJS.QE,$CLJS.QE,1],null)],null),new $CLJS.h(null,2,[$CLJS.BE,$CLJS.YE("Bin every 10 degrees"),$CLJS.AE,new $CLJS.h(null,2,[$CLJS.RE,$CLJS.QE,$CLJS.QE,10],null)],null),new $CLJS.h(null,2,[$CLJS.BE,$CLJS.YE("Bin every 20 degrees"),
$CLJS.AE,new $CLJS.h(null,2,[$CLJS.RE,$CLJS.QE,$CLJS.QE,20],null)],null)],null)):$CLJS.GC(c,$CLJS.Uj)&&!$CLJS.GC(e,$CLJS.ri)?$CLJS.Yk.g(p7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.x5(),new $CLJS.h(null,2,[$CLJS.BE,$CLJS.YE("10 bins"),$CLJS.AE,new $CLJS.h(null,2,[$CLJS.RE,$CLJS.zE,$CLJS.zE,10],null)],null),new $CLJS.h(null,2,[$CLJS.BE,$CLJS.YE("50 bins"),$CLJS.AE,new $CLJS.h(null,2,[$CLJS.RE,$CLJS.zE,$CLJS.zE,50],null)],null),new $CLJS.h(null,2,[$CLJS.BE,$CLJS.YE("100 bins"),$CLJS.AE,new $CLJS.h(null,2,
[$CLJS.RE,$CLJS.zE,$CLJS.zE,100],null)],null)],null)):null)}return $CLJS.Ff});$CLJS.AW.m(null,$CLJS.wG,function(a){return a});
$CLJS.AW.m(null,$CLJS.ZK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.aL);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.NE,$CLJS.p.h($CLJS.XE()),$CLJS.Ki,$CLJS.PA($CLJS.Ki,$CLJS.GB)(a)],null),a=$CLJS.o1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,b,a],null);case "source/expressions":return n7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.FE.h(a))?n7(a):x7(a);default:return x7(a)}});
$CLJS.gra=function(){function a(e,f,k){k=$CLJS.Ie($CLJS.Yk.g($CLJS.CW,k));var l=dra(e,f),m=$CLJS.hg.j($CLJS.eh,$CLJS.cm(function(t){return $CLJS.x2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Ff);m=$CLJS.Xk.g(m,l);k=$CLJS.n(k)?$CLJS.hg.j(k,$CLJS.lf.h($CLJS.CW),m):null;return $CLJS.bX.l(e,f,$CLJS.TE,$CLJS.H([$CLJS.fF,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.A7=function(){function a(d,e){return $CLJS.fF.h($CLJS.RW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.hra=function(){function a(d,e){var f=$CLJS.W0.v(d,e,$CLJS.RW(d,e),new $CLJS.h(null,3,[$CLJS.E0,!1,$CLJS.K0,!1,$CLJS.A0,!1],null)),k=$CLJS.A7.g(d,e);return $CLJS.ud(k)?$CLJS.Yk.g(function(l){return $CLJS.R.j(l,$CLJS.XZ,!0)},f):$CLJS.I3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
ira=function(){function a(d,e,f){var k=$CLJS.RW(d,e),l=$CLJS.E.g($CLJS.sC(f),$CLJS.lG)?$CLJS.U0:$CLJS.W0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.x2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.jra=function(){function a(d,e,f){f=$CLJS.GZ.j(d,e,f);return ira.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();