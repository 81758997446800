var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var rna,sna,tna,una,vna,g1;
$CLJS.d1=function(){function a(e,f){if($CLJS.Va(f))return"";e=$CLJS.OA(e);var k=$CLJS.Ch.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.KV("Default period","Default periods",e);case "millisecond":return $CLJS.KV("Millisecond","Milliseconds",e);case "second":return $CLJS.KV("Second","Seconds",e);case "minute":return $CLJS.KV("Minute","Minutes",e);case "hour":return $CLJS.KV("Hour","Hours",e);case "day":return $CLJS.KV("Day","Days",e);case "week":return $CLJS.KV("Week","Weeks",
e);case "month":return $CLJS.KV("Month","Months",e);case "quarter":return $CLJS.KV("Quarter","Quarters",e);case "year":return $CLJS.KV("Year","Years",e);case "minute-of-hour":return $CLJS.KV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.KV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.KV("Day of week","Days of week",e);case "day-of-month":return $CLJS.KV("Day of month","Days of month",e);case "day-of-year":return $CLJS.KV("Day of year","Days of year",e);case "week-of-year":return $CLJS.KV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.KV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.KV("Quarter of year","Quarters of year",e);default:return f=$CLJS.gu($CLJS.jh(f),/-/),e=$CLJS.y(f),f=$CLJS.z(e),e=$CLJS.B(e),$CLJS.St(" ",$CLJS.he($CLJS.LA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.e1=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.sC(f)},e,a,b,c,d)}();rna=$CLJS.$e($CLJS.N);sna=$CLJS.$e($CLJS.N);
tna=$CLJS.$e($CLJS.N);una=$CLJS.$e($CLJS.N);vna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.f1=new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.sC,vna,rna,sna,tna,una);$CLJS.f1.m(null,$CLJS.gi,function(){return null});$CLJS.f1.m(null,$CLJS.sH,function(a){return $CLJS.wi.h(a)});g1=new $CLJS.dh(null,new $CLJS.h(null,4,[$CLJS.jH,null,$CLJS.mH,null,$CLJS.nH,null,$CLJS.kH,null],null),null);
$CLJS.wna=$CLJS.hg.j($CLJS.Ff,$CLJS.Wk.g($CLJS.Xk.h(g1),$CLJS.lf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.sH,$CLJS.wi,a],null);return $CLJS.E.g(a,$CLJS.vk)?$CLJS.R.j(b,$CLJS.gi,!0):b})),$CLJS.DH);$CLJS.xna=$CLJS.Yk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.sH,$CLJS.wi,a],null);return $CLJS.E.g(a,$CLJS.ou)?$CLJS.R.j(b,$CLJS.gi,!0):b},$CLJS.yH);
$CLJS.yna=$CLJS.hg.j($CLJS.Ff,$CLJS.Wk.g($CLJS.Xk.h(g1),$CLJS.lf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Jj,$CLJS.sH,$CLJS.wi,a],null);return $CLJS.E.g(a,$CLJS.ou)?$CLJS.R.j(b,$CLJS.gi,!0):b})),$CLJS.EH);$CLJS.L0.m(null,$CLJS.sH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.wi);return $CLJS.d1.h(a)});
$CLJS.S0.m(null,$CLJS.sH,function(a,b,c){return $CLJS.nl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.BE,$CLJS.l0.j(a,b,c),$CLJS.J0,$CLJS.mB($CLJS.f1.h(c))],null),$CLJS.km(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gi,$CLJS.t0],null))]))});
$CLJS.h1=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.mj,$CLJS.uC],null),$CLJS.mj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.sC(l)},e,a,b,c,d)}();$CLJS.h1.m(null,$CLJS.gi,function(){return $CLJS.eh});
$CLJS.i1=function(){function a(d,e,f){return $CLJS.h1.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();